import React from "react";

interface ImageWrapperProps {
  src: string;
  alt: string;
  className: string;
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({ src, alt, className }) => {
  const { REACT_APP_S3_URL } = process.env;

  return (
    <img src={`${REACT_APP_S3_URL}${src}`} alt={alt} className={className} />
  );
};

export default ImageWrapper;
