import { useLocation } from "react-router-dom";
import FlowDiagram from "../components/FlowDiagram";
import FooterSection from "../components/Footer/Footer";
import Navbar from "../components/Navbar";
import ServiceDetail from "../components/ServiceDetail";
import { useEffect } from "react";

const { REACT_APP_S3_URL } = process.env;

const Service = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 1000);
    }
  }, [hash]);

  return (
    <section className="bg-codGray">
      <Navbar />
      <div className="py-10 sm:py-10 md:py-16 xl:py-24">
        <div className="flex items-center px-6 md:px-12 xl:px-20">
          <div className="z-50">
            <h4 className="text-lg text-mercury sm:text-xl md:text-2xl xl:text-3xl">
              Our Service
            </h4>
            <h1 className="my-3 w-[70%] font-epilogue text-3xl font-bold text-white 520_px:w-[60%] sm:text-4xl md:text-5xl xl:w-[60%] xl:text-6xl xl:leading-snug">
              Ready to level up your{" "}
              <span className="win font-dynaPuff">marketing</span>?
            </h1>
            <p className="w-full py-3 font-plusJakartaSans text-silverChalice md:py-6 lg:w-[60%] lg:text-lg xl:w-[45%] xl:py-8 xl:text-xl">
              With Workplay, we’re swapping boring campaigns for unforgettable
              game-driven experiences. Whether it’s a simple click or an epic
              quest, our gamified solutions will have your audience playing and
              paying attention. From custom-built challenges to no-code games,
              we make marketing that engages, excites, and delivers.
            </p>
          </div>
          <div>
            <img
              src={`${REACT_APP_S3_URL}/website-assets/servicePage/service.webp`}
              alt="service"
              className="absolute right-0 top-24 h-[22vh] rounded-r-[22px] sm:h-[24vh] md:h-[32vh] lg:h-[56vh] xl:right-4 xl:h-[80vh] xl:w-auto 3xl:h-[60vh]"
            />
          </div>
        </div>{" "}
        <div
          className="pointer-events-none absolute top-[-40%] z-10 block h-[95vh] w-full overflow-x-hidden blur-3xl xl:top-[-0%] 3xl:h-[60vh]"
          style={{
            background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, ${window.innerWidth < 640 ? 0.3 : 0.2}))`,
          }}
        ></div>
        <FlowDiagram />
        <ServiceDetail />
      </div>
      <FooterSection />
    </section>
  );
};

export default Service;
