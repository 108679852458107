import { useEffect, useState } from "react";
import {
  DomainIcon,
  EmbedIcon,
  LinkIcon,
  QRCodeIcon,
  RightArrowIcon,
} from "../../../components/SvgIcons";
import { getIconSize } from "../../../utils/icon";

const integrationOptions = [
  { Icon: EmbedIcon, label: "Embed", color: "text-carnation" },
  { Icon: LinkIcon, label: "Link", color: "text-dodgerBlue" },
  { Icon: QRCodeIcon, label: "QR Code", color: "text-oldGold" },
  { Icon: DomainIcon, label: "Custom Domains", color: "text-mantis" },
];

const HowToIntegrate: React.FC = () => {
  const [iconSize, setIconSize] = useState(getIconSize());

  useEffect(() => {
    const handleResize = () => setIconSize(getIconSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLearnMore = () => {
    window.location.href = "/integration";
  };

  return (
    <div className="items-center px-6 pb-10 sm:px-10 md:px-12 lg:flex lg:space-x-20 lg:pb-28 xl:px-20">
      <div className="flex w-full lg:block lg:w-[30%]">
        <h4 className="mx-auto w-full text-center text-xl text-mercury sm:mx-0 sm:text-2xl lg:text-left  xl:text-3xl">
          See how we can integrate:
        </h4>
        <p className="group ml-auto flex cursor-pointer items-center text-mercury underline decoration-1 underline-offset-4 hover:opacity-80 sm:text-xl lg:mt-4 lg:pt-0">
          <div className="hidden transition-transform duration-100 group-hover:inline-block">
            <RightArrowIcon width={14} height={14} />
          </div>
          <span
            className="hidden whitespace-normal break-words pr-2 transition-transform duration-100 group-hover:translate-x-2 sm:block "
            onClick={handleLearnMore}
          >
            Learn more
          </span>
        </p>
      </div>
      <div className="flex w-full items-center justify-center py-10 lg:w-[70%]">
        <div className="grid w-full grid-cols-2 gap-10 md:grid-cols-2 lg:grid-cols-4">
          {integrationOptions.map(({ Icon, label, color }, index) => (
            <div key={label} className="flex w-full flex-col items-center">
              <Icon width={iconSize} height={iconSize} />
              <p className={`text-base lg:text-xl ${color}`}>{label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mx-auto w-full text-center sm:hidden">
        <p
          className="cursor-pointer font-semibold text-silverChalice underline underline-offset-2 hover:opacity-80"
          onClick={handleLearnMore}
        >
          Learn more
        </p>
      </div>
    </div>
  );
};

export default HowToIntegrate;
