import React, { ReactNode } from "react";

interface ListItemProps {
  items: ReactNode[];
}

const ListItem: React.FC<ListItemProps> = ({ items }) => {
  return (
    <ul className="mt-3 list-disc pl-5">
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

export default ListItem;
