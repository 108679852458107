import React, { ReactNode, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import Help from "./Help";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.backgroundColor = "#0e0e0e";
    setLoading(false);
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <div className="relative min-h-screen">
      {loading ? (
        <div className="flex min-h-screen items-center justify-center">
          <ClipLoader size={50} color="#fff" loading={loading} />
        </div>
      ) : (
        <div className="flex min-h-screen flex-col">{children}</div>
      )}
      <Help />
    </div>
  );
};

export default Layout;
