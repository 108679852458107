import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { verifyPayment } from "../../services/paymentApi";

const Confirmation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("xSudTzMYmd");
  const [redirectSeconds, setRedirectSeconds] = useState<number>(3);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<any>(null);
  const [attempts, setAttempts] = useState<number>(0);
  const [paymentSuccessCount, setPaymentSuccessCount] = useState<number>(0);
  const [paymentFailureCount, setPaymentFailureCount] = useState<number>(0);

  const orderId = location.state?.id;
  const ccid = new URLSearchParams(location.search).get("ccid");

  useEffect(() => {
    if (!token || !orderId) {
      window.location.href = "/";
      return;
    }
  }, [token, orderId]);

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (!isVerified) {
        const message =
          "You are currently verifying payment. Are you sure you want to leave?";
        e.returnValue = message; // Standard for most browsers
        return message; // For some browsers
      }
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isVerified]);

  // Polling to verify the payment every 3 seconds
  useEffect(() => {
    if (!orderId) return;

    const intervalId = setInterval(async () => {
      try {
        const response = await verifyPayment(orderId);
        const { paid, status } = response.data;

        // Handle payment success
        if (paid && status === "captured") {
          setPaymentSuccessCount((prev) => prev + 1);
          setPaymentFailureCount(0); // Reset failure count on success

          if (paymentSuccessCount >= 2) {
            // If success is received twice in a row, stop polling and show success message
            setIsVerified(true);
            setPaymentStatus("success");
          }
        } else if (!paid && status === "failed") {
          setPaymentFailureCount((prev) => prev + 1);
          setPaymentSuccessCount(0); // Reset success count on failure

          if (paymentFailureCount >= 2) {
            // If failure is received twice in a row, stop polling and show failure message
            setIsVerified(true);
            setPaymentStatus("failure");
          }
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        setAttempts((prev) => prev + 1);
      }
    }, 4000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [orderId, paymentSuccessCount, paymentFailureCount, ccid, navigate]);

  // Handle redirection after 3 seconds upon successful payment
  // useEffect(() => {
  //   if (isVerified && paymentStatus === "success" && redirectSeconds > 0) {
  //     const timer = setInterval(() => {
  //       setRedirectSeconds((prev) => prev - 1);
  //     }, 1000);

  //     return () => clearInterval(timer); // Cleanup timer on component unmount
  //   } else if (redirectSeconds === 0 && paymentStatus === "success") {
  //     navigate(`/dashboard/gamedetails/?ccid=${ccid}`);
  //   }
  // }, [isVerified, paymentStatus, redirectSeconds, ccid, navigate]);

  useEffect(() => {
    if (isVerified && redirectSeconds > 0) {
      const timer = setInterval(() => {
        setRedirectSeconds((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Cleanup timer on component unmount
    } else if (redirectSeconds === 0) {
      // Redirect based on payment status
      if (paymentStatus === "success") {
        navigate(`/dashboard/gamedetails/?ccid=${ccid}`);
      } else if (paymentStatus === "failure") {
        navigate(`/dashboard/gamedetails/?ccid=${ccid}`);
      }
    }
  }, [isVerified, redirectSeconds, ccid, navigate, paymentStatus]);

  return (
    <div className="relative h-screen overflow-hidden bg-gray-100">
      {/* Navbar */}
      <div className="absolute top-0 w-full bg-black">
        <img
          src={`${process.env.REACT_APP_S3_URL}/assets/wp-logo.webp`}
          className="h-16 px-6 py-5"
          alt="workplay-logo"
        />
      </div>

      {/* Main Content */}
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <div className="w-full rounded-lg p-6 text-center">
          {paymentStatus === "success" ? (
            <>
              {/* Animated Tick Mark */}
              <div className="mb-4 animate-pulse text-8xl text-green-500">
                <div className="tick-mark">&#10003;</div>
              </div>
              {/* Success Message */}
              <h2 className="mb-4 text-3xl font-semibold text-green-500">
                Payment Successful!
              </h2>
              <p className="text-lg text-gray-600">
                Redirecting to dashboard in {redirectSeconds}...
              </p>
            </>
          ) : paymentStatus === "failure" ? (
            <>
              {/* Failure Message */}
              <div className="mb-4 text-8xl text-red-500">&#10060;</div>
              <h2 className="text-3xl font-semibold text-red-500">
                Payment Failed
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Unfortunately, your payment did not go through. Please try again
                or contact support for assistance.
              </p>
              <p className="mt-5 text-lg text-gray-600">
                Redirecting to the details page in {redirectSeconds}...
              </p>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <FadeLoader color="#000000" />
              <p className="mt-5 text-lg font-medium text-gray-600">
                Verifying payment... Please do not navigate away or go back.{" "}
                <br />
                Stay on this page until the process is complete.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
