import LandingCTA from "../../components/LandingCTA";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Campaign Booster", id: "campaignBooster" },
  { label: "Customer Loyalty Program", id: "customerLoyaltyProgram" },
  { label: "Product Launches", id: "productLaunches" },
  { label: "Holiday or Seasonal Campaigns", id: "holidayorSeasonalCampaigns" },
];

const useCaseDetails = [
  {
    id: "campaignBooster",
    title: "Campaign Booster",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/campaignBooster.webp`,
    content: [
      "Launch a promotion that combines fun with value. Offer enticing rewards with each roll, like exclusive discounts or bonus items, to create a buzz and drive sales. The element of surprise keeps customers engaged and encourages them to spread the word, amplifying your campaign's reach.",
    ],
  },
  {
    id: "customerLoyaltyProgram",
    title: "Customer Loyalty Program",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/loyalty.webp`,
    content: [
      "Add Roll the Dice as a gamified element to your loyalty initiatives. Reward repeat customers with rolls that unlock exclusive perks or surprises. This adds an exciting layer to your loyalty program, making customers feel valued while strengthening their connection to your brand.",
    ],
  },
  {
    id: "productLaunches",
    title: "Product Launches",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/productlaunch.webp`,
    content: [
      "Celebrate a new product or service by gamifying the reveal. Let customers roll to unlock early-bird discounts, limited-time offers, or VIP access. The interactive experience builds anticipation, turning your launch into an event that customers actively participate in and remember. ",
    ],
  },
  {
    id: "holidayorSeasonalCampaigns",
    title: "Holiday or Seasonal Campaigns",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/seasonalcampaigns.webp`,
    content: [
      "Make your festive promotions more interactive and engaging by tying rewards to holiday themes, increasing customer enthusiasm and participation. Gamifying seasonal offers adds a playful twist that resonates with customers, driving traffic and boosting sales during key times of the year.",
    ],
  },
];

const strategyItems = [
  {
    title: "A Game-Changer for Long-Term Growth",
    description:
      "Roll the Dice is more than a fun activity; it’s a strategic tool for building strong, lasting customer relationships. By tailoring rewards to align with your brand, it creates personalized experiences that foster loyalty and repeat engagement. This approach helps turn casual participants into long-term advocates, driving consistent growth for your business.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/longTermGrowth.webp`,
    imgAlt: "longTermGrowth",
  },
  {
    title: "Versatile & Customizable",
    description:
      "With its versatility and customization options, Roll the Dice integrates seamlessly into any campaign to amplify results. It’s a memorable way to engage your audience while meeting goals like boosting sales, enhancing brand awareness, or driving customer retention. The element of fun makes your efforts stand out, turning interactions into meaningful, results-driven connections.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/customizable.webp`,
    imgAlt: "customizable",
    reverse: true,
  },
];

const handlePlay = () => {
  window.open("https://roll-the-dice.workplay.digital/", "_target");
};

const RollTheDice = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Roll the <br /> Dice
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Roll the Dice
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Our spin on the age old classic game, Roll The Dice makes every
              interaction thrilling, exciting and rewarding for your customers.
              It’s designed to help your business stand out, drive action, and
              create loyalty through unforgettable experiences.
            </p>
            <button
              className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              onClick={handlePlay}
            >
              Play Now
            </button>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/diceFrame.png`}
            alt="Dice-Frame"
            className="absolute bottom-[-10%] left-[28%] z-0 hidden w-[100%] sm:bottom-[-30%] sm:block md:bottom-[-45%] lg:bottom-[-50%] lg:w-[80%] xl:bottom-[-120%] xl:left-[38%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/diceWithoutShadow.webp`}
            alt="roll-The-Dice"
            className="absolute right-0 top-[-2%] h-[14vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <UseCase
        title="How You Can Use Roll The Dice"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <Strategy
        title="How Roll The Dice Fits Your Strategy"
        items={strategyItems}
      />
      <LandingCTA />
    </div>
  );
};

export default RollTheDice;
