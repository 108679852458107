import React, { useState, useEffect } from "react";
import { AllCampaigns } from "../features/Campaigns/campaignSlice";
import { useAppSelector } from "../app/hooks";
import { getPaymentHistory } from "../services/paymentApi";
import { Download, Eye } from "react-feather";
import { FadeLoader } from "react-spinners";
import { NetbankingIcon, UpiIcon, WalletIcon } from "./SvgIcons";
import CardPaymentMethod from "./CardPaymentMethod";
import { useLocation } from "react-router-dom";

interface PaymentHistoryTableProps {
  payments: any[];
}

const PaymentHistoryTable: React.FC<PaymentHistoryTableProps> = ({
  payments,
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const Campaigns = useAppSelector(AllCampaigns) || [];
  const [current, setCurrent] = useState<string | undefined>();

  const passedCampaignId = location.state?.campaignId;

  useEffect(() => {
    if (passedCampaignId && !current) {
      setCurrent(passedCampaignId);
    } else if (!current && Campaigns.length > 0) {
      setCurrent(Campaigns[0].id);
    }
  }, [Campaigns, passedCampaignId, current]);

  useEffect(() => {
    if (!current) return;

    setLoading(true);

    const fetchPaymentHistory = async () => {
      const token = localStorage.getItem("xSudTzMYmd");

      if (!token) {
        console.error("Authentication token is missing.");
        setLoading(false);
        return;
      }

      try {
        const paymentData = await getPaymentHistory(current, token);

        setSelectedPayments(paymentData.data);
      } catch (error) {
        console.error("Error fetching payment history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentHistory();
  }, [current]);

  const handleCampaignSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedCampaignId = event.target.value;

    setCurrent(selectedCampaignId);
  };

  return (
    <div className="payment-history-table-container">
      {Campaigns.length === 0 ? (
        <p className="block text-[15px] font-semibold">
          No campaigns available at the moment, payment history cannot be
          displayed.
        </p>
      ) : (
        <div className="mb-2">
          <div className="flex items-center justify-between md:items-center lg:flex-row">
            <p className="block font-semibold sm:text-[17px]">
              Payment History
            </p>
            <div className="flex items-center gap-2">
              <select
                id="campaign-select"
                value={current}
                onChange={handleCampaignSelect}
                className="flex w-full justify-end rounded-md border p-2 text-left outline-none md:w-fit"
              >
                <option value="" disabled>
                  Select project
                </option>{" "}
                {/* Default option */}
                {Campaigns.map((campaign, index) => (
                  <option key={index} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            {loading ? (
              <div className="flex min-h-60 w-full items-center justify-center">
                <FadeLoader color={"#444444"} />
              </div>
            ) : (
              <div className="w-80 overflow-x-auto 420_px:w-full sm:w-full">
                <table className="min-w-full rounded-md border border-gray-200 bg-white shadow-sm">
                  <thead className="text-sm">
                    <tr>
                      <th className="whitespace-nowrap p-3 text-left">Date</th>
                      <th className="whitespace-nowrap p-3 text-left">
                        Plan Name
                      </th>
                      <th className="whitespace-nowrap p-3 text-left">
                        Payment Method
                      </th>
                      <th className="whitespace-nowrap p-3 text-left">
                        Invoice
                      </th>
                      <th className="whitespace-nowrap p-3 text-left">Total</th>
                    </tr>
                  </thead>
                  <tbody className="text-[15px]">
                    {selectedPayments.length > 0 ? (
                      selectedPayments.map((payment, index) => (
                        <tr key={index} className="border border-t-stone-200">
                          <td className="p-3">
                            {payment?.createdAt
                              ? new Date(payment.createdAt).toLocaleDateString(
                                  "en-US",
                                ) // or 'en-US', etc.
                              : ""}
                          </td>

                          <td className="p-3">
                            {payment?.notes?.payment?.notes?.subscriptionName}
                          </td>
                          <td className="p-3">
                            {/* Handle different payment methods */}
                            {(() => {
                              const method = payment?.notes?.payment?.method;

                              if (method === "netbanking") {
                                return (
                                  <div className="flex items-center space-x-2">
                                    <NetbankingIcon />
                                    <span>Netbanking</span>
                                  </div>
                                );
                              }

                              if (method === "wallet") {
                                return (
                                  <div className="flex items-center space-x-2">
                                    <WalletIcon />
                                    <span>
                                      {payment?.notes?.payment?.wallet
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        payment?.notes?.payment?.wallet?.slice(
                                          1,
                                        )}
                                    </span>
                                  </div>
                                );
                              }

                              if (method === "card") {
                                return (
                                  <CardPaymentMethod
                                    cardNetwork={
                                      payment?.notes?.payment?.card?.network ||
                                      ""
                                    }
                                    last4={
                                      payment?.notes?.payment?.card?.last4 || ""
                                    }
                                  />
                                );
                              }

                              if (method === "upi") {
                                return (
                                  <div className="flex items-center space-x-2">
                                    <UpiIcon />
                                    <span className="text-sm font-medium">
                                      {(() => {
                                        const vpa =
                                          payment?.notes?.payment?.vpa;
                                        if (!vpa) return null; // Handle case if there's no VPA

                                        const atIndex = vpa.indexOf("@");
                                        if (atIndex === -1) return vpa; // If no @ symbol, return VPA as is

                                        // Get the first letter and replace the rest of the string before @ with '•'
                                        const localPart = vpa.slice(0, atIndex);
                                        const domainPart = vpa.slice(atIndex);

                                        // Replace all letters before @ except the first one with '•'
                                        const maskedLocalPart =
                                          localPart[0] +
                                          "•".repeat(localPart.length - 1);

                                        return maskedLocalPart + domainPart;
                                      })()}
                                    </span>
                                  </div>
                                );
                              }

                              // Handle other payment methods, like card or UPI
                              return method || "Other";
                            })()}
                          </td>

                          <td className="flex items-center space-x-4 p-3">
                            <div
                              className="flex cursor-pointer items-center space-x-1"
                              onClick={() =>
                                window.open(
                                  `/invoice/print/${payment._id}?ccid=${current}`,
                                  "_blank",
                                )
                              }
                            >
                              <Eye size={16} />
                              <p>View</p>
                            </div>
                          </td>
                          <td className="p-3">
                            {/* Displaying currency with appropriate symbol */}
                            {payment.currency === "INR"
                              ? `₹${(payment.amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                              : payment.currency === "USD"
                                ? `$${(payment.amount / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`
                                : payment.amount}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="p-3 text-center">
                          No payment history found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default PaymentHistoryTable;
