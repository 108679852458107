import axios from "axios";

export const fetchCountry = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/country`,
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching country");
  }
};
