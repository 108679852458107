import { useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import GoogleSSO from "../GoogleSSO";
import { ToastContainer } from "react-toastify";
import LazyLoadImages from "../LazyLoadImages";
import "react-toastify/dist/ReactToastify.css";
import { MailIcon } from "../SvgIcons";

const { REACT_APP_S3_URL } = process.env;

interface SignUpProps {
  onEmailButtonClick: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ onEmailButtonClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const campaignId = queryParams.get("gameId");
  const fromValue = queryParams.get("from");
  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      navigate("/", { replace: true });
    }
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="input-wrapper relative flex min-h-screen w-full items-center overflow-x-hidden bg-codGray py-6 md:py-0">
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/top-Ellipse.webp`}
          alt="Ellipse"
          className="absolute top-0 z-0"
        />
        <div className="z-100 w-full items-center justify-center px-6 md:flex md:w-[55%] md:px-[5%] lg:px-20 xl:w-[40%]">
          <div>
            <div className="relative">
              <LazyLoadImages
                className="mb-12 h-6 cursor-pointer xl:h-7"
                src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
                alt="workplay-logo"
                onClick={handleLogoRedirect}
              />
              <div className="flex items-center justify-center">
                <img
                  className="w-full px-14 pb-10 sm:px-20 md:hidden"
                  src={`${REACT_APP_S3_URL}/website-assets/authPages/signUp.webp`}
                  alt="signup"
                />
              </div>
              <h2 className="text-left font-epilogue text-3xl font-bold text-mercury sm:pr-44 lg:text-left lg:text-4xl">
                Ready to Play?
              </h2>
              <LazyLoadImages
                src={`${REACT_APP_S3_URL}/website-assets/authPages/sparkle.webp`}
                alt="sparkle"
                className="absolute right-[0%] top-[40%] h-8 lg:right-[-6%] lg:h-auto"
              />
            </div>
            <p className="m-auto mb-6 mt-2 text-left font-epilogue text-[17px] font-medium text-silverChalice lg:text-left">
              Start Building Your Game!
            </p>{" "}
            <GoogleSSO />
            <span className="orline m-auto flex w-full items-center text-center">
              <p className="my-5 flex  whitespace-nowrap text-mercury">or</p>
            </span>
            <button
              // className="continue-btn flex h-[52px] w-full items-center justify-center rounded-md font-plusJakartaSans text-base font-semibold text-mercury hover:text-codGray"
              className="headerBtn relative flex w-full items-center justify-center rounded bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-xl"
              onClick={onEmailButtonClick}
            >
              <MailIcon stroke="#ffffff" />
              <p className="ml-3 text-lg">Continue with email</p>
            </button>
            <p className="mb-16 mt-5 font-plusJakartaSans font-medium text-silverChalice sm:mb-0">
              Already have an account?
              <Link
                to={
                  fromValue && campaignId
                    ? `/login?from=${fromValue}&gameId=${campaignId}`
                    : "/login"
                }
                className="underline-link relative ml-1 scale-105 hover:opacity-80"
              >
                <strong className="win relative font-epilogue font-semibold">
                  Sign In
                </strong>
              </Link>
            </p>
          </div>
        </div>{" "}
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/pattern.webp`}
          alt="pattern"
          className="absolute bottom-0 right-0 -z-0"
        />
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/yellowEllipse.webp`}
          alt="Ellipse"
          className="absolute bottom-0 right-0 z-0 h-full"
        />
        <div className="z-0 hidden md:block md:w-[45%] xl:w-[60%]">
          <div className="flex items-center justify-center">
            <LazyLoadImages
              className="w-auto px-6 md:px-10 lg:px-20"
              src={`${REACT_APP_S3_URL}/website-assets/authPages/signUp.webp`}
              alt="signUp"
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SignUp;
