import LandingCTA from "../../components/LandingCTA";
import HowToCustomize from "./sections/HowToCustomize";
import HowToIntegrate from "./sections/HowToIntegrate";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Team Challenges", id: "teamChallenges" },
  { label: "Event Engagement", id: "eventEngagement" },
  { label: "Customizable Branding", id: "customizableBranding" },
  { label: "Reward-Based Motivation", id: "rewardBasedMotivation" },
];

const useCaseDetails = [
  {
    id: "teamChallenges",
    title: "Team Challenges",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/teamChallenges.webp`,
    content: [
      "Use the game to foster teamwork by organizing group competitions where participants collaborate or compete, reinforcing bonds and creating a dynamic, interactive experience. The competitive aspect encourages camaraderie, making your events more memorable.",
    ],
  },
  {
    id: "eventEngagement",
    title: "Event Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/eventEngagement.webp`,
    content: [
      "Incorporate the Memory Game into events to draw participants into an interactive and rewarding activity that keeps them engaged. This not only boosts involvement but also makes your event stand out by offering something uniquely entertaining.",
    ],
  },
  {
    id: "customizableBranding",
    title: "Customizable Branding",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/customizableBranding.webp`,
    content: [
      "Tailor the visuals and themes of the game to align with your business goals, from promoting a product to celebrating a holiday or event. Branded customization makes the game a seamless extension of your marketing campaigns, increasing brand recall.",
    ],
  },
  {
    id: "rewardBasedMotivation",
    title: "Reward-Based Motivation",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/rewardBasedMotivation.webp`,
    content: [
      "Drive repeat engagement by offering prizes, badges, or unlockable features as players progress through the game. The promise of rewards keeps players returning, increasing their connection to your brand over time.",
    ],
  },
];

const strategyItems = [
  {
    title: "Building Long-Term Engagement",
    description:
      "The Memory Game is more than entertainment—it’s a strategic tool for sustained audience interaction. By personalizing the visuals and adding progressive challenges, the game adapts to your brand and encourages players to engage repeatedly. Over time, it helps build a strong connection with your audience by associating your brand with fun, mental stimulation, and rewarding experiences.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/longTermEngagement.webp`,
    imgAlt: "longTermEngagement",
  },
  {
    title: "The Ultimate Marketing Asset",
    description:
      "Integrating the Memory Game into your strategy adds an interactive and impactful element to your campaigns. It’s versatile enough to fit into product launches, customer loyalty programs, or corporate events, offering a unique way to captivate and retain customers. By combining mental engagement with branded elements, it transforms your marketing efforts into an unforgettable experience that drives awareness and builds lasting loyalty.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/marketingAsset.webp`,
    imgAlt: "marketingAsset",
    reverse: true,
  },
];

const sections = [
  {
    id: "personalizeYourGame",
    title: "Personalize Your Game",
    description:
      "Add your brand’s unique touch by incorporating your logo, choosing a catchy title, and crafting a description that aligns with your goals. Define the grid size (e.g., 4x4 or 6x6), upload custom images for the cards, and set the level of difficulty to match your audience’s preferences. You can also introduce gameplay elements like timers or move limits to create a challenge that keeps players engaged.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/personalizeYourGame.webp`,
  },
  {
    id: "enhanceVisualAppeal",
    title: "Enhance Visual Appeal",
    description:
      "Transform the game’s aesthetics by selecting a background color or uploading an image that complements your theme. Customize card colors, cover designs, and text styles, including font size and color, to ensure the game matches your brand identity perfectly. The ability to fine-tune visuals ensures your Memory Game feels cohesive and visually memorable.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/visualAppeal.webp`,
  },
  {
    id: "boostInteraction",
    title: "Boost Interaction",
    description:
      "Collect player details such as names and emails to build your database and foster direct communication. Add leaderboards to showcase top players, sparking competition and motivating participants to return for higher scores. You can also set the number of winners to amplify excitement and engagement.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/boostInteraction.webp`,
  },
  {
    id: "launchwithEase",
    title: "Launch with Ease",
    description:
      "Preview your game to fine-tune every detail, ensuring a flawless player experience. Once satisfied, generate a shareable link to distribute across your platforms and let the fun begin. With just a few steps, you’ll have a branded, engaging activity ready to captivate your audience and drive results.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/lauchWithEase.webp`,
  },
];

const handlePlay = () => {
  window.open("https://memory-game.workplay.digital/", "_target");
};

const MemoryCard = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Memory <br /> Card
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Memory Card
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              The Memory Game blends fun and purpose, enhancing cognitive skills
              while entertaining your audience. With branded visuals and
              gameplay, it creates memorable experiences that attract new
              customers, boost loyalty, and foster meaningful connections,
              strengthening your brand’s presence effectively.
            </p>
            <div className="flex items-center space-x-6">
              <button
                className="headerBtn relative w-full rounded-full bg-tanHide px-4 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
                onClick={handlePlay}
              >
                Play Now
              </button>
              <button className="headerBtn relative w-full rounded-full bg-merlin px-4 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtnLogin hover:bg-tuatara sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl">
                Customize
              </button>
            </div>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/memoryCardGroup.webp`}
            alt="memoryCardGroup"
            className="absolute bottom-[-10%] right-[-7%] z-0 hidden w-[90%] sm:bottom-[0%] sm:block md:bottom-[-65%] lg:bottom-[-70%] lg:w-[70%] xl:right-[-6%] xl:top-[-65%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/memoryCards.webp`}
            alt="memoryCards"
            className="absolute right-0 top-[-2%] h-[15vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <HowToIntegrate />
      <UseCase
        title="How You Can Use Memory Card"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <HowToCustomize sections={sections} />
      <Strategy
        title="How Memory Card Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default MemoryCard;
