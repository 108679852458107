import LandingCTA from "../../components/LandingCTA";
import HowToCustomize from "./sections/HowToCustomize";
import HowToIntegrate from "./sections/HowToIntegrate";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Event Engagement", id: "eventEngagement" },
  { label: "Educational Tool", id: "educationalTool" },
  { label: "Team-Building Activity", id: "teamBuildingActivity" },
  { label: "Brand Awareness", id: "brandAwareness" },
  { label: "Rewards and Incentives", id: "rewardsandIncentives" },
];

const useCaseDetails = [
  {
    id: "eventEngagement",
    title: "Event Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/eventEngagement.webp`,
    content: [
      "Keep participants entertained during live or virtual events with themed trivia rounds. Reward top scorers with prizes to make your events unforgettable and interactive.",
    ],
  },
  {
    id: "educationalTool",
    title: "Educational Tool",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/educationalTool.webp`,
    content: [
      "Turn learning into a fun experience! Use the Quiz Game in classrooms, corporate training sessions, or online courses to test knowledge in an interactive and memorable way.",
    ],
  },
  {
    id: "teamBuildingActivity",
    title: "Team-Building Activity",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/teamBuildingActivity.webp`,
    content: [
      "Encourage collaboration and friendly competition by hosting team-based quiz challenges. Teams work together to answer questions, fostering stronger bonds and boosting morale.",
    ],
  },
  {
    id: "brandAwareness",
    title: "Brand Awareness",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/brandAwarness.webp`,
    content: [
      "Promote your brand creatively by designing quizzes around your products, services, or industry trivia. It’s a fun way to engage your audience while sharing key insights about your business.",
    ],
  },
  {
    id: "rewardsandIncentives",
    title: "Rewards and Incentives",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/rewardsIncentive.webp`,
    content: [
      "Motivate players with leaderboards, badges, or rewards for high scores and completed challenges. This keeps participants coming back for more, increasing long-term engagement.",
    ],
  },
];

const strategyItems = [
  {
    title: "Interactive and Versatile",
    description:
      "The Quiz Game is more than just entertainment—it’s a tool for engagement, education, and collaboration. Its customizable format ensures it suits any setting, from casual trivia nights to corporate training sessions.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/interactiveVersatile.webp`,
    imgAlt: "Interactive-and-Versatile",
    reverse: true,
  },
  {
    title: "Educational and Competitive",
    description:
      "With various difficulty levels and topic categories, the game appeals to a wide audience. Players can compete individually or in teams, making it perfect for fostering interaction and rewarding top performers.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/educationalCompetitive.webp`,
    imgAlt: "Educational-and-Competitive",
  },
  {
    title: "A Perfect Fit for Your Goals",
    description:
      "Whether you’re hosting an event, promoting a brand, or enhancing learning experiences, the Quiz Game delivers. Its combination of fun, learning, and competition makes it a versatile addition to any strategy, leaving a lasting impression on your audience.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/perfectFitForGoals.webp`,
    imgAlt: "A-Perfect-Fit-for-Your-Goals",
    reverse: true,
  },
];

const sections = [
  {
    id: "makeYourGameUnique",
    title: "Make Your Game Unique",
    description:
      "Add your logo, set an engaging title, and include a catchy description to capture attention. Create custom questions with multiple-choice options to enhance interactivity and excitement, while setting a timer to add a sense of urgency. Ensure a seamless player experience by providing clear, easy-to-follow instructions, guiding participants effortlessly through the game.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/gameUnique.webp`,
  },
  {
    id: "quizYourWay",
    title: "Quiz Your Way",
    description:
      "Select a color scheme or upload a background image that aligns with your brand to create a cohesive look. Customize text styles, including font size, color, and type, to enhance the quiz's visual appeal and make it more engaging. Tailor the design to ensure the quiz is both fun and reflective of your brand identity, providing a unique and memorable experience for participants.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/quizYourWay.webp`,
  },
  {
    id: "engageandExcite",
    title: "Engage and Excite",
    description:
      "Collect player details such as names or emails to build your database and foster ongoing engagement. Showcase top performers with a leaderboard, and customize the number of winners to intensify the competition. These features not only encourage players to stay engaged but also motivate them to return for future rounds, creating a sense of excitement and loyalty.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/engageExcite.webp`,
  },
  {
    id: "previewandGoLive",
    title: "Preview and Go Live",
    description:
      "Test every detail to ensure your quiz runs smoothly. Once satisfied, generate a shareable link and launch your game to captivate your audience and drive participation.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/previewLive.webp`,
  },
];

const handlePlay = () => {
  window.open("https://quiz.workplay.digital/", "_target");
};

const handleCustomize = () => {
  window.open("https://custom-quiz.workplay.digital/", "_target");
};

const Quiz = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Quiz
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Challenge yourself or compete with friends in our Quiz Game, a
              versatile and engaging way to test knowledge, have fun, and earn
              rewards. With customizable features and a wide range of topics,
              this game brings excitement to any occasion, whether for
              education, entertainment, or brand engagement.
            </p>
            <div className="flex items-center space-x-6">
              <button
                className="headerBtn relative w-full rounded-full bg-tanHide px-4 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
                onClick={handlePlay}
              >
                Play Now
              </button>
              <button
                className="headerBtn relative w-full rounded-full bg-merlin px-4 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtnLogin hover:bg-tuatara sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
                onClick={handleCustomize}
              >
                Customize
              </button>
            </div>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/quizGroup.webp`}
            alt="quiz"
            className="absolute bottom-[-10%] right-[-7%] z-0 hidden w-[90%] sm:bottom-[-40%] sm:block md:bottom-[-85%] lg:w-[70%] xl:right-[-6%] xl:top-[-75%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/quiz/quiz.webp`}
            alt="quiz"
            className="absolute right-0 top-[-7%] h-[10vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <HowToIntegrate />
      <UseCase
        title="How You Can Use Quiz"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <HowToCustomize sections={sections} />
      <Strategy title="How Quiz Fits Your Strategy" items={strategyItems} />
    </div>
  );
};

export default Quiz;
