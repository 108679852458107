import React, { useState, useEffect } from "react";
import { X } from "react-feather";
import { FaHandPointer, FaGamepad } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

const Banner: React.FC = () => {
  const storedVisibility = localStorage.getItem("isBannerVisible");
  const [isAnimating, setIsAnimating] = useState(false);
  const isVisible = storedVisibility ? JSON.parse(storedVisibility) : true;

  useEffect(() => {
    // If visibility is false, remove the banner from the page on initial load
    if (!isVisible) {
      const banner = document.getElementById("banner");
      if (banner) {
        banner.style.display = "none";
      }
    }
  }, [isVisible]);

  const handleButtonClick = () => {
    window.location.href = "/game";
  };

  const handleClose = () => {
    // Add closing animation
    setIsAnimating(true);
    // Update visibility in localStorage
    localStorage.setItem("isBannerVisible", JSON.stringify(false));

    // Wait for the animation to complete before hiding the banner
    setTimeout(() => {
      const banner = document.getElementById("banner");
      if (banner) {
        banner.style.display = "none";
      }
      setIsAnimating(false); // Reset animation state
    }, 300); // Match the animation duration here (300ms)
  };

  const handleOpen = () => {
    setIsAnimating(true);
    // When the banner is opened again, make it visible and store it in localStorage
    localStorage.setItem("isBannerVisible", JSON.stringify(true));
    const banner = document.getElementById("banner");
    if (banner) {
      banner.style.display = "flex";
    }

    // Reset animation after opening
    setTimeout(() => {
      setIsAnimating(false);
    }, 300); // Match the animation duration
  };

  return (
    <div>
      {isVisible ? (
        <div className="flex items-center justify-center">
          <div className="animate-slide-in absolute bottom-6 flex items-center justify-between rounded-full bg-black px-2 py-2 text-white shadow-lg sm:bottom-8 sm:w-[38%] sm:py-3 sm:pl-6 sm:pr-3">
            <div>
              <h2 className="hidden text-sm font-semibold sm:flex sm:text-base">
                Want to check out more of our games?
              </h2>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleButtonClick}
                className="ml-4 flex items-center rounded-full text-sm font-medium text-white transition-colors sm:ml-0 sm:bg-white sm:px-4 sm:py-1 sm:text-base sm:text-black sm:hover:bg-[#f6f6f7]"
              >
                Click Here{" "}
                <p className="mx-1 flex sm:hidden">to check out more games</p>{" "}
                <FaHandPointer className="ml-2" />{" "}
              </button>
              <div
                className="cursor-pointer rounded-full border border-[#f6f6f7] bg-black p-1.5 text-white hover:text-gray-400"
                onClick={handleClose}
              >
                <X className="h-4 w-4" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute bottom-6 right-4 flex items-center justify-center sm:bottom-8 sm:right-10">
          <div
            className={`cursor-pointer rounded-full bg-black p-2.5 text-white shadow-lg hover:scale-[102%] hover:opacity-80 sm:p-4 ${isAnimating ? "animate-spin" : ""}`}
            onClick={handleOpen}
            data-tooltip-id="games"
            data-tooltip-content="Check out games"
          >
            <FaGamepad className="h-6 w-6 sm:h-7 sm:w-7" />
          </div>
          <Tooltip
            id="games"
            className="custom-tooltip"
            noArrow
            delayShow={300}
            place="bottom"
            style={{
              fontSize: "14px",
              fontFamily: "Poppins",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Banner;
