import LazyLoadImages from "./LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

interface LayoutProps {
  heading: string;
  description: string;
  title: string;
  points: string[];
  imageSrc: string;
  imageAlt: string;
  reverse?: boolean;
}

const LayoutSection: React.FC<LayoutProps> = ({
  heading,
  description,
  title,
  points,
  imageSrc,
  imageAlt,
  reverse = false,
}) => {
  const handleButtonClick = (route: string) => {
    window.location.href = route;
  };

  const sectionId = heading.toLowerCase().replace(/ /g, "-");

  return (
    <div
      id={sectionId}
      className={`z-100 py-6 sm:py-10 md:px-12 xl:px-20 ${heading === "Web Development" ? "pb-0" : ""}`}
    >
      <h2 className="px-6 font-epilogue text-2xl font-semibold text-mercury md:px-0 md:text-3xl xl:text-4xl">
        {heading}
      </h2>
      <p className="px-6 py-3 text-lg text-silverChalice md:px-0 md:py-5 xl:py-6 xl:text-xl">
        {description}
      </p>
      <div
        className={`items-center lg:flex ${
          reverse
            ? "flex-row-reverse space-x-40 space-x-reverse"
            : "lg:space-x-40"
        } `}
      >
        <div className="mb-10 mt-6 lg:w-[30%] xl:my-0">
          <LazyLoadImages
            src={imageSrc}
            alt={imageAlt}
            className="mx-auto h-48 md:h-60 xl:h-auto"
          />
        </div>
        <div
          className={`serviceBg w-full bg-opacity-5 px-6 md:my-6 md:px-0 md:py-6 lg:w-[70%] xl:my-10 xl:px-8 xl:py-10 ${
            {
              "No Code Game Builder": "bg-carnation",
              "In-app Gamification": "bg-dodgerBlue",
              "Customized Games": "bg-mantis",
              "Web Development": "bg-oldGold",
            }[heading] || "bg-gray-800"
          }`}
        >
          <h4 className="text-lg text-mercury md:text-xl">{title}</h4>
          <ul className="z-50 flex list-disc flex-col space-y-2 py-6 text-silverChalice md:text-lg xl:space-y-4 xl:text-xl">
            {points.map((point, index) => (
              <li key={index} className="ml-4">
                {point}
              </li>
            ))}
          </ul>{" "}
          <button
            className="headerBtn relative my-3 w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-xl"
            onClick={() =>
              handleButtonClick(
                heading === "No Code Game Builder" ? "/game" : "/contact",
              )
            }
          >
            {`${heading === "No Code Game Builder" ? "Build Now" : "Talk to us"}`}
          </button>
        </div>
      </div>
    </div>
  );
};

const ServiceDetail = () => {
  return (
    <div className="py-6 font-plusJakartaSans sm:py-10 md:py-16 xl:pb-0 xl:pt-24">
      <LayoutSection
        heading="No Code Game Builder"
        description="Turn your marketing into an experience worth remembering! Our gamified strategies don’t just engage—they captivate. With every click, swipe, and share, we bring your brand’s story to life."
        title="Build awareness, drive action, and keep audiences coming back for more, all without writing a single line of code."
        points={[
          "Boost audience engagement with immersive game elements.",
          "Stand out in a crowded feed with experiences that make people pause, play, and remember.",
          "Achieve measurable results while having a little fun—yes, that’s possible!",
        ]}
        imageSrc={`${REACT_APP_S3_URL}/website-assets/servicePage/noCodeGameBuilder.webp`}
        imageAlt="codeBuilder"
      />
      <LayoutSection
        heading="In-app Gamification"
        description="Make your brand addictive, but in a good way. Infuse game mechanics into your app to enhance user engagement. Think rewards, challenges, and the kind of interaction that keeps people coming back for more."
        title="Make your app the place people just can’t quit. With in-app gamification, we’ll give your users a reason to stay, play, and engage."
        points={[
          "Drive retention by offering gamified rewards.",
          "Boost in-app activity with interactive elements.",
          "Elevate user engagement to a whole new level—because who doesn’t love a good game?",
        ]}
        imageSrc={`${REACT_APP_S3_URL}/website-assets/servicePage/inAppGamification.webp`}
        imageAlt="inAppGamification"
        reverse
      />
      <LayoutSection
        heading="Customized Games"
        description="There is a game for every brand personality. Whether you want to boost leads, educate users, or spread brand awareness, give your audience an experience they won’t forget with custom-designed games tailored to your goals."
        title="We craft games that feel like you—fun, memorable, and totally on-brand."
        points={[
          "Personalized game experiences that align with your brand voice.",
          "Drive action, whether it’s leads, sales, or smiles.",
          "Stand out with a tailored approach—because your brand deserves more than a one-size-fits-all solution.",
        ]}
        imageSrc={`${REACT_APP_S3_URL}/website-assets/servicePage/customizedGames.webp`}
        imageAlt="customizedGames"
      />
      <LayoutSection
        heading="Web Development"
        description="Why settle for a site that’s just…there? We build websites that don’t just inform but engage. Think of it as a home base for your brand, packed with all the bells and whistles to make visitors want to stay and explore."
        title="We’ll work with you to create a seamless experience that’s as engaging as it is effective."
        points={[
          "Full-stack web development to bring your ideas to life.",
          "User-centric design that keeps visitors coming back.",
          "Custom-built functionality for your unique needs.",
        ]}
        imageSrc={`${REACT_APP_S3_URL}/website-assets/servicePage/webDevelopment.webp`}
        imageAlt="webDevelopment"
        reverse
      />
    </div>
  );
};

export default ServiceDetail;
