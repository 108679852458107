import { useMemo } from "react";

interface PriceBreakdown {
  gstAmount: number;
  discountAmount: number;
  finalPrice: number;
  basePrice: number; // Added basePrice
  discountedPrice: number;
}

const useFinalPrice = (
  originalPrice: number,
  gstRate: number,
  discountRate: number,
  currency?: string,
): PriceBreakdown => {
  return useMemo(() => {
    let basePrice = originalPrice;
    let gstAmount = 0;

    // Step 1: If currency is "INR", remove GST to get the base price
    if (currency === "INR") {
      basePrice = originalPrice / (1 + gstRate / 100);
    }

    // Step 2: Apply Discount
    const discountAmount = basePrice * (discountRate / 100);
    const discountedPrice = basePrice - discountAmount;

    // Step 3: Calculate GST on the discounted price (if currency is "INR")
    if (currency === "INR") {
      gstAmount = discountedPrice * (gstRate / 100);
    }

    // Step 4: Calculate Final Price
    const finalPrice = discountedPrice + gstAmount;

    return {
      gstAmount,
      discountAmount,
      finalPrice,
      basePrice,
      discountedPrice,
    };
  }, [originalPrice, gstRate, discountRate, currency]);
};

export default useFinalPrice;
