import LandingCTA from "../../components/LandingCTA";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Customizable Object Search", id: "customizableObjectSearch" },
  { label: "Timed Challenges", id: "timedChallenges" },
  { label: "Progressive Difficulty", id: "progressiveDifficulty" },
  { label: "Real-Time Leaderboards", id: "realTimeLeaderboards" },
];

const useCaseDetails = [
  {
    id: "customizableObjectSearch",
    title: "Customizable Object Search",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/objectSearch.webp`,
    content: [
      "Align hidden objects with your brand story or campaign goals, whether it’s promoting product features, celebrating holidays, or increasing brand recognition through logos and themes.",
    ],
  },
  {
    id: "timedChallenges",
    title: "Timed Challenges",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/TimedChallenge.webp`,
    content: [
      "Add urgency with countdown timers, encouraging players to act quickly, boosting excitement, and ensuring repeat participation for higher scores or rewards.",
    ],
  },
  {
    id: "progressiveDifficulty",
    title: "Progressive Difficulty",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/progressiveDifficulty.webp`,
    content: [
      "Keep your audience hooked by gradually increasing the challenge, making each level a fresh and engaging experience that motivates them to return.",
    ],
  },
  {
    id: "realTimeLeaderboards",
    title: "Real-Time Leaderboards",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/Leaderboard.webp`,
    content: [
      "Spark friendly competition with leaderboards that reward top players, fostering a sense of achievement while encouraging continuous engagement.",
    ],
  },
];

const strategyItems = [
  {
    title: "Long-Term Customer Engagement, Redefined",
    description:
      "Find the Object is a strategic approach to sustaining customer interest over time. By customizing themes and rewards, it allows you to craft unique experiences that resonate with their audience, encouraging repeat interactions. This sustained engagement helps build brand loyalty, turning casual players into dedicated customers who associate your brand with fun and value.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/longTermEngagement.webp`,
    imgAlt: "longTermEngagement",
  },
  {
    title: "The Perfect Addition to Your Marketing Toolbox",
    description:
      "With its interactive and customizable nature, Find the Object seamlessly integrates into campaigns to boost customer retention and attract new audiences. Its engaging gameplay ensures your brand stands out while delivering measurable outcomes like increased participation, higher conversion rates, and improved customer satisfaction.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/toolbox.webp`,
    imgAlt: "toolbox",
    reverse: true,
  },
];

const handlePlay = () => {
  window.open("https://find-the-object.workplay.digital/", "_target");
};

const FindTheObject = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Find The <br /> Object
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Find The Object
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Turn engagement into an adventure with Find the Object, designed
              to captivate and delight. By customizing the hidden objects to
              align with your brand’s themes—whether it’s showcasing products,
              seasonal motifs, or your company’s logo—you create a unique,
              interactive experience.
            </p>
            <button
              className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              onClick={handlePlay}
            >
              Play Now
            </button>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/findTheObjectGroup.webp`}
            alt="findTheObject"
            className="absolute right-[-6%] top-[-20%] z-0 hidden w-[80%] sm:bottom-[-30%] sm:block md:top-[-25%] lg:top-[-45%] lg:w-[75%] xl:top-[-75%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/findTheObject/findTheObject.webp`}
            alt="findTheObject"
            className="absolute right-0 top-[-2%] h-[14vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <UseCase
        title="How You Can Use Find The Object"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <Strategy
        title="How Find The Object Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default FindTheObject;
