import LandingCTA from "../../components/LandingCTA";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Brand Integration", id: "brandIntegration" },
  { label: "Reward-Based Engagement", id: "rewardBasedEngagement" },
  { label: "User Data Collection", id: "userDataCollection" },
  { label: "Event-Specific Themes", id: "eventSpecificThemes" },
];

const useCaseDetails = [
  {
    id: "brandIntegration",
    title: "Brand Integration",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/brandIntegration.webp`,
    content: [
      "Seamlessly incorporate your logo and visuals into the slot machine reels. This creates a memorable experience while boosting brand visibility, ensuring your audience engages with your brand in an entertaining way.",
    ],
  },
  {
    id: "rewardBasedEngagement",
    title: "Reward-Based Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/rewardBasedIntegration.webp`,
    content: [
      "Encourage participation by offering rewards such as discounts, gift cards, or loyalty points for specific combinations or high scores. These incentives keep players spinning and coming back for more.",
    ],
  },
  {
    id: "userDataCollection",
    title: "User Data Collection",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/userDataCollection.webp`,
    content: [
      "Gain valuable insights by collecting player information through pre-game registrations or post-game surveys. Leverage this data to refine your marketing strategy and better understand your audience.",
    ],
  },
  {
    id: "eventSpecificThemes",
    title: "Event-Specific Themes",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/eventSpecificThemes.webp`,
    content: [
      "Create a customized slot machine that reflects the theme of your event, product launch, or seasonal campaign. Tailored symbols, visuals, and rewards make the experience relevant and exciting for your audience.",
    ],
  },
];

const strategyItems = [
  {
    title: "Engaging and Interactive",
    description:
      "The Slot Machine game turns passive engagement into an active and enjoyable experience. Players are drawn to the excitement of spinning the reels, making it an effective tool for capturing attention and sustaining interest.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/engagingInteractive.webp`,
    imgAlt: "Engaging-and-Interactive",
    reverse: true,
  },
  {
    title: "Customizable and Versatile",
    description:
      "Adaptable to any theme or objective, this game integrates seamlessly with your brand. From increasing visibility to celebrating events, it offers endless possibilities for audience engagement.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/customizableAndVersatile.webp`,
    imgAlt: "Customizable-and-Versatile",
  },
  {
    title: "Delivering Value for All",
    description:
      "With every spin, players enjoy the thrill of the game while your brand gains exposure and loyalty. Whether through rewards, data collection, or memorable interactions, the Slot Machine game delivers lasting value for your audience and your business.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/deliverValue.webp`,
    imgAlt: "Delivering-Value-for-All",
    reverse: true,
  },
];

const handlePlay = () => {
  window.open("https://slot-machine.workplay.digital/", "_target");
};

const SlotMachine = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Slot <br /> Machine
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Slot Machine
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Elevate excitement with our Slot Machine game, a thrilling and
              engaging experience that keeps players entertained while driving
              participation. Perfect for campaigns, events, or promotions, this
              customizable game turns every spin into an opportunity for fun,
              rewards, and unforgettable engagement.
            </p>
            <button
              className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              onClick={handlePlay}
            >
              Play Now
            </button>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/SlotMachineGroup.webp`}
            alt="slotMachine"
            className="absolute right-[-24%] top-[-20%] z-0 hidden w-[80%] sm:bottom-[-30%] sm:block md:top-[-25%] lg:top-[-90%] lg:w-[90%] xl:top-[-120%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/slotMachine/slotMachine.webp`}
            alt="slotMachine"
            className="absolute right-0 top-[-2%] h-[14vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <UseCase
        title="How You Can Use Slot Machine"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <Strategy
        title="How Slot Machine Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default SlotMachine;
