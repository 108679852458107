import { useEffect, useState, useLayoutEffect, useRef } from "react";
import {
  getAllCampaigns,
  createCampaign,
  updateCampaign,
  getCampaign,
  CurrentCampaign,
  LinkCampaigntoGame,
  getCampaignUsersData,
} from "../../../features/Campaigns/campaignSlice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import DeleteProject from "../../PopUps/DeleteProject";
import { Code, CreditCard, Download, Trash } from "react-feather";
import { AiOutlineLink, AiOutlineQrcode } from "react-icons/ai";
import { exportToExcel, filterData } from "../../../utils/excelUtils";
import { useTrialEligibility } from "../../../hooks/useTrialEligibility";
import PopUpModal from "../../PopUps/PopUpModal";
import SelectPlanModal from "../../PopUps/SelectPlanModal";
import { FaDotCircle } from "react-icons/fa";
import { TbDotsVertical } from "react-icons/tb";
import { BsPersonGear } from "react-icons/bs";
import ManageProjectModal from "../../PopUps/ManageProjectModal";

const {
  REACT_APP_API_URL,
  REACT_APP_CUSTOM_SPIN_URL,
  REACT_APP_CUSTOM_QUIZ_URL,
  REACT_APP_S3_URL,
} = process.env;

const gameOptions = [
  { value: "quiz", label: "Quiz" },
  { value: "spin-the-wheel", label: "Spin the Wheel" },
];

const CampaignForm = () => {
  const navigate = useNavigate();
  const [selectedAction, setSelectedAction] = useState("copy");
  const [campaignName, setCampaignName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [progress, setProgress] = useState(0);
  const [steps, setSteps] = useState("0/0");
  const [Link, setLink] = useState("");
  const [setUpCompleted, setSetUpCompleted] = useState(false);
  const [editSetUp, setEditSetUp] = useState(false);
  const [showdelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isPopUpModalOpen, setIsPopUpModalOpen] = useState(false);

  const [isStartDatePopUpModalOpen, setIsStartDatePopUpModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const currentCampaign = useAppSelector(CurrentCampaign);

  const sid = searchParams.get("sid");
  const ccid = searchParams.get("ccid");

  const fromValue = searchParams.get("fromValue");
  const gameId = searchParams.get("gameId") || currentCampaign.gameId;
  const token = localStorage.getItem("xSudTzMYmd");
  const qrRef = useRef<HTMLDivElement>(null);

  const handleSetUpEdit = async () => {
    setSetUpCompleted(false);
    setEditSetUp(false);
  };

  const handleCampaignUpdate = async () => {
    const ccid = searchParams.get("ccid");

    if (!campaignName || !startDate || !endDate || !selectedOption) {
      toast.error("Please fill in the required fields.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return;
    }

    if (ccid) {
      if (startDate) {
        const localStartDate = new Date(startDate);
        localStartDate.setHours(0, 0, 0, 0);
      }

      if (endDate) {
        endDate.setHours(23, 59, 59);
      }

      const campaignData = {
        name: campaignName,
        game: selectedOption,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      };

      try {
        const actionResult = await dispatch(
          updateCampaign({ updateCampaign: campaignData, ccid: ccid }),
        );
        if (actionResult.meta.requestStatus === "fulfilled") {
          const ccid = sessionStorage.getItem("ccid");

          toast.success("Configuration updated!", {
            autoClose: 1000,
            className: "toastify-toast",
          });
          if (currentCampaign.gameId) {
            setProgress(100);
            setSteps("2/2");
          } else {
            setProgress(50);
            setSteps("1/2");
          }
          setEditSetUp(true);
          setSetUpCompleted(true);

          dispatch(getAllCampaigns());
          navigate(`/dashboard/campaigns/create?ccid=${ccid}`);
        }
      } catch (error: any) {
        console.log(error);
        toast.error("Oops! Something went wrong! Please try again later.", {
          autoClose: 1000,
          className: "toastify-toast",
        });
      }
    }
  };

  const handleFormSubmit = async () => {
    if (!campaignName || !startDate || !endDate || !selectedOption) {
      toast.error("Please fill in the required fields.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return;
    }
    const ccid = searchParams.get("ccid");
    const token = localStorage.getItem("xSudTzMYmd");
    const URL = `${REACT_APP_API_URL}/campaign`;
    if (!URL || !token || ccid) {
      return;
    }

    if (startDate) {
      const localStartDate = new Date(startDate);
      localStartDate.setHours(0, 0, 0, 0);
    }

    if (endDate) {
      endDate.setHours(23, 59, 59);
    }

    const campaignData = {
      name: campaignName,
      game: selectedOption,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
    };

    try {
      const actionResult = await dispatch(createCampaign(campaignData));

      if (actionResult.meta.requestStatus === "fulfilled") {
        const ccid = sessionStorage.getItem("ccid");
        console.log(actionResult.payload);
        toast.success("Configuration completed!", {
          autoClose: 1000,
          className: "toastify-toast",
        });

        if (gameId && fromValue && ccid) {
          dispatch(
            LinkCampaigntoGame({
              gameData: { game: campaignData.game, gameId },
              ccid,
            }),
          ).then(() => {
            dispatch(getCampaign(ccid));
            setProgress(100);
            setSteps("2/2");
          });
        }
        setProgress(50);
        setSteps("1/2");
        setEditSetUp(true);
        setSetUpCompleted(true);
        dispatch(getAllCampaigns());
        navigate(`/dashboard/campaigns/create?ccid=${ccid}`);
      }
    } catch (error) {
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
    }
  };

  const handleExitCampaign = () => {
    navigate("/dashboard/campaigns");
  };

  const discardCampaign = () => {
    setCampaignName("");
    setSelectedOption("");
    setEndDate(null);
    setStartDate(null);
    navigate("/dashboard/campaigns/create");
  };

  const updateCancel = () => {
    setSetUpCompleted(true);
    setEditSetUp(true);
    setCampaignName(currentCampaign.name);
    setSelectedOption(currentCampaign.game);
    setStartDate(new Date(currentCampaign.startDate));
    setEndDate(new Date(currentCampaign.endDate));
    setSetUpCompleted(!setUpCompleted);
  };

  const navigateToGame = () => {
    if (currentCampaign.game === "spin-the-wheel") {
      window.open(`${REACT_APP_CUSTOM_SPIN_URL}/?ccid=${ccid}`, "_self");
    }
    if (currentCampaign.game === "quiz") {
      window.open(`${REACT_APP_CUSTOM_QUIZ_URL}/?ccid=${ccid}`, "_self");
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(Link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const downloadQRCode = () => {
    if (qrRef.current) {
      const svgElement = qrRef.current.querySelector("svg");
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const svgString = new XMLSerializer().serializeToString(
        svgElement as any,
      );
      const svgBlob = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8",
      });
      const url = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.crossOrigin = "*";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);

        // Add the image to the center of the QR code
        const centerX = canvas.width / 2 - 24; // Adjust the size of the image (48x48)
        const centerY = canvas.height / 2 - 24;
        const logo = new Image();
        logo.crossOrigin = "*";
        logo.onload = () => {
          ctx?.drawImage(logo, centerX, centerY, 48, 48);

          // Download the final QR code with the image in the center
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "qrcode.png";
          link.click();
        };
        logo.onerror = (err) => {
          console.error("Error loading logo image:", err);
        };
        logo.src = `${REACT_APP_S3_URL}/assets/WorkPlay_FaviCon.svg`;
      };
      img.onerror = (err) => {
        console.error("Error loading QR code image:", err);
      };
      img.src = url;
    }
  };

  const fieldWhitelist: string[] = [
    "inAppParams",
    "fullName",
    "email",
    "score",
    "prize",
    "isWinner",
    "createdAt",
  ];

  const handleDownload = async (id: string) => {
    // Replace with actual ccid or get it from props/state
    const result = await dispatch(getCampaignUsersData(id) as any);

    if (getCampaignUsersData.fulfilled.match(result)) {
      const filteredData = filterData(result.payload, fieldWhitelist);
      const filename = `${currentCampaign.name} Data Export`;
      exportToExcel(filteredData, filename);
    } else {
      // Handle error
      console.error("Failed to fetch campaign data:", result.payload);
    }
  };

  useEffect(() => {
    if (ccid) {
      dispatch(getCampaign(ccid));
      if (!currentCampaign.gameId) {
        setProgress(50);
        setSteps("1/2");
      }
      setSetUpCompleted(true);
      setEditSetUp(true);
      setCampaignName(currentCampaign.name);
      setSelectedOption(currentCampaign.game);
      setStartDate(new Date(currentCampaign.startDate));
      setEndDate(new Date(currentCampaign.endDate));
    }

    if (gameId && fromValue) {
      setSelectedOption(fromValue);
      setSelectedOption(fromValue);
      if (fromValue === "quiz") {
        setLink(
          `${REACT_APP_CUSTOM_QUIZ_URL}/play?campaignId=${currentCampaign.gameId || gameId}`,
        );
      } else {
        setLink(
          `${REACT_APP_CUSTOM_SPIN_URL}/play?gameId=${currentCampaign.gameId || gameId}`,
        );
      }
      setProgress(50);
      setSteps("1/2");
    }

    if (gameId && ccid) {
      setProgress(100);
      setSteps("2/2");
      if (currentCampaign.game === "quiz") {
        setLink(
          `${REACT_APP_CUSTOM_QUIZ_URL}/play?campaignId=${currentCampaign.gameId || gameId}`,
        );
      } else {
        setLink(
          `${REACT_APP_CUSTOM_SPIN_URL}/play?gameId=${currentCampaign.gameId || gameId}`,
        );
      }
      if (!currentCampaign.gameId) {
        const gameData = { game: currentCampaign.game, gameId: gameId };
        dispatch(LinkCampaigntoGame({ gameData, ccid })).then((response) => {
          console.log(response);
          dispatch(getCampaign(currentCampaign._id));
        });
      }
    }

    if (gameId && fromValue) {
      setSelectedOption(fromValue);
    }
  }, []);

  const handleEmbedCopy = () => {
    const embedCode = `<iframe src="${Link}" width="100%" height="650"></iframe>`;
    navigator.clipboard.writeText(embedCode);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  useLayoutEffect(() => {
    dispatch(getAllCampaigns());
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  const { trialAvailable } = useTrialEligibility();
  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);

  const handleUpgrade = () => {
    if (!sid) {
      if (!trialAvailable) {
        // Pass ccid as a query parameter
        navigate(`/pricing?ccid=${currentCampaign._id}`);
        setTimeout(() => {
          const element = document.getElementById("plans");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else {
        setTimeout(() => {
          setIsSelectPlanOpen(true);
        }, 100);
      }
    } else {
      if (!trialAvailable) {
        navigate(`/checkout?ccid=${currentCampaign._id}&sid=${sid}`);
      } else {
        setTimeout(() => {
          setIsSelectPlanOpen(true);
        }, 100);
      }
    }
  };

  const handleViewPlans = () => {
    navigate(`/pricing?ccid=${ccid}`);
    setTimeout(() => {
      const element = document.getElementById("plans");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const calculateDaysLeft = (expiryDate: string): number => {
    const currentDate = new Date();
    const expiry = new Date(expiryDate);
    return Math.floor(
      (expiry.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
    );
  };

  // If there is an expiration date, calculate days left
  const daysLeft = currentCampaign?.expiresOn
    ? calculateDaysLeft(currentCampaign.expiresOn)
    : null;

  // Check if the "Renew Now" button should be displayed
  const shouldDisplayRenewButton =
    daysLeft !== null && daysLeft <= 4 && daysLeft >= 0;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isManageProjectModalOpen, setIsManageProjectModalOpen] =
    useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="m-auto block px-5 py-8 lg:p-10">
      {!loading && (
        <div>
          <div className="m-auto w-full">
            <div className="mb-2 flex w-full items-center">
              <div
                className="mr-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white shadow hover:opacity-45 lg:mr-4 lg:h-10 lg:w-10"
                onClick={handleExitCampaign}
              >
                <FontAwesomeIcon
                  className="h-3 font-thin hover:scale-[102%] lg:h-4"
                  icon={faChevronLeft}
                />
              </div>
              <div className="w-full items-center justify-between text-base md:flex">
                {!currentCampaign.gameId && !currentCampaign._id ? (
                  <p className="font-poppins text-[22px] font-medium">
                    Let's Setup Your Game!
                  </p>
                ) : (
                  <div className="flex items-center space-x-6">
                    <p className="font-poppins text-lg font-semibold uppercase lg:text-[22px]">
                      Project Overview - {campaignName}
                    </p>
                    {currentCampaign?.isPaid ? (
                      <div>
                        {currentCampaign?.subscription && (
                          <div className="flex w-fit items-center  space-x-1.5 rounded-full bg-orange-600 px-3 py-1 font-poppins text-sm font-medium uppercase tracking-wider text-white">
                            <FaDotCircle className="text-white" size={10} />
                            <p>{currentCampaign?.subscription?.title}</p>
                          </div>
                        )}
                      </div>
                    ) : currentCampaign?.isTrial ? (
                      <div className="flex items-center space-x-1.5 rounded-full bg-green-600 px-3 py-1 font-poppins text-sm font-medium uppercase tracking-wider text-white">
                        <FaDotCircle className="text-white" size={10} />
                        <p>Free Trial</p>
                      </div>
                    ) : (
                      <div>
                        {progress === 100 ? (
                          <button
                            className="flex w-fit cursor-pointer items-center rounded-full bg-black px-4 py-2 font-poppins text-sm font-medium text-white hover:scale-[102%]"
                            onClick={handleUpgrade}
                          >
                            Upgrade
                          </button>
                        ) : null}
                      </div>
                    )}
                  </div>
                )}

                <div className="flex items-center justify-center space-x-4">
                  <div className="flex items-center justify-center space-x-4">
                    {currentCampaign?.expiresOn && shouldDisplayRenewButton ? (
                      <>
                        <p className="font-medium text-red-600">
                          Expires on{" "}
                          {new Date(
                            currentCampaign.expiresOn,
                          ).toLocaleDateString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            weekday: undefined,
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </p>

                        <button
                          className="flex w-fit cursor-pointer items-center rounded-full bg-black px-4 py-2 font-poppins text-sm font-medium text-white hover:scale-[102%]"
                          onClick={handleUpgrade}
                        >
                          Renew Now
                        </button>
                      </>
                    ) : null}
                  </div>

                  <Tooltip
                    id="download"
                    className="custom-tooltip"
                    noArrow
                    place="bottom"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                  />

                  <Tooltip
                    id="more"
                    className="custom-tooltip"
                    noArrow
                    place="left"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                  />
                  {progress !== 100 ? (
                    <div>
                      <p className="mb-2 text-right text-sm text-[#5f5f5f] lg:text-[15px]">
                        {steps} Completed
                      </p>
                      <div className="m-0 flex h-1 items-center justify-end rounded-lg border border-gray-400 p-0 lg:h-1.5">
                        <div
                          className="h-1 w-10  rounded-lg bg-black lg:h-1.5 lg:w-16"
                          style={{
                            width: `${progress}%`,
                            backgroundColor: "black",
                          }}
                        ></div>
                      </div>
                    </div>
                  ) : null}

                  {currentCampaign._id && (
                    <>
                      {progress === 100 && (
                        <button
                          disabled={!currentCampaign.gameId ? true : false}
                          onClick={() => {
                            currentCampaign.gameId &&
                              handleDownload(currentCampaign._id);
                          }}
                          className="hidden h-10 w-fit items-center gap-x-2 rounded-md border-[#b3b3b3] bg-[#eeeeee] px-4 py-2 font-poppins text-base hover:scale-[102%] hover:opacity-80 sm:flex"
                          data-tooltip-id="download"
                          data-tooltip-content="Download Data"
                        >
                          {" "}
                          <Download className="mr-1 h-4 w-4" />
                        </button>
                      )}
                      <TbDotsVertical
                        size={20}
                        onClick={toggleDropdown}
                        className="cursor-pointer text-gray-700 outline-none hover:text-gray-900"
                        data-tooltip-id="more"
                        data-tooltip-content="View More"
                      />
                    </>
                  )}
                </div>
              </div>{" "}
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-10 top-40 z-50 mt-2 w-fit rounded-lg border border-gray-300 bg-white shadow-lg"
                >
                  <ul className="list-none p-2 text-[15px]">
                    <li
                      className="flex cursor-pointer items-center space-x-3 rounded px-4 py-2 text-black hover:bg-[#f6f6f7]"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setIsManageProjectModalOpen(true);
                      }}
                    >
                      <BsPersonGear className="h-4 w-4 text-black" />
                      <p>Manage</p>
                    </li>
                    <li
                      className="flex cursor-pointer items-center space-x-3 rounded px-4 py-2 text-black hover:bg-[#f6f6f7]"
                      onClick={() => {
                        const section = "Billing";
                        localStorage.setItem("section", section);
                        setIsDropdownOpen(false);

                        navigate("/dashboard/profile", {
                          state: { campaignId: ccid },
                        });
                      }}
                    >
                      <CreditCard className="h-4 w-4 text-black" />
                      <p>Payment History</p>
                    </li>
                    <li
                      className="flex cursor-pointer items-center space-x-3 rounded px-4 py-2 text-black hover:bg-[#f6f6f7]"
                      onClick={() => {
                        setIsDropdownOpen(false);
                        setShowDelete(!showdelete);
                        setDeleteId(currentCampaign._id);
                      }}
                    >
                      <Trash className="h-4 w-4 text-red-600" />
                      <p>Delete</p>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className=" my-4 flex flex-row  justify-end ">
              {currentCampaign._id && (
                <div className="flex justify-between gap-x-4 sm:hidden">
                  <button
                    disabled={!currentCampaign.gameId ? true : false}
                    onClick={() => {
                      currentCampaign.gameId &&
                        handleDownload(currentCampaign._id);
                    }}
                    className="flex h-10 w-fit items-center gap-x-2 rounded-md border-[#b3b3b3] bg-[#eeeeee] px-4 py-2 font-poppins text-base hover:scale-[102%] hover:opacity-80"
                  >
                    Download data
                    <Download className="h-4 w-4" />
                  </button>
                </div>
              )}
            </div>
            <div className="rounded-xl border bg-white p-4 text-black lg:px-6 lg:py-6">
              {!currentCampaign.gameId ? (
                <p className="mb-6 mt-1 font-poppins text-[15px] lg:text-base">
                  Please, fill out the project setup details in order to tailor
                  your game to your preferences and generate your personalized
                  link.
                </p>
              ) : (
                <p className="mb-6 mt-1 font-poppins text-base">
                  Your tailored game is set and ready for play. Below, you can
                  copy the link, get the embed code, or download the QR code.
                </p>
              )}
              {currentCampaign.gameId && currentCampaign._id && (
                <div className="my-4">
                  <div className="my-4">
                    <div className="mb-6 flex w-full items-center justify-between gap-x-5 sm:gap-x-4">
                      <button
                        onClick={() => setSelectedAction("copy")}
                        className={`flex w-full items-center justify-center gap-x-2 rounded-md px-4 py-4 text-center text-base font-medium shadow hover:scale-[102%] lg:px-10 ${
                          selectedAction === "copy"
                            ? "border-2 border-gray-300 bg-[#e5e5e5]"
                            : "bg-[#e5e5e5]"
                        }`}
                      >
                        <AiOutlineLink className="h-6 w-6" />{" "}
                        <span className="hidden lg:flex">Copy link</span>
                      </button>
                      <button
                        onClick={() => setSelectedAction("embed")}
                        className={`flex w-full items-center justify-center gap-x-2 rounded-md px-4 py-4 text-base font-medium shadow hover:scale-[102%] lg:px-10 ${
                          selectedAction === "embed"
                            ? "border-2 border-gray-200 bg-[#f4f4f4]"
                            : "bg-[#f4f4f4]"
                        }`}
                      >
                        <Code className="h-6 w-6 text-gray-500" />{" "}
                        <span className="hidden text-gray-500 lg:flex">
                          Embed
                        </span>
                      </button>
                      <button
                        onClick={() => {
                          setSelectedAction("qr");
                          setTimeout(downloadQRCode, 0);
                        }}
                        className={`flex w-full items-center justify-center gap-x-2 rounded-md px-4 py-4 text-base font-medium shadow hover:scale-[102%] lg:px-10  ${
                          selectedAction === "qr"
                            ? "border-2  bg-eerie-black"
                            : "bg-eerie-black"
                        } text-white`}
                      >
                        <AiOutlineQrcode className="h-6 w-6" />{" "}
                        <span className="hidden lg:flex">Download QR</span>
                      </button>
                    </div>

                    <div className="mb-2 w-full border-b border-[#0000001e]"></div>
                    {selectedAction === "copy" && (
                      <div className="mb-6">
                        <p className="mb-2 text-gray-800">
                          Copy this link and share it with others:
                        </p>

                        <div className="relative mb-4 flex flex-col items-center">
                          <input
                            className="h-14 w-full rounded-md px-3 font-poppins outline-none"
                            type="text"
                            value={Link}
                            readOnly
                          />
                          <button
                            onClick={handleCopy}
                            className="absolute right-0 h-14 w-20 rounded-r-md border-[2px] border-l-2 border-[#bebebe] bg-[#e5e5e5] p-2 text-base font-medium text-black"
                          >
                            <span>{isCopied ? "Copied!" : "Copy"}</span>
                          </button>
                        </div>
                      </div>
                    )}
                    {selectedAction === "embed" && (
                      <div className="mb-6">
                        <p className="mb-2 text-gray-800">
                          Copy this embed code and add it to websites:
                        </p>
                        <div className="relative flex items-center">
                          <input
                            className="h-14 w-full rounded-md border border-[#bebebe] px-3 font-poppins text-gray-600 outline-none"
                            type="text"
                            value={`<iframe src="${Link}" width="100%" height="650"></iframe>`}
                            readOnly
                          />
                          <button
                            onClick={handleEmbedCopy}
                            className="absolute right-0 h-14 w-20 rounded-r-md border-[2px] border-l-2 border-[#b3b3b3] bg-[#f4f4f4] p-2 text-base font-medium text-black"
                          >
                            <span>{isCopied ? "Copied!" : "Copy"}</span>
                          </button>
                        </div>
                      </div>
                    )}

                    {selectedAction === "qr" && (
                      <div className="mb-4">
                        <p className="mb-2 text-gray-800">
                          Download this QR code to share your game:
                        </p>
                        <div className="mb-4 flex justify-center">
                          <div className="qr-code bg-white p-2" ref={qrRef}>
                            <QRCode
                              value={Link}
                              size={256}
                              renderAs="svg"
                              imageSettings={{
                                src: `${process.env.REACT_APP_S3_URL}/assets/WorkPlay_FaviCon.svg`,
                                x: undefined,
                                y: undefined,
                                height: 48,
                                width: 48,
                                excavate: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <button
                    className="h-14 w-full rounded-md border-[1px] border-[#bebebe] bg-black font-poppins text-base font-semibold text-white"
                    onClick={downloadQRCode}
                  >
                    Download QR code
                  </button> */}
                </div>
              )}
              <div className="flex flex-col gap-x-5 gap-y-2">
                <div className="cursor-pointer items-center rounded-md border border-[#bebebe]">
                  <div>
                    {!setUpCompleted ? (
                      <div>
                        <div className="flex items-center justify-between rounded-md bg-stone-50 px-4 py-3">
                          <p className="font-poppins text-[15px] font-medium uppercase tracking-wider text-black lg:text-base">
                            <FontAwesomeIcon
                              className="mr-3 h-3"
                              icon={faChevronDown}
                            ></FontAwesomeIcon>
                            Configuration
                          </p>
                        </div>
                        <div className="w-auto border-b border-[#bebebe]"></div>
                        <div className="px-4 py-6 lg:p-8">
                          <div className="gap-8 lg:grid lg:grid-cols-2">
                            <div className="">
                              <p className="mb-2 font-poppins text-base font-normal text-black">
                                1. Title
                                <span className=" text-red-600">&nbsp;*</span>
                              </p>
                              <input
                                type="text"
                                id="campaignInput"
                                className="h-12 w-full rounded-md px-3 font-poppins outline-none"
                                value={campaignName}
                                onChange={(e) =>
                                  setCampaignName(e.target.value)
                                }
                                placeholder="Merry Go Spin"
                              />
                            </div>
                            <div className="w-full">
                              <p className="-mb-3 mt-3 font-poppins text-base font-normal text-black lg:mb-5 lg:mt-0">
                                2. Select a game type
                                <span className=" text-red-600">&nbsp;*</span>
                              </p>
                              <div className="gap-x-3 lg:flex">
                                {gameOptions.map((option) => (
                                  <div key={option.value} className="">
                                    <label className="rounded-md border border-[#b3b3b3] bg-white p-3 text-[black] opacity-80">
                                      <input
                                        type="radio"
                                        value={option.value}
                                        checked={
                                          selectedOption === option.value
                                        }
                                        onChange={(e) =>
                                          !currentCampaign.gameId &&
                                          !fromValue &&
                                          setSelectedOption(e.target.value)
                                        }
                                        className="mr-2 mt-10 font-poppins lg:mt-0"
                                      />
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div>
                              <p className="mb-2 mt-5 font-poppins text-base font-normal text-black lg:mt-0">
                                3. Start Date
                                <span className=" text-red-600">&nbsp;*</span>
                              </p>
                              <DatePicker
                                autoComplete="off"
                                id="campaignInput"
                                selected={startDate}
                                onChange={(date: Date) => {
                                  // If `ccid` is present but `expiresOn` is not, show popup
                                  if (
                                    currentCampaign?._id &&
                                    !currentCampaign?.expiresOn
                                  ) {
                                    setIsStartDatePopUpModalOpen(true);
                                  }
                                  // If `ccid` is not present, allow selecting any date
                                  else {
                                    setStartDate(date);
                                    const newEndDate = new Date(date);
                                    newEndDate.setDate(
                                      newEndDate.getDate() + 1,
                                    );
                                    setEndDate(newEndDate);
                                  }
                                }}
                                minDate={new Date()}
                                maxDate={
                                  currentCampaign?._id &&
                                  currentCampaign?.expiresOn
                                    ? new Date(
                                        new Date(
                                          currentCampaign.expiresOn,
                                        ).setDate(
                                          new Date(
                                            currentCampaign.expiresOn,
                                          ).getDate() - 1,
                                        ),
                                      )
                                    : undefined
                                } // Set maxDate to 1 day before expiresOn
                                className="datepicker rounded-md px-4 py-3 text-base outline-none"
                                placeholderText="MM/DD/YYYY"
                              />
                            </div>
                            <div className="w-full">
                              <p className="mb-2 mt-3 font-poppins text-base font-normal text-black lg:mt-0">
                                4. End Date
                                <span className=" text-red-600">&nbsp;*</span>
                              </p>
                              <DatePicker
                                id="campaignInput"
                                autoComplete="off"
                                selected={endDate}
                                onChange={(date: Date) => {
                                  // Case 1: If expiresOn is present, allow changing endDate
                                  if (currentCampaign?.expiresOn) {
                                    const validStartDate =
                                      startDate instanceof Date
                                        ? startDate
                                        : new Date();

                                    if (date < validStartDate) {
                                      date = new Date(validStartDate);
                                    }
                                    const expiresOnDate = new Date(
                                      currentCampaign.expiresOn,
                                    );
                                    if (date > expiresOnDate) {
                                      date = expiresOnDate;
                                    }
                                    setEndDate(date);
                                  } else {
                                    if (ccid) {
                                      setIsStartDatePopUpModalOpen(true);
                                    } else {
                                      setIsPopUpModalOpen(true);
                                    }
                                  }
                                }}
                                minDate={
                                  startDate ? new Date(startDate) : new Date()
                                }
                                maxDate={
                                  currentCampaign?.expiresOn
                                    ? new Date(currentCampaign.expiresOn)
                                    : undefined
                                }
                                className={`datepicker rounded-md px-4 py-3 text-base outline-none ${
                                  !startDate ? "cursor-not-allowed" : ""
                                }`}
                                placeholderText="MM/DD/YYYY"
                                onFocus={() => {
                                  // Trigger the modal when the user clicks the input field if expiresOn is not available
                                  if (!currentCampaign?.expiresOn && !ccid) {
                                    setIsPopUpModalOpen(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="mt-6 flex items-center justify-end gap-x-4">
                            {!ccid ? (
                              <>
                                <button
                                  className="flex h-[44px] w-[100px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2"
                                  onClick={handleFormSubmit}
                                >
                                  Save
                                </button>
                                <button
                                  className="flex h-[44px] w-[100px] items-center justify-center rounded-md border border-solid border-black bg-black text-base font-medium text-white hover:scale-1.2 "
                                  onClick={() => discardCampaign()}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="flex h-10 w-[100px] items-center justify-center rounded-md border border-solid border-black bg-black text-[15px] font-medium text-white hover:scale-1.2 lg:h-[44px] lg:text-base"
                                  onClick={handleCampaignUpdate}
                                >
                                  Update
                                </button>{" "}
                                <button
                                  className="flex h-10 w-[100px] items-center justify-center rounded-md border border-solid border-black bg-black text-[15px] font-medium text-white hover:scale-1.2 lg:h-[44px] lg:text-base "
                                  onClick={() => {
                                    updateCancel();
                                  }}
                                >
                                  Cancel
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between rounded-md bg-stone-50 px-4 py-3">
                        <p className="font-poppins text-[15px] font-medium tracking-wider text-black lg:text-base lg:uppercase">
                          <FontAwesomeIcon
                            className="mr-3 h-3"
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                          Configuration
                        </p>
                        <div
                          className="flex items-center gap-x-3 py-1 text-sm hover:scale-[102%] hover:opacity-60 lg:text-base"
                          onClick={handleSetUpEdit}
                        >
                          <FontAwesomeIcon icon={faPen} />
                          <p>Edit</p>
                        </div>
                      </div>
                    )}

                    {setUpCompleted ? (
                      <div className="flex items-center justify-between rounded-b-md border-t border-[#bebebe] bg-stone-50 px-4 py-3">
                        <p className="font-poppins text-[15px]  font-medium tracking-wider lg:text-base lg:uppercase">
                          <FontAwesomeIcon
                            className="mr-3 h-3"
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                          Customization
                        </p>
                        {progress === 50 && !gameId ? (
                          <div
                            onClick={() => navigateToGame()}
                            className="rounded-full py-1.5 text-center text-sm hover:scale-[102%] hover:border-black hover:opacity-80 lg:w-28 lg:border lg:bg-black lg:text-white"
                          >
                            Get Started
                          </div>
                        ) : (
                          <div
                            className="flex items-center gap-x-3 py-1 hover:scale-[102%] hover:opacity-60"
                            onClick={() => {
                              if (currentCampaign.game === "quiz") {
                                window.open(
                                  `${REACT_APP_CUSTOM_QUIZ_URL}/?ccid=${ccid}&gameId=${gameId}&xSudTzMYmd=${token}`,
                                  "_self",
                                );
                              } else {
                                window.open(
                                  `${REACT_APP_CUSTOM_SPIN_URL}/?ccid=${ccid}&gameId=${gameId}&xSudTzMYmd=${token}`,
                                  "_self",
                                );
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                            <p>Edit</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`flex items-center justify-between rounded-b-md border-t border-[#bebebe] bg-stone-50 px-4 py-3 ${isHovered ? "cursor-not-allowed" : "hover:bg-stone-100"}`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        title="Complete the campaign set up first"
                      >
                        <p className="flex items-center font-poppins text-[15px] font-medium uppercase tracking-wider lg:text-base">
                          <FontAwesomeIcon
                            className="mr-3 h-3"
                            icon={faChevronRight}
                          ></FontAwesomeIcon>
                          Customization
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showdelete && (
        <DeleteProject
          id={deleteId}
          close={() => setShowDelete(false)}
        ></DeleteProject>
      )}

      {isPopUpModalOpen && (
        <PopUpModal
          close={setIsPopUpModalOpen}
          title="Default Duration"
          message="By default, the project is set for 24 hours from the start date. To modify the end date and activate the project, complete the setup and upgrade to a plan. For further assistance, reach out to us."
          contactButtonText="Contact Us"
          planButtonText="Explore Plans"
        />
      )}
      {isStartDatePopUpModalOpen && (
        <PopUpModal
          close={setIsStartDatePopUpModalOpen}
          title="Upgrade Required"
          message="To modify the dates, please upgrade to a plan. For more details, reach out to us."
          contactButtonText="Contact Us"
          planButtonText="Explore Plans"
          ccid={currentCampaign._id}
        />
      )}
      {isManageProjectModalOpen && (
        <ManageProjectModal
          close={setIsManageProjectModalOpen}
          campaignDetails={currentCampaign}
        />
      )}
      {isSelectPlanOpen && (
        <SelectPlanModal
          handleViewPlans={handleViewPlans}
          close={setIsSelectPlanOpen}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default CampaignForm;
