import { useNavigate } from "react-router-dom";
import {
  deleteCampaign,
  getAllCampaigns,
} from "../../features/Campaigns/campaignSlice";
import { useAppDispatch } from "../../app/hooks";
import { X } from "react-feather";

interface DeleteProjectProps {
  id: string;
  close: () => void;
  onDelete?: () => void;
}

const DeleteProject: React.FC<DeleteProjectProps> = ({
  id,
  close,
  onDelete,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      await dispatch(deleteCampaign(id)).unwrap();
      // If successful, dispatch getAllCampaigns and navigate
      dispatch(getAllCampaigns());
      close();
      navigate("/dashboard/campaigns");
    } catch (error: unknown) {
      close();
      if (error instanceof Error) {
        console.error("Failed to delete campaign:", error);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden transition">
      <div className="z-10 flex h-screen w-screen bg-transparent backdrop-blur"></div>
      <div className="absolute left-1/2 top-1/2 z-20 flex w-[90%] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-y-4 rounded-lg border-[2px] border-[#d7dadf] bg-[#ffffff] bg-opacity-95 pb-4 sm:w-auto sm:min-w-[500px]">
        <div className="flex w-full flex-row justify-between rounded-t-md border-b-[1px] border-[#bbbdbe] bg-[#fafaf9] px-6 pb-3 pt-3">
          <p className="font-poppins text-lg font-semibold text-black">
            Delete project
          </p>

          <X
            className="cursor-pointer text-red-600 hover:scale-110"
            size={24}
            onClick={() => close()}
          />
        </div>

        <div className="flex max-w-[520px] flex-col items-start justify-start text-wrap px-6 py-1">
          <p className="w-full text-start font-poppins text-base font-medium">
            Are you sure you want to delete this project ? This action is
            permanent and cannot be undone.
          </p>
        </div>
        <div className="-mt-2 flex w-full flex-row justify-end space-x-4 py-2 pr-6">
          <button
            className="min-w-32 cursor-pointer rounded-md bg-mercury px-3 py-2 text-center font-poppins  text-sm font-medium  text-black hover:scale-[102%] lg:text-[15px]"
            onClick={() => close()}
          >
            Cancel
          </button>{" "}
          <button
            onClick={() => handleDelete(id)}
            className="min-w-32 cursor-pointer rounded-md bg-black px-3 py-2 text-center font-poppins  text-sm font-medium  text-white hover:scale-[102%] lg:text-[15px]"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProject;
