import {
  parsePhoneNumberFromString,
  PhoneNumber,
  CountryCode,
} from "libphonenumber-js";

/**
 * Utility function to format a contact number based on the country.
 *
 * @param contact - The phone number to format.
 * @param country - The country code (ISO 3166-1 alpha-2).
 * @returns The formatted phone number or the original contact if invalid.
 */
export const formatContactNumber = (
  contact: string,
  country: string,
): string => {
  if (!contact || !country) return "";

  const countryCode = country as CountryCode; // Cast to CountryCode type

  const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
    contact,
    countryCode,
  );

  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.formatInternational(); // Format the phone number in international format
  }

  return contact; // Return the original contact if it's invalid
};
