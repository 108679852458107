import React from "react";
import "../../../styles/Sections/Game.css";

type StrategyItemProps = {
  title: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  reverse?: boolean;
};

const StrategyItem: React.FC<StrategyItemProps> = ({
  title,
  description,
  imgSrc,
  imgAlt,
  reverse = false,
}) => {
  return (
    <div
      className={`w-full items-center justify-between lg:flex ${reverse ? "flex-row-reverse" : ""}`}
    >
      <div className="mt-5 w-full lg:mt-0 lg:w-[90%] xl:w-2/3">
        <h3 className="text-center font-epilogue text-xl font-medium text-mercury md:text-2xl lg:text-left xl:text-3xl">
          {title}
        </h3>
        <p className="mb-6 mt-4 font-plusJakartaSans text-silverChalice sm:text-lg lg:my-6 xl:text-xl">
          {description}
        </p>
      </div>
      <div className="strategy-img-border mx-auto flex h-60 w-60 items-center justify-center lg:mx-0 lg:mt-0 lg:h-96 lg:w-96">
        <img src={imgSrc} alt={imgAlt} className="w-[70%] lg:w-auto" />
      </div>
    </div>
  );
};

type StrategyProps = {
  title: string;
  items: {
    title: string;
    description: string;
    imgSrc: string;
    imgAlt: string;
    reverse?: boolean;
  }[];
};

const Strategy: React.FC<StrategyProps> = ({ title, items }) => {
  return (
    <section className="px-6 md:px-12 md:py-16 xl:px-20 xl:pb-36">
      <h2 className="my-6 text-center font-epilogue text-2xl font-semibold text-mercury md:text-3xl lg:my-20 xl:text-5xl">
        {title}
      </h2>
      <div className="flex flex-col space-y-8 lg:space-y-10">
        {items.map((item, index) => (
          <StrategyItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
};

export default Strategy;
