import LandingCTA from "../../components/LandingCTA";
import HowToCustomize from "./sections/HowToCustomize";
import HowToIntegrate from "./sections/HowToIntegrate";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Event Engagement", id: "eventEngagement" },
  { label: "Customer Rewards Program", id: "customerRewardsProgram" },
  { label: "Seasonal Promotions", id: "seasonalPromotions" },
  { label: "Gamified Sales Incentives", id: "gamifiedSalesIncentives" },
  { label: "Social Media Engagement", id: "socialMediaEngagement" },
];

const useCaseDetails = [
  {
    id: "eventEngagement",
    title: "Event Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/eventEngagement.webp`,
    content: [
      "Bring energy to your live or virtual events by incorporating Spin the Wheel. Offer prizes or exclusive content to participants, encouraging active engagement and making your event memorable.",
    ],
  },
  {
    id: "customerRewardsProgram",
    title: "Customer Rewards Program",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/customerRewards.webp`,
    content: [
      "Reward customer loyalty with the fun of spinning for discounts, free items, or special perks. This interactive experience keeps your audience coming back and fosters stronger connections with your brand.",
    ],
  },
  {
    id: "seasonalPromotions",
    title: "Seasonal Promotions",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/seasonalPromotions.webp`,
    content: [
      "Boost excitement during holidays or new launches by customizing the wheel with limited-time rewards and offers. Seasonal themes make your campaigns stand out, driving attention and participation.",
    ],
  },
  {
    id: "gamifiedSalesIncentives",
    title: "Gamified Sales Incentives",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/salesIncentive.webp`,
    content: [
      "Motivate sales teams or customers with an engaging twist—spin the wheel to unlock rewards after achieving specific targets or completing purchases. This gamification adds fun while driving results.",
    ],
  },
  {
    id: "socialMediaEngagement",
    title: "Social Media Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/socialMedia.webp`,
    content: [
      "Elevate your social campaigns by offering spin-to-win opportunities. Encourage users to follow, share, or tag friends for a chance to spin, boosting visibility and growing your online presence.",
    ],
  },
];

const strategyItems = [
  {
    title: "Driving Excitement and Engagement",
    description:
      "Spin the Wheel creates an immersive experience with instant rewards that keep participants engaged. Its simplicity and universal appeal make it suitable for all audiences, ensuring maximum reach and impact.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/drivingExcitement.webp`,
    imgAlt: "drivingExcitementEngagement",
    reverse: true,
  },
  {
    title: "Customizable and Versatile",
    description:
      "Whether it’s for promotions, events, or loyalty programs, the game’s adaptable design integrates seamlessly with your brand. Tailor the visuals and mechanics to suit any occasion, creating a memorable and impactful interaction.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/customizableVersatile.webp`,
    imgAlt: "customizableVersatile",
  },
  {
    title: "The Ultimate Audience Connector",
    description:
      "By combining the thrill of chance with your brand’s unique identity, Spin the Wheel becomes more than a game—it’s a powerful tool for driving awareness, fostering loyalty, and creating lasting impressions.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/audienceConnector.webp`,
    imgAlt: "ultimateAudienceConnector",
    reverse: true,
  },
];

const sections = [
  {
    id: "createYourWheelofFun",
    title: "Create Your Wheel of Fun",
    description: `Personalize every aspect of the game by adding your logo, title, and description. Customize the wheel segments with rewards such as discounts (e.g., "40% off," "60% off") or surprises (e.g., "No luck"). Modify, add, or duplicate segments to keep the gameplay fresh and exciting. Additionally, set clear instructions to ensure a smooth and enjoyable player experience.`,
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/wheelOfFun.webp`,
  },
  {
    id: "spininStyle",
    title: "Spin in Style",
    description:
      "Design a visually captivating experience by selecting a custom color palette or uploading a background image that aligns with your brand. Adjust text styles, including color, font, and size, to create a cohesive look. This attention to detail helps to craft a fun and immersive atmosphere for players, enhancing their overall experience.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/spinInStyle.webp`,
  },
  {
    id: "elevateEngagementwithInteractiveFeatures",
    title: "Elevate Engagement with Interactive Features",
    description:
      "Collect player details, such as names or emails, to build your database and foster ongoing engagement. Track participation by focusing on winners or all players to monitor activity. Enable gamification features to further enhance the player experience, rewarding interaction and keeping players motivated.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/interactive.webp`,
  },
  {
    id: "fineTuneGameMechanics",
    title: "Fine-Tune Game Mechanics",
    description:
      "Customize every spin by adjusting segment probabilities, either equally or with custom percentages, to create the desired outcome. Define win/loss scenarios for a more tailored experience, ensuring each spin is unique. From generous discounts to playful “No luck” slices, this flexibility allows you to align the game with your campaign goals and keep players engaged.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/fineTune.webp`,
  },
  {
    id: "previewandGoLive",
    title: "Preview and Go Live",
    description:
      "Refine your game to perfection by previewing every detail. Once ready, generate a shareable link and launch it to captivate your audience and boost engagement.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/previewAndGoLive.webp`,
  },
];

const handlePlay = () => {
  window.open("https://spin-the-wheel.workplay.digital/", "_target");
};

const handleCustomize = () => {
  window.open("https://custom-spin.workplay.digital/", "_target");
};

const SpinTheWheel = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Spin the <br /> Wheel
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Spin the Wheel
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Spin the Wheel combines excitement and rewards, perfect for events
              or promotions. Customizable and visually stunning, it engages
              audiences with prizes and surprises, creating memorable,
              interactive brand experiences.
            </p>
            <div className="flex items-center space-x-6">
              <button
                className="headerBtn relative w-full rounded-full bg-tanHide px-4 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
                onClick={handlePlay}
              >
                Play Now
              </button>
              <button
                className="headerBtn relative w-full rounded-full bg-merlin px-4 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtnLogin hover:bg-tuatara sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
                onClick={handleCustomize}
              >
                Customize
              </button>
            </div>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/spinGroup.webp`}
            alt="spinTheWheelGroup"
            className="absolute bottom-[-10%] right-[-7%] z-0 hidden w-[90%] sm:bottom-[-10%] sm:block md:bottom-[-65%] lg:bottom-[-110%] lg:w-[70%] xl:right-[-6%] xl:top-[-76%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/spinTheWheel/spin.webp`}
            alt="spinTheWheel"
            className="absolute right-0 top-[-2%] h-[15vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <HowToIntegrate />
      <UseCase
        title="How You Can Use Spin The Wheel"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <HowToCustomize sections={sections} />
      <Strategy
        title="How Spin The Wheel Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default SpinTheWheel;
