import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PlanDetails } from "../types/planTypes";
import { getAllSubscriptions } from "../services/subscriptionApi";

interface PlanState {
  selectedPlan: PlanDetails | null;
  allPlans: PlanDetails[];
  loading: boolean;
  error: string | null;
}

const initialState: PlanState = {
  selectedPlan: null,
  allPlans: [],
  loading: false,
  error: null,
};

export const fetchAllPlans = createAsyncThunk<PlanDetails[], void>(
  "plan/fetchAllPlans",
  async () => {
    try {
      const response = await getAllSubscriptions();
      return response;
    } catch (error) {
      throw new Error("Failed to fetch subscriptions");
    }
  },
);

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    setPlan: (state, action: PayloadAction<PlanDetails>) => {
      state.selectedPlan = action.payload;
    },
    setAllPlans: (state, action: PayloadAction<PlanDetails[]>) => {
      state.allPlans = action.payload;
    },
    clearPlan: (state) => {
      state.selectedPlan = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.allPlans = action.payload;
      })
      .addCase(fetchAllPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setPlan, setAllPlans, clearPlan } = planSlice.actions;
export default planSlice.reducer;
