import { NavigateFunction } from "react-router-dom";

export const handlePriceButtonClick = (
  e: React.MouseEvent<HTMLButtonElement>,
  navigate: NavigateFunction,
  ccid: string | null,
  planDetails: any,
) => {
  e.stopPropagation();
  const token = localStorage.getItem("xSudTzMYmd");

  if (planDetails.title === "Business") {
    window.location.href = "/contact";
  } else {
    if (!token) {
      navigate("/login");
    } else {
      if (ccid) {
        navigate(`/checkout?ccid=${ccid}&sid=${planDetails._id}`);
        // if (!trialAvailable) {
        //   navigate(`/checkout?ccid=${ccid}&sid=${planDetails._id}`);
        // } else {
        //   navigate(`/dashboard/gamedetails?ccid=${ccid}`);
        // }
      } else {
        // navigate(`/dashboard/campaigns?sid=${planDetails._id}`);

        navigate(`/dashboard/campaigns?sid=${planDetails._id}`, {
          state: {
            showModal: true,
            message:
              "To proceed with your selected plan, you must first choose a project for the plan to apply to. This step is required to activate and access your project.",
          },
        });
      }
    }
  }
};
