import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import GoogleSSO from "../GoogleSSO";
import LazyLoadImages from "../LazyLoadImages";
import { Eye, EyeOff } from "react-feather";
import "../../styles/Input.css";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const urlParams = new URLSearchParams(window.location.search);
  const fromValue = urlParams.get("from");
  const campaignId = urlParams.get("gameId");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handleEmailFocus = () => setIsEmailFocused(true);
  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const handlePasswordFocus = () => setIsPasswordFocused(true);
  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  useEffect(() => {
    const storedCredentialsString = Cookies.get("xSudTzMYmdCred");
    try {
      if (storedCredentialsString) {
        const storedCredentials = JSON.parse(storedCredentialsString);
        setEmail(storedCredentials.email);
        setPassword(storedCredentials.password);
      }
    } catch (error) {
      console.error("Error parsing stored credentials:", error);
    }
  }, []);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogoRedirect = () => {
    navigate("/");
  };

  const redirectToGame = (token: any) => {
    if (campaignId && fromValue) {
      navigate(
        `/dashboard/campaigns/create?gameId=${encodeURIComponent(campaignId)}&fromValue=${fromValue}`,
      );
    }
  };

  const handleSubmit = async () => {
    if (!email && !password) {
      toast.error("Please fill in all fields", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      return;
    }

    let isEmailValid = true;
    let isPasswordValid = true;

    if (!validateEmail(email)) {
      toast.error("Invalid email address", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      isEmailValid = false;
    }

    if (!password) {
      toast.error("Password is required", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      isPasswordValid = false;
    }

    if (isEmailValid && isPasswordValid) {
      const URL = `${REACT_APP_API_URL}/auth/login`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.post(
          URL,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        // Redirect after creating user
        if (response.data.success === true) {
          contextValue?.login();
          localStorage.setItem("xSudTzMYmd", response.data.data.accessToken);
          if (rememberMe) {
            const credentials = { email, password };
            const credentialsString = JSON.stringify(credentials);
            Cookies.set("xSudTzMYmdCred", credentialsString, { expires: 30 });
          } else {
            Cookies.remove("xSudTzMYmdCred");
          }
          if (campaignId && fromValue) {
            redirectToGame(response.data.data.accessToken);
          } else {
            setTimeout(() => {
              navigate("/dashboard/campaigns", { replace: true });
            }, 1000);
          }
          contextValue?.login();
        }
        setPassword("");
        setEmail("");
        console.log("Form submitted!");
        console.log(response);
      } catch (error: any) {
        setPassword("");
        setEmail("");
        toast.error(error.response.data.message, {
          autoClose: 2000,
          className: "toastify-toast",
        });
        console.error("Error creating user:", error);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("xSudTzMYmd")) {
      const token = localStorage.getItem("xSudTzMYmd");
      if (fromValue && campaignId) {
        redirectToGame(token);
      } else navigate("/", { replace: true });
    }
  }, []);

  return (
    <>
      <div
        style={{
          pointerEvents: localStorage.getItem("xSudTzMYmd") ? "none" : "auto",
        }}
      >
        <div className="input-wrapper relative flex w-full items-center overflow-x-hidden bg-codGray py-6 sm:min-h-screen md:py-0">
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/authPages/top-Ellipse.webp`}
            alt="Ellipse"
            className="absolute top-0 z-0"
          />
          <div className="z-100 w-full items-center justify-center px-6 md:flex md:w-[55%] md:px-0 xl:w-[40%]">
            <div>
              <LazyLoadImages
                className="mb-12 h-6 cursor-pointer xl:h-7"
                src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
                alt="workplay-logo"
                onClick={handleLogoRedirect}
              />
              <div className="relative">
                <div className="flex items-center justify-center">
                  <img
                    className="w-full px-14 pb-10 sm:px-20 md:hidden"
                    src={`${REACT_APP_S3_URL}/website-assets/authPages/login.webp`}
                    alt="login"
                  />
                </div>
                <h2 className="text-left font-epilogue text-3xl font-bold text-mercury sm:pr-44 lg:text-left lg:text-4xl">
                  Welcome back
                </h2>{" "}
                <LazyLoadImages
                  src={`${REACT_APP_S3_URL}/website-assets/authPages/sparkle.webp`}
                  alt="sparkle"
                  className="absolute right-[0%] top-[-140%] h-8 lg:right-[-6%] lg:h-auto"
                />
              </div>
              <p className="m-auto mb-6 mt-3 text-left font-epilogue text-[17px] font-medium text-silverChalice lg:text-left">
                Ready to dive back in? Log in now!
              </p>
              <>
                <GoogleSSO />{" "}
                <span className="orline m-auto flex w-full items-center text-center">
                  <p className="my-6 flex  whitespace-nowrap text-mercury">
                    or
                  </p>
                </span>
              </>
              <div className="m-auto flex flex-col items-center justify-center gap-y-4">
                <div className="flex w-full flex-col">
                  <div className="flex w-full flex-col space-y-6">
                    <div className="relative z-100 w-full">
                      <label
                        htmlFor="email"
                        className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                          isEmailFocused
                            ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                            : email
                              ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                              : "text-mercury"
                        }`}
                      >
                        Email Address{" "}
                        <span
                          className={`${isEmailFocused ? "text-tanHide" : "text-red-400"}`}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={handleEmailFocus}
                        onBlur={handleEmailBlur}
                        required
                        className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                      />
                    </div>

                    <div className="relative z-100 w-full">
                      {" "}
                      <label
                        htmlFor="password"
                        className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                          isPasswordFocused
                            ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                            : password
                              ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                              : "text-mercury"
                        }`}
                      >
                        Password{" "}
                        <span
                          className={`${isPasswordFocused ? "text-tanHide" : "text-red-400"}`}
                        >
                          *
                        </span>
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                        placeholder=""
                        required
                        className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                      />
                      {showPassword ? (
                        <EyeOff
                          className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <Eye
                          className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                          onClick={togglePasswordVisibility}
                        />
                      )}
                    </div>
                  </div>
                  <div className="z-100 mb-2 mt-4 flex flex-row items-center justify-between font-plusJakartaSans">
                    <label className="flex cursor-pointer items-center font-normal">
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMe}
                        className="relative mr-2 w-[18px] cursor-pointer appearance-none rounded border border-none border-mercury bg-mercury bg-opacity-10 checked:border-mercury checked:bg-tanHide checked:opacity-100 checked:after:absolute checked:after:right-1 checked:after:text-xs checked:after:text-white checked:after:content-['✓']"
                      />
                      <p className="text-base text-mercury sm:text-[15px]">
                        Remember me
                      </p>
                    </label>
                    <Link
                      to="/forgetpassword"
                      className="cursor-pointer text-base font-medium text-[#5c5c5c] underline hover:opacity-60 sm:text-[15px]"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <button
                  className="headerBtn relative z-100 w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-xl"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </div>

              <p className="z-100 mb-16 mt-5 font-plusJakartaSans font-medium text-mercury sm:mb-0">
                Don't have an account?
                <Link
                  to={
                    fromValue && campaignId
                      ? `/signup?from=${fromValue}&gameId=${campaignId}`
                      : "/signup"
                  }
                  className="underline-link relative ml-1 scale-105 hover:opacity-80"
                >
                  <strong className="win relative font-epilogue text-base font-semibold">
                    Create an Account
                  </strong>
                </Link>{" "}
              </p>
            </div>
          </div>{" "}
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/authPages/pattern.webp`}
            alt="pattern"
            className="absolute bottom-0 right-0 -z-0"
          />
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/authPages/blueEllipse.webp`}
            alt="Ellipse"
            className="absolute bottom-0 right-0 z-0 h-full"
          />
          <div className="z-0 hidden md:block md:w-[45%] xl:w-[60%]">
            <div className="flex items-center justify-center">
              <LazyLoadImages
                className="w-[80%] px-6 md:px-10 lg:px-20"
                src={`${REACT_APP_S3_URL}/website-assets/authPages/login.webp`}
                alt="login"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
