import React, { useState } from "react";
import { X } from "react-feather";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { subscribeTrial } from "../../services/trialApi";
import { toast } from "react-toastify";

interface SelectPlanModalProps {
  handleViewPlans: () => void;
  close: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectPlanModal: React.FC<SelectPlanModalProps> = ({
  handleViewPlans,
  close,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ccid = searchParams.get("ccid");

  const allPlans = useSelector((state: RootState) => state.plan.allPlans);

  const handleSelectPlan = (planId: string) => {
    if (planId && ccid) {
      navigate(`/checkout?ccid=${ccid}&sid=${planId}`);
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGetStarted = async () => {
    if (!ccid) {
      setError("Campaign ID (ccid) is missing.");
      return;
    }

    setLoading(true);
    setError(null);

    const campaignId = ccid;

    try {
      // Call the subscribeTrial API when the Free Trial button is clicked
      const response = await subscribeTrial(campaignId);
      // If the API is successful, redirect to checkout or handle as needed
      console.log("Trial subscription response:", response);
      close(false);
      toast.success("You’ve successfully subscribed to the free trial!", {
        autoClose: 950,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      setError("Failed to subscribe to the free trial. Please try again.");
      toast.error(
        "Failed to subscribe to the free trial. Please try again later.",
      );
      console.error("Error subscribing to the trial:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden transition">
      <div className="z-10 flex h-screen w-screen bg-transparent backdrop-blur"></div>
      <div className="absolute left-1/2 top-1/2 z-20 flex w-[90%] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-y-4 rounded-lg border-[2px] border-[#d7dadf] bg-[#ffffff] bg-opacity-95 pb-4 sm:w-auto sm:min-w-[500px]">
        <div className="flex w-full flex-row justify-between rounded-t-md border-b-[1px] border-[#bbbdbe] bg-[#fafaf9] px-6 pb-3 pt-3">
          <p className="font-poppins font-semibold text-black sm:text-lg">
            Select a plan to upgrade
          </p>
          <X
            className="cursor-pointer text-red-600 hover:scale-110"
            size={24}
            onClick={() => {
              close(false);
            }}
          />
        </div>

        <p className="flex w-full max-w-[520px] flex-col items-start justify-start text-wrap px-6 py-1 text-start font-poppins text-[15px] font-medium sm:text-base">
          To continue, select a plan that best suits your needs from below. You
          can start with the Free Trial or upgrade to our paid plans for more
          benefits.
        </p>

        <div className="flex w-[90%] items-center justify-between rounded-md border border-[#bbbdbe] bg-[#fafaf9] p-2">
          <p className="px-2 font-medium">Free Trial</p>
          <button
            className="w-auto min-w-32 cursor-pointer rounded-md bg-black py-2 text-center font-poppins text-sm font-medium text-white hover:scale-[102%] lg:text-[15px]"
            onClick={handleGetStarted}
            disabled={loading}
          >
            {loading ? "Activating..." : "Get Started"}
          </button>
        </div>

        {allPlans && allPlans.length > 0 ? (
          allPlans.map((plan) => (
            <div
              key={plan._id}
              className="flex w-[90%] items-center justify-between rounded-md border border-[#bbbdbe] bg-[#fafaf9] p-2"
            >
              <p className="px-2 font-medium">{plan.title}</p>
              <button
                className="w-auto min-w-32 cursor-pointer rounded-md bg-black py-2 text-center font-poppins text-sm font-medium text-white hover:scale-[102%] lg:text-[15px]"
                onClick={() => {
                  handleSelectPlan(plan._id);
                }}
              >
                Select
              </button>
            </div>
          ))
        ) : (
          <p className="font-medium">No plans available at the moment</p>
        )}

        <div
          onClick={handleViewPlans}
          className="mt-2 cursor-pointer font-poppins text-sm text-black underline-offset-1 hover:underline hover:opacity-80"
        >
          View All Plans and Pricing Details
        </div>
      </div>
    </div>
  );
};

export default SelectPlanModal;
