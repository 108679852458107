import { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import "../../styles/Checkout.css";
import { BsExclamationCircle } from "react-icons/bs";

const PromoCode = () => {
  const [isInputVisible, setInputVisible] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChevronClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isInputVisible) {
      setPromoCode("");
      setIsError(false);
    }
    setInputVisible((prev) => !prev);
  };

  const handleApplyClick = () => {
    if (!promoCode) return; // Don't proceed if the input is empty

    setIsLoading(true);
    setIsError(false);

    // Simulating API call with setTimeout (replace with actual API call logic)
    setTimeout(() => {
      setIsLoading(false);

      // Simulate a failed promo code response (you can replace this with actual validation logic)
      const isValidPromoCode = false; // Always invalid for testing

      if (isValidPromoCode) {
        // Successfully applied, reset error and promo code
        setPromoCode("");
      } else {
        setIsError(true);
      }
    }, 1000); // Simulate a delay of 1 second for applying
  };

  return (
    <div className="checkout-wrapper my-5">
      <div className="mb-2 flex w-fit cursor-pointer items-center font-semibold hover:underline">
        <p onClick={handleChevronClick} className="mr-2 select-none">
          Have a promo code?
        </p>
        <span onClick={handleChevronClick} className="select-none">
          {isInputVisible ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>

      {isInputVisible && (
        <div className="flex flex-col items-start space-y-2">
          <div className="relative flex w-full items-center space-x-4">
            <input
              type="text"
              className={`h-10 w-full rounded-md border px-2 text-[15px] font-medium uppercase ${
                isError ? "input-error" : ""
              }`}
              value={promoCode}
              onChange={(e) => {
                setPromoCode(e.target.value);
                setIsError(false); // Reset error if new value is entered
              }}
            />
            {isError && (
              <BsExclamationCircle className="absolute right-[112px] top-1/2 -translate-y-1/2 transform text-red-500" />
            )}
            <button
              className={`h-10 rounded-md px-6 text-sm text-white ${
                !promoCode || isLoading || isError
                  ? "cursor-not-allowed bg-stone-800"
                  : "bg-black"
              }`}
              disabled={!promoCode || isLoading}
              onClick={handleApplyClick}
            >
              {isLoading ? "Applying..." : "Apply"}
            </button>
          </div>
          {isError && (
            <p className="flex items-center text-sm font-medium text-red-500">
              Invalid promo code
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default PromoCode;
