import React, { ChangeEvent, FC, useState } from "react";
import Footer from "../components/Footer/Footer";
import axios from "axios";
import Navbar from "../components/Navbar";
import { CountryData } from "react-phone-input-2";
import countriesData from "../assets/countriesData.json";
import PhoneInput from "react-phone-input-2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoadImages from "../components/LazyLoadImages";
import "../styles/Contact.css";
import "../styles/Input.css";

interface ContactPageProps {}
interface CountryInfo {
  country: string;
  nationality: string;
}
interface FormData {
  fullName: string;
  email: string;
  contactNumber?: string;
  message: string;
}

const { REACT_APP_API_URL, REACT_APP_S3_URL } = process.env;

const Contact: FC<ContactPageProps> = () => {
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isContactFocused, setIsContactFocused] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false);
  const [nationality, setNationality] = useState("");
  const [phoneNumberWithoutDialCode, setPhoneNumberWithoutDialCode] =
    useState("");
  const [dialCode, setDialCode] = useState("");
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    message: "",
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNameFocus = () => setIsNameFocused(true);
  const handleNameBlur = () => {
    setIsNameFocused(false);
  };

  const handleEmailFocus = () => setIsEmailFocused(true);
  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const handleMessageFocus = () => setIsMessageFocused(true);
  const handleMessageBlur = () => {
    setIsMessageFocused(false);
  };

  const handleContactFocus = () => setIsContactFocused(true);
  const handleContactBlur = () => {
    setIsContactFocused(false);
  };

  // PhoneNumber validation
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Find the country info based on the country name
    const countryInfo: CountryInfo | undefined = countriesData.find(
      (info: CountryInfo) => info.country === country.name,
    );

    if (!countryInfo) {
      console.error(`Country info not found for name: ${country.name}`);
      return;
    }
    setNationality(countryInfo.nationality);
    const dialCode = country.dialCode;
    setDialCode(dialCode);

    const phoneNumberWithoutDialCodeValue = value.replace(dialCode, "");
    setPhoneNumberWithoutDialCode(phoneNumberWithoutDialCodeValue);
    setFormData((prevState) => ({
      ...prevState,
      contactNumber: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { fullName, email, message } = formData;

      const data: any = {
        fullName,
        email,

        message,
        iss: process.env.REACT_APP_WORKPLAY_URL,
      };

      if (phoneNumberWithoutDialCode) {
        if (
          nationality === "Indian" &&
          phoneNumberWithoutDialCode.length !== 10
        ) {
          toast.error("Enter a valid phone number", {
            autoClose: 2000,
            className: "toastify-toast",
          });
          return;
        } else {
          data.phoneNumber = phoneNumberWithoutDialCode;
        }
      }

      if (dialCode) {
        data.isdCode = dialCode;
      }

      console.log("Form Data:", data);

      const response = await axios.post(`${REACT_APP_API_URL}/contact`, data);

      console.log("Response:", response.data);
      if (response.status === 200) {
        toast.success(
          "Thanks for reaching out! Your form has been submitted!",
          {
            autoClose: 3000,
            className: "toastify-toast",
          },
        );
      } else {
        throw new Error("Failed to submit the form. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    }

    setFormData({
      fullName: "",
      email: "",
      contactNumber: "",
      message: "",
    });
  };

  return (
    <div className="input-wrapper relative bg-codGray">
      <Navbar />{" "}
      <LazyLoadImages
        src={`${REACT_APP_S3_URL}/website-assets/contactPage/Ellipse.webp`}
        alt="Ellipse"
        className="absolute right-0 top-0 z-0"
      />
      <section className="relative px-6 py-10 font-plusJakartaSans text-mercury sm:p-10 md:px-12 md:pb-16 xl:px-20 xl:pb-0">
        <h1 className="text-lg lg:text-3xl">Contact</h1>
        <h3 className="my-3 w-[75%] font-plusJakartaSans text-4xl font-bold text-white 520_px:w-[60%] md:mt-2 md:text-4xl xl:w-[60%] xl:text-[64px] xl:leading-snug">
          Let’s Connect & Create
        </h3>
        <p className="w-full py-3 font-plusJakartaSans text-base text-silverChalice sm:w-[60%] md:py-6 lg:text-lg xl:w-[45%] xl:py-8 xl:text-xl">
          Looking to transform your marketing with gamified solutions? Whether
          it’s a quick consultation or a deep dive into your needs, our team is
          ready to help you create experiences that engage and deliver results.
        </p>
        <div>
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/contactPage/contact-vector.webp`}
            alt="contact"
            className="absolute right-0 top-10 h-[18vh] sm:h-[35vh] xl:top-6 xl:h-auto xl:w-auto"
          />
        </div>
        <div className="my-10 flex w-full justify-between xl:mb-32 xl:mt-20">
          <div className="flex w-full text-mercury lg:w-[520px] xl:w-[540px]">
            <form
              onSubmit={handleSubmit}
              method="POST"
              className="flex w-full flex-col justify-between space-y-4"
            >
              <div className="relative w-full">
                <label
                  htmlFor="fullName"
                  className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                    isNameFocused
                      ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                      : formData.fullName
                        ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  Full Name{" "}
                  <span
                    className={`${isNameFocused ? "text-tanHide" : "text-red-400"}`}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder=""
                  value={formData.fullName}
                  onChange={handleInputChange}
                  onFocus={handleNameFocus}
                  onBlur={handleNameBlur}
                  required
                  className="contact w-full rounded-md bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:ring-2 focus:ring-tanHide"
                />
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="email"
                  className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                    isEmailFocused
                      ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                      : formData.email
                        ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  Email Address{" "}
                  <span
                    className={`${isEmailFocused ? "text-tanHide" : "text-red-400"}`}
                  >
                    *
                  </span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  value={formData.email}
                  onChange={handleInputChange}
                  onFocus={handleEmailFocus}
                  onBlur={handleEmailBlur}
                  required
                  className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                />
              </div>
              <div className="relative w-full">
                <label
                  htmlFor="contactNumber"
                  className={`absolute left-3 top-3.5 z-100 ml-24 transform transition-all duration-300 ${
                    isContactFocused
                      ? "ml-0 -translate-y-7 bg-codGray px-1 text-tanHide"
                      : formData.contactNumber
                        ? "ml-0 -translate-y-7 bg-codGray  px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  Phone Number
                </label>
                <div className="contact-phone-wrapper z-0">
                  <PhoneInput
                    country={"in"}
                    value={formData.contactNumber || ""}
                    onChange={handlePhoneChange}
                    inputProps={{ id: "contactNumber" }}
                    onFocus={handleContactFocus}
                    onBlur={handleContactBlur}
                  />
                </div>
              </div>

              <div className="relative w-full">
                <label
                  htmlFor="message"
                  className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                    isMessageFocused
                      ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                      : formData.message
                        ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  Your Message{" "}
                  <span
                    className={`${isEmailFocused ? "text-tanHide" : "text-red-400"}`}
                  >
                    *
                  </span>
                </label>

                <div className="input-text">
                  <textarea
                    name="message"
                    id="message"
                    placeholder=""
                    value={formData.message}
                    onChange={handleInputChange}
                    onFocus={handleMessageFocus}
                    onBlur={handleMessageBlur}
                    required
                    className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="headerBtn relative mt-8 w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:mt-4 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              >
                Submit
              </button>
            </form>
          </div>{" "}
          <div className="hidden lg:block lg:w-[40%] xl:w-[50%]">
            <iframe
              title="WorkPlay Studio Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2701413286036!2d77.57940577476015!3d12.890343187417626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1561743cf959%3A0xcb6d5f6ef7b1e7c9!2sWorkPlay%20Studio%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1694608010783!5m2!1sen!2sin"
              width="100%"
              height="460"
              style={{
                border: "0",
                filter: "invert(90%) hue-rotate(180deg)",
              }}
              loading="eager"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="mb-5 w-full lg:mb-0 lg:hidden">
          <iframe
            title="WorkPlay Studio Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2701413286036!2d77.57940577476015!3d12.890343187417626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1561743cf959%3A0xcb6d5f6ef7b1e7c9!2sWorkPlay%20Studio%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1694608010783!5m2!1sen!2sin"
            width="100%"
            height="250px"
            style={{
              border: "0",
              filter: "invert(90%) hue-rotate(180deg)",
            }}
            loading="eager"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <ToastContainer />
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
