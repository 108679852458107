import React from "react";
import Navbar from "../components/Navbar";
import FooterSection from "../components/Footer/Footer";
import LazyLoadImages from "../components/LazyLoadImages";
import LandingCTA from "../components/LandingCTA";

const AboutUs: React.FC = () => {
  return (
    <div className="relative bg-codGray font-plusJakartaSans text-mercury">
      <section className="relative z-10">
        <Navbar />
        <div className="px-6 pb-2 pt-10 text-center md:px-12 lg:py-16 xl:px-20">
          <div className="items-center justify-between gap-x-20 md:flex">
            <h3 className="text-left font-epilogue text-xl font-semibold md:text-[21px] lg:text-3xl">
              At Workplay, we create gaming concepts that foster meaningful
              digital connections between{" "}
              <span className="win font-dynaPuff">consumers and brands</span>.
            </h3>
            <p className="py-4 text-left font-plusJakartaSans leading-relaxed text-silverChalice md:py-0 lg:text-lg">
              These concepts inspire loyalty and drive purchases. Our solutions
              resonate on a human level, uncovering valuable insights into
              consumer behavior and boosting engagement. By providing actionable
              data and optimizing campaigns, we improve conversion rates and
              deliver measurable results.
            </p>
          </div>
          <LazyLoadImages
            src={`${process.env.REACT_APP_S3_URL}/website-assets/aboutPage/peopleWave.webp`}
            alt="team-members"
            className="mx-auto pb-10 pt-6 md:w-[80%] md:pb-16 md:pt-20"
          />
          <div className="w-full justify-between border-y-[1px] border-[#e2e2e28c] py-10 md:flex md:py-16">
            <h3 className="font-epilogue text-xl font-semibold md:w-1/3 md:text-left md:text-2xl lg:text-3xl">
              How Workplay was born
            </h3>
            <p className="pt-4 text-left font-plusJakartaSans leading-relaxed text-silverChalice md:w-1/2 md:py-0 lg:text-lg">
              Elena Santolini, an Italian expat, who moved to India with dreams
              and drive met Mohammed Imtiyaz, an industry veteran, during a
              social gathering, and they came together with an idea to address
              the gap between consumers and brands in India. Fueled by their
              passion for marketing, engagement, and gaming. Together, they
              combined creativity and innovation to transform how brands connect
              with people through impactful gamified solutions, and Workplay
              Studios was born.
            </p>
          </div>
          <p className="pt-10 text-left font-plusJakartaSans leading-relaxed text-silverChalice md:py-16 lg:text-lg">
            Our approach goes beyond traditional marketing, blending creativity
            with data-driven strategies to ensure every interaction leaves a
            lasting impression and creates real business value. We're committed
            to making every brand experience unforgettable and truly engaging.
          </p>
          <div className="items-center justify-between pt-10 md:flex md:space-x-40 md:pt-16">
            <div className="flex flex-col items-center md:w-1/2">
              <LazyLoadImages
                src={`${process.env.REACT_APP_S3_URL}/website-assets/aboutPage/mission.webp`}
                alt="mission"
                className="mb-6 w-[35%] md:mb-10"
              />
              <h3 className="mb-2 font-epilogue text-xl font-semibold md:my-4 md:text-[21px] lg:text-3xl">
                Mission
              </h3>
              <p className="font-plusJakartaSans leading-relaxed text-silverChalice md:w-[80%] lg:text-lg">
                To become the leading gamification platform that bridges the gap
                between brands and people, fostering meaningful connections and
                lasting engagement.
              </p>
            </div>
            <div className="mt-10 flex flex-col items-center md:mt-0 md:w-1/2">
              <LazyLoadImages
                src={`${process.env.REACT_APP_S3_URL}/website-assets/aboutPage/vision.webp`}
                alt="vision"
                className="mb-6 w-[35%] md:mb-10"
              />
              <h3 className="mb-2 font-epilogue text-xl font-semibold md:my-4 md:text-[21px] lg:text-3xl">
                Vision
              </h3>
              <p className="font-plusJakartaSans leading-relaxed text-silverChalice md:w-[60%] lg:text-lg">
                Bring brands and people closer, and make interactions fun,
                through innovation and gamification
              </p>
            </div>
          </div>
        </div>
        <LandingCTA />
        <FooterSection />
      </section>
    </div>
  );
};

export default AboutUs;
