import { useEffect, useState } from "react";
import { Plus, X } from "react-feather";
import { RightArrowIcon } from "./SvgIcons";

const { REACT_APP_S3_URL } = process.env;

const sections = [
  {
    id: "no-code-game-builder",
    title: "No-Code Game Builder",
    description:
      "Transform your campaigns into fun, engaging experiences with our No-Code game builder. Level up your audience interaction and brand awareness with game-like elements that turn casual viewers into active participants.",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/what-we-offer/codebuilder.webp`,
  },
  {
    id: "in-app-gamification",
    title: "In-app Gamification",
    description:
      "Get those user retention numbers singing by integrating gaming elements within your app. Motivate, engage, and convert your users with rewards and challenges.",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/what-we-offer/inappgamification.webp`,
  },
  {
    id: "customized-games",
    title: "Customized Games",
    description:
      "You dream it, we gamify it. Meticulously crafted to align with your unique vision and goals, we build games that resonate with your audience to help you deliver a one-of-a-kind experience. Be the brand they play and talk about!",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/what-we-offer/customizedgames.webp`,
  },
  {
    id: "web-development",
    title: "Web Development",
    description:
      "Beyond the fun, we make sure your platform is sleek, fast, and game-ready. We develop modernized, scalable, and performant websites for your brand, so that you’re represented the right way, in all your glory.",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/what-we-offer/webdevelopment.webp`,
  },
];

const WhatWeOffer = () => {
  const [expandedSection, setExpandedSection] = useState<string>("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setExpandedSection("no-code-game-builder"); // Set default section open for large screens
      } else {
        setExpandedSection(""); // Close all sections for smaller screens
      }
    };

    // Set initial state and add listener for resizing
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = (sectionId: string) => {
    if (window.innerWidth >= 1024) {
      setExpandedSection((prevId) => {
        if (prevId === sectionId) {
          // If closing the current section, open the next one
          const currentIndex = sections.findIndex(
            (section) => section.id === sectionId,
          );
          const nextIndex = (currentIndex + 1) % sections.length;
          return sections[nextIndex].id;
        }
        return sectionId; // Open the clicked section
      });
    } else {
      setExpandedSection((prevId) => (prevId === sectionId ? "" : sectionId));
    }
  };

  return (
    <div className="bg-codGray px-6 py-10 sm:p-10 md:px-12 md:py-16 xl:px-20 xl:py-24">
      <h2 className="font-epilogue text-3xl font-semibold text-mercury md:text-4xl xl:text-5xl">
        What We Offer
      </h2>
      <h3 className="mt-1 font-plusJakartaSans text-xl font-medium text-white md:mt-2 md:text-3xl xl:text-4xl">
        We work. They play. We all{" "}
        <span className="win font-dynaPuff">win</span>!
      </h3>
      <p className="py-3 font-plusJakartaSans text-base text-silverChalice md:py-6 lg:text-lg xl:py-8 xl:text-[22px]">
        We bring the magic of interactive games to your marketing campaigns!
        From pick-and-play games to custom-designed challenges, all built to
        suit your brand goals and reach those milestones. Our approach taps into
        the power of gamification of marketing to drive results and give you the
        data that you need.
      </p>
      <div className="mt-6 flex w-full items-center justify-between lg:space-x-32 xl:mt-10 xl:space-x-44">
        <div className="w-full space-y-6 lg:w-[70%] xl:space-y-8">
          {sections.map(({ id, title, description }) => (
            <Accordion
              key={id}
              id={id}
              title={title}
              description={description}
              isOpen={expandedSection === id}
              onToggle={handleToggle}
              expandedSection={expandedSection}
            />
          ))}
        </div>
        <div className="hidden lg:block lg:w-[30%]">
          {sections.map(
            ({ id, imgSrc }) =>
              expandedSection === id && <img key={id} src={imgSrc} alt={id} />,
          )}
        </div>
      </div>
    </div>
  );
};

interface AccordionProps {
  id: string;
  title: string;
  description: string;
  isOpen: boolean;
  onToggle: (id: string) => void;
}

const Accordion = ({
  id,
  title,
  description,
  isOpen,
  onToggle,
  expandedSection,
}: AccordionProps & { expandedSection: string }) => {
  const handleLearnMoreClick = (e: React.MouseEvent, sectionId: string) => {
    e.stopPropagation();
    window.location.href = `/service#${sectionId}`;
  };

  return (
    <div
      className="flex cursor-pointer items-center justify-between"
      onClick={() => onToggle(id)}
    >
      <div className="w-1/2 flex-1">
        <div className="flex items-center justify-between">
          <h3 className="font-epilogue text-lg font-medium text-mercury md:text-xl xl:text-2xl">
            {title}
          </h3>
          <div className="flex items-center">
            {isOpen ? (
              <X className="h-5 text-mercury lg:h-10" />
            ) : (
              <Plus className="h-5 text-mercury lg:h-10" />
            )}
          </div>
        </div>
        {isOpen && (
          <div className="flex w-full items-center space-x-4 lg:block">
            <div className="ml-2 w-[70%] lg:ml-10 lg:w-auto">
              <p className="h-auto py-2 text-base text-silverChalice lg:h-28 lg:py-4 xl:text-lg">
                {description}
              </p>
              <p className="group ml-auto flex w-fit items-center justify-end pt-2 text-mercury underline decoration-1 underline-offset-4 hover:opacity-80 lg:pt-0 lg:text-right">
                <div className="hidden transition-transform duration-100 group-hover:inline-block">
                  <RightArrowIcon width={14} height={14} />
                </div>
                <span
                  className="whitespace-normal break-words pr-2 transition-transform duration-100 group-hover:translate-x-2 sm:text-lg"
                  onClick={(e) => handleLearnMoreClick(e, id)}
                >
                  Learn more
                </span>
              </p>
            </div>
            <div className="block w-[30%] lg:hidden">
              {sections.map(
                ({ id, imgSrc }) =>
                  expandedSection === id && (
                    <img key={id} src={imgSrc} alt={id} className="" />
                  ),
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WhatWeOffer;
