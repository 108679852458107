import React from "react";

interface TextBlockProps {
  heading: string;
  content: React.ReactNode[];
}

const TextBlock: React.FC<TextBlockProps> = ({ heading, content }) => {
  return (
    <div>
      <h2 className="mb-2 mt-6 font-semibold text-mercury lg:text-lg">
        {heading}
      </h2>

      {content.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};

export default TextBlock;
