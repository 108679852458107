import LazyLoadImages from "./LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

const Card = ({
  title,
  description,
  bgColor,
  imgSrc,
  alt,
}: {
  title: string[];
  description: string;
  bgColor: string;
  imgSrc: string;
  alt: string;
}) => (
  <div
    className="relative h-auto rounded-2xl p-4 md:h-80 md:rounded-[22px] xl:h-72 xl:px-6 xl:py-8"
    style={{ backgroundColor: bgColor }}
  >
    <h4 className="font-epilogue text-lg font-medium leading-snug text-mercury md:text-lg xl:text-2xl">
      {title.map((line, idx) => (
        <span key={idx} className="block">
          {line}
        </span>
      ))}
    </h4>
    <p className="py-3 font-plusJakartaSans text-base text-[#D4D4D4] md:text-base xl:text-lg">
      {description}
    </p>
    <LazyLoadImages
      src={imgSrc}
      alt={alt}
      className="absolute bottom-0 right-0 z-50 h-24 rounded-r-[22px] md:h-32 xl:h-auto"
    />
  </div>
);

const cardData = [
  {
    title: ["Engage like never", "before"],
    description:
      "We build games that don’t just look pretty, but help you engage and retain your customers.",
    bgColor: "#963C43",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/why-workplay/vector1.webp`,
    alt: "engagement",
  },
  {
    title: ["God-Level", "Acquisition"],
    description:
      "Watch your conversion metrics soar, with gamified solutions that are built to perform.",
    bgColor: "#9D6A3E",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/why-workplay/vector2.webp`,
    alt: "acquisition",
  },
  {
    title: ["Critical insights,", "compounded returns"],
    description:
      "We uncover crucial data metrics and create solutions that maximize them.",
    bgColor: "#5C8B41",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/why-workplay/vector3.webp`,
    alt: "insights",
  },
  {
    title: ["Multiply Brand", "Value"],
    description:
      "We make your brand the star with a fun, results-driven marketing approach!",
    bgColor: "#40509C",
    imgSrc: `${REACT_APP_S3_URL}/website-assets/landingPage/why-workplay/vector4.webp`,
    alt: "brand-value",
  },
];

const WhyWorkplay = () => (
  <div className="WhyWorkplayBg bg-codGray px-6 py-10 sm:p-10 md:px-12 md:py-16 xl:px-20 xl:py-24">
    <h2 className="font-epilogue text-3xl font-semibold text-mercury md:text-4xl xl:text-5xl">
      Why Workplay ?
    </h2>
    <h3 className="mt-1 font-plusJakartaSans text-xl font-medium text-white md:mt-2 md:text-3xl xl:text-4xl">
      Because Marketing <span className="win font-dynaPuff">Shouldn’t</span> Be
      Boring!
    </h3>
    <p className="py-3 font-plusJakartaSans text-base text-silverChalice md:py-6 lg:text-lg xl:py-8 xl:text-[22px]">
      We strongly believe that the ‘right marketing strategies’ define
      experiences that connect on a human level to make a true impression. The
      result of this belief is what we live for:
    </p>
    <div className="mt-6 grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-4 xl:mt-10 xl:gap-8">
      {cardData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          bgColor={card.bgColor}
          imgSrc={card.imgSrc}
          alt={card.alt}
        />
      ))}
    </div>
  </div>
);

export default WhyWorkplay;
