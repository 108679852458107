import axios from "axios";

interface verifyPaymentResponse {
  data: {
    paid: boolean;
    status: string;
  };
  statusCode: number;
  success: boolean;
}

export const verifyPayment = async (id: string): Promise<any> => {
  try {
    const accessToken = localStorage.getItem("xSudTzMYmd");

    if (!accessToken) {
      throw new Error("No access token found.");
    }

    const response = await axios.get<verifyPaymentResponse>(
      `${process.env.REACT_APP_API_URL}/payment/status/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Verifying Payment");
  }
};

export const getPaymentHistory = async (id: string, token: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment/payments/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching payment history:", error);
    throw error;
  }
};
