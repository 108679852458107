import FooterSection from "../components/Footer/Footer";
import LandingCTA from "../components/LandingCTA";
import Navbar from "../components/Navbar";

interface IntegrateSectionProps {
  imageSrc: string;
  title: string;
  description: string;
  reverseOrder?: boolean;
  items: string[];
}

const IntegrateSection: React.FC<IntegrateSectionProps> = ({
  imageSrc,
  title,
  description,
  items,
  reverseOrder = false,
}) => {
  return (
    <div
      className={`items-center justify-between pt-10 lg:flex lg:pb-24 ${reverseOrder ? "flex-row-reverse" : ""}`}
    >
      <div className="px-6 md:px-12 lg:w-1/2 xl:px-20">
        <h4 className="font-epilogue text-xl font-semibold md:text-2xl lg:text-4xl">
          {title}
        </h4>
        <p className="py-4 text-silverChalice lg:text-lg">{description}</p>
        <ul className="flex flex-col space-y-1 text-silverChalice lg:text-lg">
          {items.map((item, index) => {
            const [title, description] = item.split(": ");
            return (
              <li key={index} className="ml-6 list-disc">
                <span className="text-white">{title}:</span> {description}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="my-6 flex items-center justify-center px-20 lg:mb-0 lg:mt-0 lg:w-1/2 lg:px-0">
        <img src={imageSrc} alt={title} />
      </div>
    </div>
  );
};

const Integration = () => {
  const embedLinklistItems = [
    "Seamless Integration: Display external content within your site, keeping users engaged.",
    "No Redirection: Allows users to interact with the game without leaving your platform.",
    "Flexible Content: Perfect for showcasing dynamic content like games, media, or forms.",
    "Enhanced User Experience: Keeps the interaction within your native interface for a smooth experience.",
  ];

  const copyLinkListItems = [
    "One-Click Sharing: Copy the link instantly with just a click.",
    "Effortless Access: Easily paste the link anywhere, no need for manual selection.",
    "Boosts Convenience: Simplifies the process of sharing and revisiting the game.",
    "Versatile: Perfect for sharing across websites, social media, or messaging apps.",
  ];

  const qrCodeListItems = [
    "Instant Play: Users can scan the code and start playing right away.",
    "Cross-Device Compatibility: Works seamlessly on smartphones, tablets, and more.",
    "No App Required: Enjoy direct access without needing any extra downloads.",
    "Brand Customization: Personalize the QR code to align with your branding. ",
  ];

  const customDomainListItems = [
    "Complete Control: Host the game on your own domain for full customization, tailoring the experience to your specific needs.",
    "Brand Consistency: Ensure your game aligns with your brand by using a personalized URL, strengthening your identity.",
    "Improved Trust: Boost user confidence and engagement with content that’s securely hosted on your domain.",
    "Seamless Experience: Deliver a smooth, uninterrupted experience by keeping users within your site, without redirects to external platforms.",
  ];

  return (
    <section className="relative bg-codGray font-plusJakartaSans text-white">
      <Navbar />
      <div className="relative flex h-[30vh] items-center px-6 py-10 sm:py-10 md:px-12 md:py-16 xl:px-20 xl:py-24">
        <div className="z-50 md:mt-36">
          <h1 className="w-[75%] font-epilogue text-3xl font-bold text-white sm:w-[60%] md:text-4xl lg:text-[52px] xl:w-[100%] xl:leading-normal">
            Your Platform, Your Games- <br />
            <span className="win font-dynaPuff">Integrated in Seconds</span>!
          </h1>
        </div>
        <div className="absolute right-0 top-4">
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/integrationPage/hero.webp`}
            alt="integration"
            className="h-[25vh] rounded-r-[22px] md:h-[40vh] xl:h-[50vh]"
          />
        </div>
      </div>

      <div
        className="pointer-events-none absolute left-0 right-0 top-20 z-10 block h-[30vh] bg-gradient-to-r from-[#0e0e0e] to-[#0e0e0e] md:h-[60vh] lg:top-12"
        style={{
          background:
            "linear-gradient(to right, rgba(14, 14, 14, 1) 40%, rgba(14, 14, 14, 0) 100%)",
        }}
      ></div>

      <div className="relative z-20 md:pt-20 xl:pt-40">
        <div>
          <p className="mb-12 px-6 leading-relaxed text-silverChalice md:my-12 md:px-12 lg:text-lg xl:px-20">
            Integrating interactive games into your website or platform is quick
            and easy. Our flexible options let you choose the method that best
            suits your needs, whether for simplicity, customization, or full
            control. Get your games up and running in no time, ensuring an
            engaging experience for your users.
          </p>
          <h3 className="my-2 px-6 text-center font-epilogue text-2xl font-bold md:my-14 md:px-12 md:text-3xl lg:text-4xl xl:px-20">
            See How you can integrate your games
          </h3>
          <IntegrateSection
            imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/integrationPage/embedLink.webp`}
            title="Embed Code"
            description="Embedding allows you to seamlessly integrate external games into your webpage or application, offering an interactive experience without users needing to leave your platform. It’s well-suited for businesses or individuals aiming to deliver engaging content directly on their site, enhancing the overall user experience."
            items={embedLinklistItems}
            reverseOrder={false}
          />

          <IntegrateSection
            imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/integrationPage/copyLink.webp`}
            title="Copy Link"
            description={`With the "Copy Link" feature, users can effortlessly copy the game URL to their clipboard with a single click, making it easy to share or revisit the game without any hassle. This is ideal for those looking to easily share links with a wide audience across multiple channels.`}
            items={copyLinkListItems}
            reverseOrder={true}
          />

          <IntegrateSection
            imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/integrationPage/qrCode.webp`}
            title="QR Code"
            description="Download a scannable QR code that lets users instantly access and play your game. It’s a fast and convenient way to bring your game to a wider audience with just a scan, making it ideal for businesses, events, and marketing campaigns looking to engage users on the go."
            items={qrCodeListItems}
            reverseOrder={false}
          />

          <IntegrateSection
            imageSrc={`${process.env.REACT_APP_S3_URL}/website-assets/integrationPage/customDomain.webp`}
            title="Custom Domain"
            description={`Host your game on your own custom domain for a fully branded experience, giving you complete control over the user journey. This integration is ideal for businesses looking to enhance brand presence and build trust with their audience.`}
            items={customDomainListItems}
            reverseOrder={true}
          />
        </div>
        <LandingCTA />
        <FooterSection />
      </div>
    </section>
  );
};

export default Integration;
