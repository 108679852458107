import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LazyLoadImages from "../LazyLoadImages";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Eye, EyeOff } from "react-feather";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

interface ResetPasswordProps {}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const URL = `${REACT_APP_API_URL}/auth/reset-password`;
  const code = searchParams.get("code");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);

  const handlePasswordFocus = () => setIsPasswordFocused(true);
  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const handleConfirmPasswordFocus = () => setIsConfirmPasswordFocused(true);
  const handleConfirmPasswordBlur = () => {
    setIsConfirmPasswordFocused(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (password: string) => {
    // Set form data
    setFormData({
      ...formData,
      password: password,
    });
  };

  const handleConfirmPasswordChange = (confirmPassword: string) => {
    setFormData({
      ...formData,
      confirmPassword: confirmPassword,
    });
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    // Validation regular expressions
    const digitRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (
      formData.password !== formData.confirmPassword &&
      formData.password &&
      formData.confirmPassword
    ) {
      toast.error("Passwords do not match.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!formData.password && !formData.confirmPassword) {
      toast.error("Please fill all the fields.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!formData.password) {
      toast.error("Please enter your new password, then confirm it.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!formData.confirmPassword) {
      toast.error("Confirm Password.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters long.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!digitRegex.test(formData.password)) {
      toast.error("Password must contain at least one digit.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!uppercaseRegex.test(formData.password)) {
      toast.error("Password must contain at least one uppercase letter.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!specialCharRegex.test(formData.password)) {
      toast.error("Password must contain at least one special character.", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else {
      try {
        if (!URL) {
          return;
        }
        const response = await axios.patch(
          `${URL}/${code}`,
          {
            password: formData.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        if (response.data.success === true) {
          setFormData({
            password: "",
            confirmPassword: "",
          });
          toast.success("Password reset successful!", {
            autoClose: 3000,
            className: "toastify-toast",
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
        console.error("Error creating user:", error);
        setFormData({
          password: "",
          confirmPassword: "",
        });
      }
    }
  };

  const handleResetPassWord = async () => {
    if (!URL) {
      return;
    }
    try {
      const Response = await axios.get(`${URL}/${code}`);
      if (Response.data.success === true) {
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    handleResetPassWord();
  }, []);

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="input-wrapper relative min-h-screen w-full items-center overflow-x-hidden bg-codGray py-6 md:flex md:py-0">
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/top-Ellipse.webp`}
          alt="Ellipse"
          className="absolute top-0 z-0"
        />
        <div className="z-100 w-full items-center justify-center px-6 md:flex md:w-[55%] xl:w-[40%]">
          <div className="relative">
            <img
              className="mb-12 h-6 cursor-pointer xl:h-7"
              src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
              alt="workplay-logo"
              onClick={handleLogoRedirect}
            />
            <div className="flex items-center justify-center">
              <img
                className="w-full px-14 pb-10 sm:px-20 md:hidden"
                src={`${REACT_APP_S3_URL}/website-assets/authPages/reset.webp`}
                alt="reset-pass"
              />
            </div>
            <div className="relative">
              <h2 className="text-left font-epilogue text-3xl font-bold text-mercury sm:pr-16 lg:text-4xl">
                Create new password
              </h2>
              <LazyLoadImages
                src={`${REACT_APP_S3_URL}/website-assets/authPages/sparkle.webp`}
                alt="sparkle"
                className="absolute right-[0%] top-[-140%] h-8 lg:right-[-6%] lg:h-auto"
              />
            </div>
            <p className="m-auto mb-6 mt-3 text-left font-epilogue text-[17px] font-medium text-silverChalice lg:mb-10">
              Please enter your new password below.
            </p>{" "}
            <div className="flex w-full flex-col space-y-6">
              <div className="relative z-100 w-full">
                <label
                  htmlFor="password"
                  className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                    isPasswordFocused
                      ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                      : formData.password
                        ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  New Password{" "}
                  <span
                    className={`${isPasswordFocused ? "text-tanHide" : "text-red-400"}`}
                  >
                    *
                  </span>
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => {
                    handlePasswordChange(e.target.value);
                  }}
                  onFocus={handlePasswordFocus}
                  onBlur={handlePasswordBlur}
                  placeholder=""
                  required
                  className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                />
                {showPassword ? (
                  <EyeOff
                    className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <Eye
                    className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              <div className="relative z-100 w-full">
                {" "}
                <label
                  htmlFor="confirmPassword"
                  className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                    isConfirmPasswordFocused
                      ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                      : formData.confirmPassword
                        ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  Password{" "}
                  <span
                    className={`${isConfirmPasswordFocused ? "text-tanHide" : "text-red-400"}`}
                  >
                    *
                  </span>
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) => {
                    handleConfirmPasswordChange(e.target.value);
                  }}
                  onFocus={handleConfirmPasswordFocus}
                  onBlur={handleConfirmPasswordBlur}
                  placeholder=""
                  required
                  className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                />
                {showConfirmPassword ? (
                  <EyeOff
                    className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <Eye
                    className="absolute right-4 top-1/2 h-[18px] -translate-y-1/2 transform cursor-pointer text-mercury"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="headerBtn relative z-100 mt-6 w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-xl"
            >
              Reset password
            </button>
            <p className="z-100 mb-16 mt-5 hidden font-plusJakartaSans font-medium text-mercury sm:mb-0 sm:block">
              Did you remember your password?{" "}
              <Link
                to="/login"
                className="underline-link relative ml-1 scale-105 hover:opacity-80"
              >
                <strong className="win relative font-epilogue font-semibold">
                  Try logging in
                </strong>
              </Link>
            </p>
            <p className="z-100 block pb-1 pt-4 text-left font-plusJakartaSans font-medium text-mercury sm:hidden">
              Did you remember your password?{" "}
            </p>
            <Link
              to="/login"
              className="underline-link relative ml-1 block w-fit scale-105 hover:opacity-80 sm:hidden"
            >
              <strong className="win relative font-epilogue font-semibold">
                Try logging in
              </strong>
            </Link>
          </div>
        </div>{" "}
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/pattern.webp`}
          alt="pattern"
          className="absolute bottom-0 right-0 -z-0"
        />
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/greenEllipse.webp`}
          alt="Ellipse"
          className="absolute bottom-0 right-0 -z-0 h-full"
        />
        <div className="z-0 hidden md:block md:w-[45%] xl:w-[60%]">
          <div className="flex items-center justify-center">
            <LazyLoadImages
              className="w-[80%] px-6 md:px-10 lg:px-20"
              src={`${REACT_APP_S3_URL}/website-assets/authPages/reset.webp`}
              alt="reset-pass"
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
