import axios from "axios";

interface StatusRequestBody {
  status: boolean;
}

export const projectStatus = async (
  id: string,
  token: string,
  body: StatusRequestBody,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/campaign/status/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching project status:", error);
    throw error;
  }
};
