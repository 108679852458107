import { useEffect, useState } from "react";
import { PlanDetails } from "../../types/planTypes";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { FadeLoader } from "react-spinners";
import PromoCode from "./PromoCode";
import axios from "axios";
import { usePricing } from "../../hooks/usePricing";
import LazyLoadImages from "../../components/LazyLoadImages";
import { X } from "react-feather";
import "../../styles/Checkout.css";
import useUnloadPrompt from "../../hooks/useUnloadPrompt";
import { toast } from "react-toastify";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedPlanId = searchParams.get("sid");
  const ccid = searchParams.get("ccid");
  const token = localStorage.getItem("xSudTzMYmd");

  const pricingDetails = JSON.parse(
    localStorage.getItem("pricingdetail") || "[]",
  );
  const country = pricingDetails[0]?.country || "US";

  const allPlans = useSelector((state: RootState) => state.plan.allPlans);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(
    selectedPlanId,
  );

  const [selectedPlanDetails, setSelectedPlanDetails] =
    useState<PlanDetails | null>(null);
  const [campaignDetails, setCampaignDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [gameId, setGameId] = useState("");
  const embedQuiz = `${process.env.REACT_APP_CUSTOM_QUIZ_URL}/play?campaignId=${gameId}`;
  const embedSpin = `${process.env.REACT_APP_CUSTOM_SPIN_URL}/play?gameId=${gameId}`;

  const handleLogoRedirect = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!token || !ccid || !selectedPlanId) {
      window.location.href = "/"; // Redirect if token or ccid or sid is missing
      return;
    }

    const fetchCampaignDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/campaign/${ccid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );

        setCampaignDetails(response.data.data);
        setGameId(response.data.data.gameId);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignDetails();
  }, [token, ccid]);

  useEffect(() => {
    if (selectedPlanId && allPlans.length > 0) {
      const selectedPlan = allPlans.find((plan) => plan._id === selectedPlanId);
      if (selectedPlan) {
        setSelectedPlanDetails(selectedPlan);
      }
    }
  }, [selectedPlanId, allPlans]);

  const pricing = usePricing(selectedPlanDetails, country);

  const handleCheckout = async () => {
    if (!selectedPlanDetails || !campaignDetails) return;

    const payload = {
      subscriptionId: selectedPlanDetails._id,
      campaignId: ccid,
      country: country,
    };

    try {
      // Make a POST request to create the Razorpay order
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/payment/checkout`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      if (window.Razorpay) {
        // Extract order details from the response (e.g., order_id)
        const orderData = response.data.data;
        const options = {
          key: process.env.RAZORPAY_ID_KEY,
          amount: orderData.amount,
          currency: orderData.currency,
          order_id: orderData.orderId,
          name: "Workplay Studio Private Ltd",
          description: "Payment for the selected plan",
          image: "https://cdn.workplay.digital/assets/workplay-logo.webp",
          handler: (response: any) => {
            // Handle successful payment response here
            // alert("Payment successful: " + response.razorpay_payment_id);
            navigate(`/confirm-payment?ccid=${ccid}`, {
              state: {
                id: orderData.orderId,
              },
            });
            // You can also update the payment status in the backend
          },
          prefill: {
            name: orderData.notes.notes.fullName,
            email: orderData.notes.notes.email,
            contact: orderData.notes.notes?.phoneNumber,
          },
          notes: {
            address: "Some address",
          },
        };

        // Create the Razorpay payment gateway instance and open the modal
        const razorpay = new window.Razorpay(options);
        razorpay.open(); // Open the Razorpay payment modal
      } else {
        console.error("Razorpay SDK not loaded properly");
      }
    } catch (error) {
      console.error("Error during payment initiation:", error);
      toast.error(
        "Cannot subscribe to plan at the moment. Please try again later.",
      );
    }
  };

  const handlePlanSelection = (planId: string) => {
    // Set the selected plan ID in the state
    setSelectedPlan(planId);

    // Update the URL with the new selected plan ID (sid)
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("sid", planId); // Set the new plan ID to the sid parameter

    // Update the browser's URL without reloading the page
    navigate(`?${updatedSearchParams.toString()}`, { replace: true });
  };

  useUnloadPrompt();

  if (loading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <FadeLoader color={"#444444"} />
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full lg:flex">
      <div className="relative mx-auto flex flex-col justify-center bg-black px-6 pb-4 pt-10 text-white lg:w-[50%] lg:p-10 xl:w-[60%]">
        <div className="checkoutBackground absolute inset-0 z-0 h-screen"></div>
        <LazyLoadImages
          className="absolute top-6 h-5 w-fit cursor-pointer lg:top-[44px] lg:h-7"
          src={`${process.env.REACT_APP_S3_URL}/assets/wp-logo.webp`}
          onClick={handleLogoRedirect}
          alt="workplay-logo"
        />
        <h2 className="mt-10 font-epilogue text-2xl font-semibold text-white md:text-3xl xl:text-4xl">
          Checkout
        </h2>
        <p className="mb-8 mt-2 text-lg font-medium text-mercury md:mb-10">
          You're just one step away from completing your order!
        </p>

        {allPlans && allPlans.length > 0 ? (
          allPlans.map((plan) => (
            <div
              key={plan._id}
              className={`relative z-50 mb-6 flex cursor-pointer items-start justify-between rounded-md border-4 bg-mercury p-5 shadow-lg ${
                selectedPlan === plan._id
                  ? " border-[#bbbbbb]"
                  : " border-transparent"
              }`}
              onClick={() => handlePlanSelection(plan._id)}
            >
              <div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id={plan._id}
                    name="plan"
                    value={plan._id}
                    checked={selectedPlan === plan._id}
                    onChange={() => handlePlanSelection(plan._id)}
                    className="custom-radio mr-3"
                  />
                  <h4 className="text-xl font-semibold text-black lg:text-2xl">
                    {plan.title}
                  </h4>
                </div>
                <p className="mt-2 text-[15px] font-medium text-[#363636] lg:text-base">
                  {plan.description}
                </p>
              </div>

              {/* Pricing */}
              <div className="text-right">
                <p className="flex items-center space-x-1 text-lg font-bold text-black lg:text-xl">
                  {(country === "IN" || country !== "IN") &&
                  plan.pricing[country === "IN" ? "IN" : "US"] ? (
                    <p>
                      {country === "IN"
                        ? `₹${(plan.pricing.IN - (plan.pricing.IN * plan.pricing.discount) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : `$${(plan.pricing.US - (plan.pricing.US * plan.pricing.discount) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </p>
                  ) : null}{" "}
                  /
                  <span className="font-semibold text-[#313131] lg:text-lg">
                    {plan.pricing.validForInDays} days
                  </span>
                </p>

                {(country === "IN" || country !== "IN") &&
                plan.pricing[country === "IN" ? "IN" : "US"] ? (
                  <p
                    className={`pt-2 font-bold text-[#5c5c5c] line-through lg:text-lg ${
                      plan.pricing.discount === 0 ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    {country === "IN"
                      ? `₹${plan.pricing.IN.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      : `$${plan.pricing.US.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                  </p>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <p className="text-white">No plans available</p> // If no plans are available
        )}

        <div className="absolute bottom-4 hidden items-center space-x-4 text-[15px] text-silverChalice lg:bottom-20 lg:flex lg:space-x-10 xl:bottom-8">
          <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
            <p className="cursor-pointer hover:underline hover:underline-offset-2 hover:opacity-80">
              Terms of use
            </p>
          </a>
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            <p className="cursor-pointer hover:underline hover:underline-offset-2 hover:opacity-80">
              Privacy Policy
            </p>
          </a>
          <a href="/contact" target="_blank" rel="noopener noreferrer">
            <p className="cursor-pointer hover:underline hover:underline-offset-2 hover:opacity-80">
              Contact
            </p>
          </a>
        </div>
      </div>

      <div className="mx-auto flex flex-col bg-white p-6 lg:w-[50%] lg:p-10 xl:w-[40%]">
        <div className="w-full">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-bold lg:text-2xl">Order Summary</h3>
            <X
              size={28}
              className="relative z-50 hidden cursor-pointer text-red-600 hover:scale-110 lg:block"
              onClick={() => navigate("/dashboard/campaigns")}
            />
            <p
              className="relative z-50 cursor-pointer text-[15px] font-medium text-red-800 underline underline-offset-1 lg:hidden"
              onClick={() => navigate("/dashboard/campaigns")}
            >
              Cancel
            </p>
          </div>
          <div className="flex items-start justify-between ">
            <div className="w-full">
              <div className="mt-8 flex w-full items-center justify-between font-medium">
                <div>
                  <p className="text-lg font-bold">{campaignDetails?.name}</p>
                  <p className="text-base text-stone-600">
                    {campaignDetails?.game === "spin-the-wheel"
                      ? "Spin the Wheel"
                      : campaignDetails?.game === "quiz"
                        ? "Quiz"
                        : campaignDetails?.game || ""}
                  </p>
                </div>{" "}
                {/* <div className="iframe-container">
                  <iframe
                    src={
                      campaignDetails?.game === "quiz"
                        ? embedQuiz
                        : campaignDetails?.game === "spin-the-wheel"
                          ? embedSpin
                          : ""
                    }
                    title="preview"
                    className="gameFrame"
                  ></iframe>
                </div> */}
              </div>
            </div>
          </div>
          <div className="my-5">
            <div className="flex items-center space-x-2">
              <p className="text-lg font-bold">{selectedPlanDetails?.title}</p>
              {selectedPlanDetails?.pricing.discount !== 0 && (
                <div className="py-.5 bg-green-600 px-2 text-[15px] font-bold uppercase text-white">
                  <span className="mr-1">Save</span> {pricing?.discountAmount}
                </div>
              )}
            </div>

            <div className="flex w-full items-center justify-between pt-1 font-medium">
              <div className="items-center lg:flex lg:space-x-2">
                {selectedPlanDetails?.pricing.discount !== 0 && (
                  <p className="text-sm font-medium line-through">
                    {pricing?.originalPrice}
                  </p>
                )}
                <p className="text-base">
                  {pricing?.amountAfterDiscount} / {pricing?.validForInDays}{" "}
                  days
                </p>
              </div>
              <p className="text-base font-medium text-black">
                {pricing?.amountAfterDiscount}
              </p>
            </div>
          </div>

          <PromoCode />

          <div className="border-y-[1px] border-dashed border-stone-400">
            <div className="my-4 flex w-full items-center justify-between font-medium">
              <p className="text-base">Subtotal</p>
              <p className="text-base font-medium">
                {pricing?.amountAfterDiscount}
              </p>
            </div>{" "}
            {country === "IN" ? (
              <div className="my-4 flex w-full items-center justify-between font-medium">
                <p className="text-base">GST (18%)</p>
                <p className="text-base font-medium">{pricing?.gst}</p>
              </div>
            ) : null}
          </div>
          <div className="my-5 flex w-full items-center justify-between">
            <p className="text-lg font-semibold">Total</p>
            <p className="text-lg font-bold">{pricing?.total}</p>
          </div>
          <button
            className="h-12 w-full rounded-md bg-black text-center text-white"
            onClick={handleCheckout}
          >
            Submit Purchase
          </button>
          <p className="my-5 flex items-center justify-center font-semibold">
            <FaLock className="mr-2" /> Safe & Secure Payment
          </p>
          <p className="text-[12px]">
            By purchasing, you agree to the Workplay Studio Private Ltd{" "}
            <a href="/terms-of-use" className="font-semibold">
              Terms of Service
            </a>{" "}
            and acknowledge reading the{" "}
            <a href="/privacy-policy" className="font-semibold">
              privacy policy
            </a>
            . You also agree to auto renewal of your monthly subscription for ₹
            {pricing?.total} (tax included), which can be disabled at any time
            through your account.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
