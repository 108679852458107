import React from "react";
import "../styles/Sections/Home.css";
import Navbar from "./Navbar";
import { SparklesIcon } from "./SvgIcons";

const { REACT_APP_S3_URL, REACT_APP_WORKPLAY_URL } = process.env;

const Home: React.FC = () => {
  const handleGamePageRedirect = () => {
    window.location.href = `${REACT_APP_WORKPLAY_URL}/game`;
  };

  return (
    <section className="headerBackground bg-codGray overflow-x-hidden">
      <Navbar />
      <div className="relative px-8 pb-10 pt-24 text-center md:py-16 lg:pb-12 lg:pt-32">
        <img
          src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/pattern.webp`}
          alt="pattern"
          className="absolute left-[-4%] top-[18%] h-[6%] translate-y-[210%] lg:h-20 xl:left-[-2%] xl:top-[2%] xl:h-auto"
        />
        <div className="relative inline-block">
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/spinWheel.webp`}
            alt="spinWheel"
            className="absolute left-[18%] top-[28%] z-50 h-[35%] -translate-y-[220%] xl:left-[14%] xl:top-[16%] xl:h-auto"
          />
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/spinWheelShadow.webp`}
            alt="spinWheelShadow"
            className="absolute left-[14%] top-[36%] h-[35%] -translate-y-[220%] xl:left-[12%] xl:top-[22%] xl:h-auto"
          />
          <h1 className="font-epilogue text-mercury -mt-8 text-center text-4xl font-bold leading-tight lg:text-[52px] xl:text-6xl xl:leading-normal">
            Play to <span className="win font-dynaPuff">win</span> by Gamifying
            your <br /> Digital Marketing
          </h1>{" "}
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/logoColors.webp`}
            alt="logoColors"
            className="absolute bottom-[80%] right-[-2%] h-[30%] -translate-y-1/2 md:h-[50%] xl:bottom-[65%] xl:right-[-9%] xl:h-auto"
          />
        </div>
        <div className="relative">
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/dice.webp`}
            alt="dice"
            className="absolute top-[150%] z-50 h-[25%] -translate-y-[220%] lg:left-[17%] lg:top-[215%] lg:h-auto"
          />
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/diceShadow.webp`}
            alt="diceShadow"
            className="absolute top-[160%] h-[25%] -translate-y-[220%] lg:left-[17%] lg:top-[239%] lg:h-auto"
          />
          <h3 className="font-plusJakartaSans text-silverChalice py-6 text-center font-medium md:py-4 md:text-lg xl:text-xl">
            We help brands boost audience engagement with fun, gamified
            marketing <br />
            strategies. Our no-code game builder empowers you to create
            unforgettable <br /> campaigns that keep customers coming back. All
            work, all play!
          </h3>
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/puzzlePiece.webp`}
            alt="puzzlePiece"
            className="absolute right-[-6%] top-[134%] z-50 h-[25%] -translate-y-[220%] md:h-[50%] xl:right-[14%] xl:top-[120%] xl:h-auto"
          />
          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/puzzlePieceShadow.webp`}
            alt="puzzlePieceShadow"
            className="absolute right-[-10%] top-[136%] h-[25%] -translate-y-[220%] md:h-[50%] xl:right-[12%] xl:top-[120%] xl:h-auto"
          />
        </div>
        <div className="relative inline-block pb-0 pt-10 md:py-4">
          <button className="rounded-full ring-2 ring-transparent hover:ring-[6px] hover:ring-gray-500 hover:ring-opacity-70 xl:text-2xl">
            <button
              className="createbtn relative flex cursor-pointer items-center justify-center overflow-hidden rounded-[100px] border-none p-[2px] "
              onClick={handleGamePageRedirect}
            >
              <span className="createbtnSpan bg-tanHide font-encodeSansExpanded hover:bg-btn-gradient relative z-[1] flex w-full items-center rounded-[100px] p-4 px-8 py-3 text-xl font-semibold text-white backdrop-blur-[40px] xl:text-2xl">
                <SparklesIcon /> <p className="ml-2">Create Now</p>
              </span>
            </button>
          </button>

          <img
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/header/playArrow.webp`}
            alt="playArrow"
            className="absolute left-[110%] top-[50%] h-[50%] -translate-y-1/2 md:h-[70%] xl:left-[120%] xl:top-[50%] xl:h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
