import { useState, useEffect } from "react";
import { getTrialEligibility } from "../services/trialApi";

interface TrialEligibilityResponse {
  data: {
    trialAvailable: boolean;
  };
  statusCode: number;
  success: boolean;
}

export const useTrialEligibility = () => {
  const [trialAvailable, setTrialAvailable] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrialEligibility = async () => {
      setLoading(true);
      try {
        const response: TrialEligibilityResponse = await getTrialEligibility();
        if (response.success) {
          setTrialAvailable(response.data.trialAvailable);
        } else {
          setError("Failed to fetch trial eligibility.");
        }
      } catch (err) {
        setError("Error fetching trial eligibility");
      } finally {
        setLoading(false);
      }
    };

    fetchTrialEligibility();
  }, [trialAvailable]);

  return { trialAvailable, loading, error };
};
