import LandingCTA from "../../components/LandingCTA";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Quick Interactive Fun", id: "quickInteractiveFun" },
  { label: "Skill Development", id: "skillDevelopment" },
  { label: "Team Building", id: "teamBuilding" },
  { label: "Seasonal Campaigns", id: "seasonalCampaigns" },
];

const useCaseDetails = [
  {
    id: "quickInteractiveFun",
    title: "Quick Interactive Fun",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/interactiveFun.webp`,
    content: [
      "Provide a fast-paced, easy-to-learn experience that players can enjoy during short breaks. Its simplicity and instant gratification make it a hit for all audiences.",
    ],
  },
  {
    id: "skillDevelopment",
    title: "Skill Development",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/skillDevelopment.webp`,
    content: [
      "Encourage strategic thinking and decision-making with a fun and engaging challenge. Tic-Tac-Toe offers an opportunity for cognitive growth while keeping the gameplay light and enjoyable.",
    ],
  },
  {
    id: "teamBuilding",
    title: "Team Building",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/teamBuilding.webp`,
    content: [
      "Incorporate Tic-Tac-Toe into team-building sessions where players can collaborate or compete in pairs or groups. It’s a great way to foster teamwork and friendly competition.",
    ],
  },
  {
    id: "seasonalCampaigns",
    title: "Seasonal Campaigns",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/seasonalCampaigns.webp`,
    content: [
      "Align the game with seasonal events, holidays, or special promotions by customizing its visuals and themes. Create an immersive experience that resonates with your audience.",
    ],
  },
];

const strategyItems = [
  {
    title: "Timeless and Engaging",
    description:
      "Tic-Tac-Toe’s simplicity and universal appeal make it an excellent choice for engaging a diverse audience. Its short rounds and strategic elements keep players entertained and coming back for more.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/timelessAndEngaging.webp`,
    imgAlt: "timeless-and-Engaging",
    reverse: true,
  },
  {
    title: "Customizable and Versatile",
    description:
      "With options to adjust themes, rules, and gameplay mechanics, this game adapts seamlessly to your campaign goals. From branding opportunities to seasonal twists, Tic-Tac-Toe is endlessly versatile.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/customizableVersatile.webp`,
    imgAlt: "Customizable-and-Versatile",
  },
  {
    title: "Building Connections",
    description:
      "More than just a game, Tic-Tac-Toe fosters interaction and connection. Whether used in events, promotions, or team-building activities, it delivers memorable experiences that strengthen your brand's relationship with its audience.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/buildingConnections.webp`,
    imgAlt: "Building-Connections",
    reverse: true,
  },
];

const handlePlay = () => {
  window.open("https://tic-tac-toe.workplay.digital/", "_target");
};

const TicTacToe = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Tic-Tac-
              <br />
              Toe
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Tic-Tac-Toe
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Step up the challenge with our interactive Tic-Tac-Toe game!
              Combining timeless gameplay with modern twists, this game is
              designed to entertain and engage players of all ages in just a few
              strategic moves.
            </p>
            <button
              className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              onClick={handlePlay}
            >
              Play Now
            </button>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/ticTacToeGroup.webp`}
            alt="tic-tac-toe"
            className="absolute right-[-6%] top-[-20%] z-0 hidden w-[80%] sm:bottom-[-30%] sm:block md:top-[-25%] lg:top-[-45%] lg:w-[75%] xl:top-[-85%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/ticTacToe/ticTacToe.webp`}
            alt="tic-tac-toe"
            className="absolute right-0 top-[-2%] h-[14vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <UseCase
        title="How You Can Use Tic-Tac-Toe"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <Strategy
        title="How Tic-Tac-Toe Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default TicTacToe;
