import FooterSection from "../components/Footer/Footer";
import Navbar from "../components/Navbar";

interface SitemapLink {
  label: string;
  href: string;
}

interface SitemapLinkListProps {
  title?: string;
  links: SitemapLink[];
}

const SitemapLinkList: React.FC<SitemapLinkListProps> = ({ title, links }) => {
  return (
    <div>
      <p className="my-2 font-medium lg:text-lg">{title}</p>
      <ul className="my-2 flex flex-col space-y-3 text-silverChalice lg:text-lg">
        {links.map((link: SitemapLink, index: number) => (
          <li
            key={index}
            className="w-fit cursor-pointer hover:underline hover:underline-offset-2 hover:opacity-80"
          >
            <a href={link.href}>{link.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Sitemap = () => {
  const companyLinks = [
    { label: "About Our Story", href: "/about-us" },
    { label: "Contact Us", href: "/contact" },
    { label: "Read Privacy Policy", href: "/privacy-policy" },
    { label: "Read Terms of Use", href: "/terms-of-use" },
  ];

  const gamesLinks = [
    { label: "Spin the Wheel", href: "/game/spin-the-wheel" },
    { label: "Quiz", href: "/game/quiz" },
    { label: "Memory Card", href: "/game/memory-card" },
    { label: "Find the Object", href: "/game/find-the-object" },
    { label: "Jigsaw Puzzle", href: "/game/jigsaw-puzzle" },
    { label: "Slot Machine", href: "/game/slot-machine" },
    { label: "Tic-tac-toe", href: "/game/tic-tac-toe" },
    { label: "Spot the Difference", href: "/game/spot-the-difference" },
    { label: "Roll the Dice", href: "/game/roll-the-dice" },
  ];

  const servicesLinks = [
    { label: "No Code Game Builder", href: "/service#no-code-game-builder" },
    { label: "In-app Gamification", href: "/service#in-app-gamification" },
    { label: "Customized Games", href: "/service#customized-games" },
    { label: "Web Development", href: "/service#web-development" },
    { label: "Learn About Integration", href: "/integration" },
  ];

  const supportLinks = [
    { label: "View Pricing Plans", href: "/pricing" },
    { label: "Frequently Asked Questions", href: "/faq" },
  ];
  return (
    <div className="relative bg-codGray font-plusJakartaSans text-mercury">
      <div className="headerBackground absolute inset-0 z-0 h-screen"></div>
      <section className="relative z-10">
        <Navbar />
        <div className="mx-6 mb-20 mt-14 sm:mx-10 md:mx-12 md:mt-16 xl:mx-20 xl:mb-32">
          <div className="relative text-center md:my-5">
            <h1 className="font-epilogue text-3xl font-bold leading-snug md:text-4xl xl:text-5xl">
              Sitemap
            </h1>
          </div>
          <div className="my-14 grid gap-y-10 sm:grid-cols-2 md:my-20 md:gap-y-14 xl:grid-cols-4">
            <div className="flex flex-col md:space-y-3">
              <h2 className="mb-4 mt-2 font-epilogue text-xl font-semibold lg:text-2xl">
                Company Information
              </h2>
              <SitemapLinkList
                title="About Us"
                links={companyLinks.slice(0, 1)}
              />
              <SitemapLinkList
                title="Contact"
                links={companyLinks.slice(1, 2)}
              />
              <SitemapLinkList
                title="Privacy Policy"
                links={companyLinks.slice(2, 3)}
              />
              <SitemapLinkList
                title="Terms of Use"
                links={companyLinks.slice(3, 4)}
              />
            </div>

            <div className="flex flex-col md:space-y-1">
              <h2 className="mb-4 mt-2 font-epilogue text-xl font-semibold lg:text-2xl">
                Games
              </h2>
              <SitemapLinkList links={gamesLinks} />
            </div>

            <div className="flex flex-col md:space-y-3">
              <h2 className="my-2 font-epilogue text-xl font-semibold lg:text-2xl">
                Services & Integration
              </h2>
              <SitemapLinkList
                title="Services"
                links={servicesLinks.slice(0, 4)}
              />
              <SitemapLinkList
                title="Integration"
                links={servicesLinks.slice(4)}
              />
            </div>

            <div className="flex flex-col md:space-y-3">
              <h2 className="my-2 font-epilogue text-xl font-semibold lg:text-2xl">
                Support & Pricing
              </h2>
              <SitemapLinkList
                title="Pricing"
                links={supportLinks.slice(0, 1)}
              />
              <SitemapLinkList title="FAQ" links={supportLinks.slice(1)} />
            </div>
          </div>
        </div>
        <FooterSection />
      </section>
    </div>
  );
};

export default Sitemap;
