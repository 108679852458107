import { useEffect, useRef, useState } from "react";
import { TbMessageQuestion } from "react-icons/tb";
import { LuUserCog } from "react-icons/lu";
import { BsQuestionLg } from "react-icons/bs";

const Help = () => {
  const [dropdownOpenmain, setDropdownOpenmain] = useState(false);
  const dropdownRef1 = useRef<HTMLDivElement>(null);
  const questionButtonRef1 = useRef<HTMLButtonElement>(null);

  const toggleDropdownmain = () => {
    setDropdownOpenmain((prev) => !prev);
  };

  const handleContactSupport = () => {
    setDropdownOpenmain(false);
    setTimeout(() => {
      window.open(`${process.env.REACT_APP_WORKPLAY_URL}/contact`, "_blank");
    }, 100);
  };

  const handleFAQs = () => {
    setDropdownOpenmain(false);
    setTimeout(() => {
      window.open(`${process.env.REACT_APP_WORKPLAY_URL}/faq`, "_blank");
    }, 100);
  };

  const handleClickOutside1 = (event: MouseEvent) => {
    if (
      dropdownRef1.current &&
      !dropdownRef1.current.contains(event.target as Node) &&
      questionButtonRef1.current &&
      !questionButtonRef1.current.contains(event.target as Node)
    ) {
      setDropdownOpenmain(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);
  return (
    <div className="fixed bottom-6 right-6 flex flex-col items-center justify-center rounded-full">
      <button
        ref={questionButtonRef1}
        className={`flex h-10 w-10 flex-col items-center justify-center rounded-full bg-[#4C4C4C] hover:scale-[102%] ${
          dropdownOpenmain
            ? "animate-pulse bg-[#292929] ring-4 ring-gray-400 ring-opacity-50"
            : ""
        }`}
        onClick={toggleDropdownmain}
        data-tooltip-id="help"
        data-tooltip-content="Help"
      >
        <BsQuestionLg size={20} className="text-white" />
      </button>
      {dropdownOpenmain && (
        <div
          style={{ position: "absolute", zIndex: 99999 }}
          ref={dropdownRef1}
          className="absolute bottom-14 right-0 z-40 w-48 rounded-xl bg-[#292929] text-white shadow"
        >
          <div className="z-80 absolute right-3 top-[calc(100%-1px)] h-0 w-0 border-x-8 border-t-8 border-x-transparent border-t-[#292929] drop-shadow"></div>
          <div className="p-2">
            <ul onClick={handleFAQs}>
              <li className="flex cursor-pointer items-center space-x-2 rounded-lg p-2 hover:bg-[#292929]">
                <TbMessageQuestion size={20} />
                <span>FAQs</span>
              </li>
            </ul>
            <ul onClick={handleContactSupport}>
              <li className="flex cursor-pointer items-center space-x-2 rounded-lg p-2 hover:bg-[#292929]">
                <LuUserCog size={20} />
                <span>Contact support</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Help;
