import LazyLoadImages from "./LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

export const TestimonialCard = ({
  quote,
  name,
  company,
}: {
  quote: string;
  name: string;
  company: string;
}) => (
  <div className="relative mx-4 flex min-h-full flex-col justify-between overflow-hidden rounded-[22px] px-6 py-8 md:mx-2">
    {/* Background */}
    <div className="testimonials-cardBg absolute inset-0 z-0"></div>
    <div className="relative z-10">
      <LazyLoadImages
        src={`${REACT_APP_S3_URL}/website-assets/landingPage/testimonials/quotations.webp`}
        alt="quotes"
        className="absolute left-0 top-0 z-10"
      />
      <p className="pt-16 font-plusJakartaSans text-base text-[#D4D4D4] lg:text-lg">
        {quote}
      </p>
    </div>
    <div className="relative z-10 mt-10 flex items-center space-x-4">
      <div>
        <h4 className="text-mercury">{name}</h4>
        <h6 className="text-silverChalice">{company}</h6>
      </div>
    </div>
    <LazyLoadImages
      src={`${REACT_APP_S3_URL}/website-assets/landingPage/testimonials/testimonialspiece.webp`}
      alt="engagement"
      className="absolute bottom-0 right-0 z-10 rounded-r-[22px]"
    />
  </div>
);
