import React, { useState } from "react";
import LazyLoadImages from "./LazyLoadImages";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import "../styles/Sections/DataPoints.css";

const { REACT_APP_S3_URL } = process.env;

const stats = [
  { count: 20, label: ["Brands", "Engaged"], bgColor: "#963C43", suffix: "+" },
  {
    count: 40,
    label: ["Games", "Customized"],
    bgColor: "#40509C",
    suffix: "+",
  },
  {
    count: 10,
    label: ["Value", "Generated"],
    bgColor: "#5F5F5F",
    suffix: "x",
  },
  {
    count: 8,
    label: ["Games To", "Choose", "From"],
    bgColor: "#5C8B41",
    suffix: "+",
  },
  {
    count: 45,
    label: ["Higher Engagement", "Delivered"],
    bgColor: "#9D6A3E",
    suffix: "%",
  },
];

const icons = [
  { src: "brandEngagement.webp", bgColor: "#963C43" },
  { src: "customizedGames.webp", bgColor: "#40509C" },
  { src: "gameToChooseFrom.webp", bgColor: "#5C8B41" },
  { src: "campaignsGamified.webp", bgColor: "#9D6A3E" },
];

const StatCard = ({
  count,
  label,
  bgColor,
  suffix = "",
  isCenter,
}: {
  count: number;
  label: string[];
  bgColor: string;
  suffix?: string;
  isCenter?: boolean;
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const [startAnimation, setStartAnimation] = useState(false);

  // Start the count animation when in view
  React.useEffect(() => {
    if (inView) setStartAnimation(true);
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`flex h-[90px] w-full items-center justify-center rounded-2xl font-medium text-mercury hover:scale-1.2 hover:opacity-90 420_px:h-[100px] 420_px:rounded-[20px] 420_px:px-8 md:h-auto md:w-auto md:px-0 md:py-5 xl:p-10 ${
        isCenter
          ? "flex-col space-x-0 text-center md:space-y-2 xl:space-y-4"
          : "space-x-3 420_px:space-x-4 xl:space-x-6"
      }`}
      style={{ backgroundColor: bgColor }}
    >
      <h2 className="text-[24px] font-semibold xsm:text-[32px] xsm:font-medium 420_px:text-5xl lg:text-6xl xl:text-[76px]">
        {startAnimation ? (
          <CountUp start={0} end={count} duration={2} suffix={suffix} />
        ) : (
          0
        )}
      </h2>
      <p className="text-sm font-medium lg:text-lg xl:text-2xl">
        {label.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
};

const IconCard = ({ src, bgColor }: { src: string; bgColor: string }) => (
  <div
    className={`flex h-[90px] w-full items-center justify-center rounded-2xl hover:scale-1.2 hover:opacity-90 420_px:h-[100px] 420_px:rounded-[20px] md:h-20 xl:h-28`}
    style={{ backgroundColor: bgColor }}
  >
    <LazyLoadImages
      src={`${REACT_APP_S3_URL}/website-assets/landingPage/data-points/${src}`}
      alt={src}
      className="h-8 420_px:h-12 xl:h-16"
    />
  </div>
);

const DataPoints = () => {
  return (
    <div className="w-full bg-codGray px-6 py-10 font-plusJakartaSans text-mercury sm:p-10 md:px-12 md:py-16 xl:px-20 xl:py-24">
      <div className="m-auto hidden w-full grid-cols-3 md:grid md:gap-3 xl:gap-6">
        {/* Left Column */}
        <div className="grid grid-rows-2 md:gap-y-3 xl:gap-y-6">
          <StatCard {...stats[0]} />
          <StatCard {...stats[1]} />
        </div>

        {/* Center Column */}
        <div className="grid h-fit md:gap-3 xl:gap-6">
          <div className="flex items-center justify-between md:gap-x-3 xl:gap-x-6">
            <IconCard {...icons[0]} />
            <IconCard {...icons[1]} />
          </div>
          <StatCard {...stats[2]} isCenter /> {/* Center-aligned text */}
          <div className="flex items-center justify-between md:gap-x-3 xl:gap-x-6">
            <IconCard {...icons[2]} />
            <IconCard {...icons[3]} />
          </div>
        </div>

        {/* Right Column */}
        <div className="grid grid-rows-2 md:gap-y-3 xl:gap-y-6">
          <StatCard {...stats[3]} />
          <StatCard {...stats[4]} />
        </div>
      </div>
      <div className="flex flex-col gap-y-3 420_px:gap-y-4 md:hidden">
        <div className="flex w-full gap-x-3 420_px:gap-x-4">
          <div className="w-[80%] 420_px:w-[70%]">
            <StatCard {...stats[0]} />
          </div>{" "}
          <div className="w-[20%] 420_px:w-[30%]">
            <IconCard {...icons[0]} />{" "}
          </div>
        </div>
        <div className="flex w-full gap-x-3 420_px:gap-x-4">
          <div className="w-[80%] 420_px:w-[70%]">
            <StatCard {...stats[1]} />
          </div>{" "}
          <div className="w-[20%] 420_px:w-[30%]">
            <IconCard {...icons[1]} />{" "}
          </div>
        </div>
        <div className="flex w-full gap-x-3 420_px:gap-x-4">
          <StatCard {...stats[2]} />
        </div>
        <div className="flex w-full gap-x-3 420_px:gap-x-4">
          <div className="w-[80%] 420_px:w-[70%]">
            <StatCard {...stats[3]} />
          </div>{" "}
          <div className="w-[20%] 420_px:w-[30%]">
            <IconCard {...icons[2]} />{" "}
          </div>
        </div>
        <div className="flex w-full gap-x-3 420_px:gap-x-4">
          <div className="w-[80%] 420_px:w-[70%]">
            <StatCard {...stats[4]} />
          </div>{" "}
          <div className="w-[20%] 420_px:w-[30%]">
            <IconCard {...icons[3]} />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPoints;
