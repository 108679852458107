import React from "react";

interface PrivacyPolicyLinkProps {
  url: string;
  children: React.ReactNode;
}

const PrivacyPolicyLink: React.FC<PrivacyPolicyLinkProps> = ({
  url,
  children,
}) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="relative mr-1 break-words underline underline-offset-2 hover:opacity-80"
  >
    <strong className="relative font-plusJakartaSans font-normal text-white">
      {children}
    </strong>
  </a>
);

export default PrivacyPolicyLink;
