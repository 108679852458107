import axios from "axios";

interface TrialEligibilityResponse {
  data: {
    trialAvailable: boolean;
  };
  statusCode: number;
  success: boolean;
}

export const getTrialEligibility =
  async (): Promise<TrialEligibilityResponse> => {
    try {
      const accessToken = localStorage.getItem("xSudTzMYmd");

      if (!accessToken) {
        throw new Error("No access token found.");
      }

      const response = await axios.get<TrialEligibilityResponse>(
        `${process.env.REACT_APP_API_URL}/campaign/trial`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw new Error("Error fetching trial eligibility");
    }
  };

interface TrialResponse {
  data: any;
}

export const subscribeTrial = async (campaignId: string): Promise<any> => {
  try {
    const accessToken = localStorage.getItem("xSudTzMYmd");

    if (!accessToken) {
      throw new Error("No access token found.");
    }

    const response = await axios.post<TrialResponse>(
      `${process.env.REACT_APP_API_URL}/campaign/trial/${campaignId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching trial subscription");
  }
};
