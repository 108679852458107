export const faqAssets = [
  {
    src: "/website-assets/FaqPage/yellowEllipse.webp",
    alt: "yellowEllipse",
    className: "absolute z-0 h-60 sm:h-80 xl:h-auto top-0",
  },
  {
    src: "/website-assets/FaqPage/greenEllipse.webp",
    alt: "greenEllipse",
    className: "absolute z-0 h-60 sm:h-80 xl:h-auto top-48",
  },
  {
    src: "/website-assets/FaqPage/redEllipse.webp",
    alt: "redEllipse",
    className: "absolute z-0 h-60 sm:h-80 xl:h-auto right-0 top-24",
  },
  {
    src: "/website-assets/FaqPage/blueEllipse.webp",
    alt: "blueEllipse",
    className: "absolute z-0 h-60 sm:h-80 xl:h-auto left-0 top-24",
  },
  {
    src: "/website-assets/FaqPage/faqVector1.webp",
    alt: "book",
    className:
      "absolute h-16 z-0 right-10 top-20 md:h-20 md:top-72 lg:right-20 lg:h-28 xl:top-80 right-0 xl:right-32 xl:h-32",
  },
  {
    src: "/website-assets/FaqPage/faqVector2.webp",
    alt: "question",
    className:
      "absolute h-14 z-0 top-20 left-12 md:h-16 lg:h-24 lg:left-24 xl:top-40 md:top-32 xl:left-32 xl:h-32",
  },
];

export const privacyIcons = [
  {
    src: "/website-assets/PrivacyPolicyPage/pieChart.svg",
    alt: "pieChart",
    className: "absolute xl:h-auto h-[10%] left-[1%] top-[4%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/internet.svg",
    alt: "internet",
    className: "absolute xl:h-auto h-[10%] left-[6%] sm:left-[11%] top-[28%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/key.svg",
    alt: "key",
    className:
      "absolute xl:h-auto h-[10%] bottom-[38%] left-[10%] sm:left-[18%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/search.svg",
    alt: "search",
    className: "absolute xl:h-auto h-[10%] left-[12%] sm:left-[22%] top-[12%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/mail.svg",
    alt: "mail",
    className:
      "absolute xl:h-auto h-[10%] bottom-[48%] left-[16%] sm:left-[28%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/shop.svg",
    alt: "shop",
    className: "absolute xl:h-auto h-[10%] bottom-[28%] left-[3%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/message.svg",
    alt: "message",
    className: "absolute xl:h-auto h-[10%] right-[1%] top-[4%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/graph.svg",
    alt: "graph",
    className: "absolute xl:h-auto h-[10%] right-[6%] sm:right-[11%] top-[28%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/lock.svg",
    alt: "lock",
    className:
      "absolute xl:h-auto h-[10%] bottom-[38%] right-[10%] sm:right-[18%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/cloud.svg",
    alt: "cloud",
    className:
      "absolute xl:h-auto h-[10%] right-[12%] sm:right-[22%] top-[12%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/globe.svg",
    alt: "globe",
    className:
      "absolute xl:h-auto h-[10%] bottom-[48%] right-[16%] sm:right-[28%]",
  },
  {
    src: "/website-assets/PrivacyPolicyPage/user.svg",
    alt: "user",
    className: "absolute xl:h-auto h-[10%] bottom-[28%] right-[3%]",
  },
];

export const pricingAssets = [
  {
    src: "/website-assets/PricingPage/yellowEllipse.webp",
    alt: "yellowEllipse",
    className: "absolute inset-0 z-10 w-full",
  },
  {
    src: "/website-assets/PricingPage/sparkle-left.webp",
    alt: "sparkle-left",
    className:
      "absolute inset-0 h-6 md:h-8 lg:h-auto top-[4%] left-[5%] md:left-[10%] md:top-[7%] z-0",
  },
  {
    src: "/website-assets/PricingPage/sparkle-right.webp",
    alt: "sparkle-right",
    className:
      "absolute inset-0 h-6 md:h-8 lg:h-auto md:left-[85%] left-[90%] top-[4%] md:top-[7%] z-0",
  },
  {
    src: "/website-assets/PricingPage/diamond.webp",
    alt: "diamond",
    className:
      "absolute inset-0 h-6 md:h-auto left-[8%] md:left-[20%] top-[6%] md:top-[14%] z-0",
  },
  {
    src: "/website-assets/PricingPage/flower.webp",
    alt: "flower",
    className:
      "absolute inset-0 h-6 md:h-auto left-[90%] md:left-[75%] top-[6%] md:top-[14%] z-0",
  },
];
