import FooterSection from "../components/Footer/Footer";
import Navbar from "../components/Navbar";
import ImageWrapper from "../components/ImageWrapper";
import { sections } from "./privacyPolicy/PrivacySections";
import { privacyIcons } from "../utils/assets";
import { RightArrowIcon } from "../components/SvgIcons";

const PrivacyPolicy = () => {
  return (
    <div className="relative bg-codGray font-plusJakartaSans text-mercury">
      <div className="headerBackground absolute inset-0 z-0 h-screen"></div>
      <section className="relative z-10">
        <Navbar />
        <div className="mx-6 mb-20 sm:mx-10 md:mx-12 xl:mx-20 xl:mb-32">
          <div className="relative py-10 text-center lg:py-20">
            {privacyIcons.map((icon, index) => (
              <ImageWrapper
                key={index}
                src={icon.src}
                alt={icon.alt}
                className={icon.className}
              />
            ))}
            <h1 className="font-epilogue text-3xl font-bold leading-snug md:text-4xl xl:text-5xl">
              Privacy policy
            </h1>
            <p className="my-2 font-plusJakartaSans text-sm text-silverChalice md:text-base lg:my-6">
              Last Updated on May 24th, 2024
            </p>
          </div>
          <div className="justify-between lg:flex">
            <div className="top-10 z-100 flex h-fit flex-col space-y-2 lg:sticky lg:w-[40%]">
              <h2 className="font-epilogue text-xl font-semibold md:text-2xl xl:text-3xl">
                Table of content
              </h2>{" "}
              {[
                { label: "Introduction", id: "introduction" },
                { label: "Consent", id: "consent" },
                { label: "Information we collect", id: "informationWeCollect" },
                {
                  label: "How we use your information",
                  id: "howWeUseYourInformation",
                },
                {
                  label: "Our Advertising Partners",
                  id: "ourAdvertisingPartners",
                },
                {
                  label: "Advertising Partners Privacy Policies",
                  id: "advertisingPartnersPrivacyPolicies",
                },
                {
                  label: "Third Party Privacy Policies",
                  id: "thirdPartyPrivacyPolicies",
                },
                {
                  label: "CCPA Privacy Rights",
                  id: "ccpaPrivacyRights",
                },
                {
                  label: "GDPR Data Protection Rights",
                  id: "gdprDataProtectionRights",
                },
                {
                  label: "Children's Information",
                  id: "childrensInformation",
                },
              ].map((option) => (
                <div
                  key={option.id}
                  className="group flex w-fit cursor-pointer items-center underline decoration-1 underline-offset-4"
                  onClick={() => {
                    const element = document.getElementById(option.id);
                    if (element) {
                      const offset = 20;
                      const elementPosition =
                        element.getBoundingClientRect().top + window.scrollY;
                      const offsetPosition = elementPosition - offset;

                      window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  <div className="hidden transition-transform duration-100 group-hover:inline-block">
                    <RightArrowIcon width={14} height={14} />
                  </div>
                  <p className="whitespace-normal break-words transition-transform duration-100 group-hover:translate-x-2 md:text-lg lg:text-xl">
                    {option.label}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex w-full flex-col space-y-6 overflow-y-auto lg:w-[55%]">
              <p className="mt-10 text-silverChalice lg:mt-0 lg:max-w-[70%] lg:text-lg">
                This Privacy Policy will help you better understand how we
                collect, use, and share your personal information.
              </p>

              {sections.map((section) => (
                <div id={section.id} key={section.id}>
                  <h2 className="my-2 font-epilogue text-xl font-semibold md:my-4 lg:text-2xl">
                    {section.title}
                  </h2>
                  <div className="flex flex-col space-y-2 text-silverChalice lg:text-lg xl:space-y-3">
                    {section.content.map((paragraph, index) => (
                      <div key={index}>
                        {typeof paragraph === "string" ? paragraph : paragraph}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <FooterSection />
      </section>
    </div>
  );
};

export default PrivacyPolicy;
