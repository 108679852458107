import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Footer from "./components/Footer/Footer";
import TrustedPartners from "./components/TrustedPartners";
import Game from "./pages/Game";
import LoginContextProvider from "./components/Context/LoginContext/LoginContext";
import SignUpContainer from "./components/signUp/SignUpContainer";
import Login from "./components/login/Login";
import DashBoard from "./components/DashBoard/DashBoard";
import Settings from "./components/Settings/Settings";
import Campaigns from "./components/DashBoard/Campaigns/Campaigns";
import ForgotPassword from "./components/login/ForgotPassword";
import ResetPassword from "./components/login/ResetPassword";
import VerifyMail from "./components/VerifyMail";
import Profile from "./components/UserProfile/Profile";
import WorkInProgress from "./components/WorkInProgress";
import PageNotFound from "./components/PageNotFound";
import CampaignForm from "./components/DashBoard/Campaigns/CampaignForm";
import "./index.css";
import DeleteAccount from "./components/UserProfile/DeleteAccount";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Statistics from "./components/DashBoard/Statistics/Statistics";
import PlansAndFaq from "./pages/PlansAndFaq";
import Contact from "./pages/Contact";
import DataPoints from "./components/DataPoints";
import LandingCTA from "./components/LandingCTA";
import WhatWeOffer from "./components/WhatWeOffer";
import WhyWorkplay from "./components/WhyWorkplay";
import Testimonials from "./components/Testimonials";
import Service from "./pages/Service";
import Faq from "./pages/Faq";
import Pricing from "./pages/Pricing";
import Quiz from "./pages/games/Quiz";
import SpinTheWheel from "./pages/games/SpinTheWheel";
import MemoryCard from "./pages/games/MemoryCard";
import FindTheObject from "./pages/games/FindTheObject";
import JigsawPuzzle from "./pages/games/JigsawPuzzle";
import SlotMachine from "./pages/games/SlotMachine";
import TicTacToe from "./pages/games/TicTacToe";
import SpotTheDifference from "./pages/games/SpotTheDifference";
import RollTheDice from "./pages/games/RollTheDice";
import Sitemap from "./pages/Sitemap";
import Integration from "./pages/Integration";
import TermsOfUse from "./pages/TermsOfUse";
import { useEffect, useState } from "react";
import { fetchCountry } from "./services/countryApi";
import { fetchAllPlans } from "./features/planSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./app/store";
import { ToastContainer } from "react-toastify";
import Checkout from "./pages/payment/Checkout";
import AboutUs from "./pages/AboutUs";
import Confirmation from "./pages/payment/Confirmation";
import Invoice from "./pages/Invoice";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [country, setCountry] = useState<string | null>(null);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const countryCode = await fetchCountry();
        const country = countryCode.data;
        const updatedData = [{ country }];
        localStorage.setItem("pricingdetail", JSON.stringify(updatedData));

        setCountry(country);
        dispatch(fetchAllPlans());
      } catch (error) {
        console.error(error);
      }
    };

    getCountry();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <LoginContextProvider>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
                <TrustedPartners />
                <DataPoints />
                <WhatWeOffer />
                <WhyWorkplay />
                <Testimonials />
                <LandingCTA />
                <Footer />
              </Layout>
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/game" element={<Game />}>
            <Route path="quiz" element={<Quiz />} />
            <Route path="spin-the-wheel" element={<SpinTheWheel />} />
            <Route path="memory-card" element={<MemoryCard />} />{" "}
            <Route path="find-the-object" element={<FindTheObject />} />
            <Route path="jigsaw-puzzle" element={<JigsawPuzzle />} />
            <Route path="slot-machine" element={<SlotMachine />} />
            <Route path="tic-tac-toe" element={<TicTacToe />} />
            <Route path="spot-the-difference" element={<SpotTheDifference />} />
            <Route path="roll-the-dice" element={<RollTheDice />} />
          </Route>
          {/* <Route path="/plans" element={<PlansAndFaq />} /> */}
          <Route path="/service" element={<Service />} />
          <Route path="/integration" element={<Integration />} />
          <Route path="/signup" element={<SignUpContainer />} />
          <Route path="/login" element={<Login />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/build" element={<WorkInProgress />} />
          <Route path="/profile/delete" element={<DeleteAccount />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirm-payment" element={<Confirmation />} />
          <Route path="/invoice/print/:ccid" element={<Invoice />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/dashboard" element={<DashBoard />}>
            <Route path="gamedetails" element={<CampaignForm />} />
            {/* <Route path="settings" element={<Settings />} /> */}
            <Route path="statistics" element={<Statistics />} />
            <Route path="campaigns" element={<Campaigns />}>
              <Route path="create" element={<CampaignForm />} />
            </Route>
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="/forgetpassword" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account-verify" element={<VerifyMail />} />
        </Routes>
      </LoginContextProvider>
      <ToastContainer className={"custom-toast"} />
    </BrowserRouter>
  );
}

export default App;
