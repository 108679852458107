import React from "react";
import { CardIcon } from "./SvgIcons";

interface CardPaymentMethodProps {
  cardNetwork: string;
  last4: string;
}

const CardPaymentMethod: React.FC<CardPaymentMethodProps> = ({
  cardNetwork,
  last4,
}) => {
  const cardNetworks = {
    mastercard: "mastercard.svg",
    visa: "visa.svg",
    maestro: "maestro.svg",
    rupay: "rupay.svg",
    bajaj: "bajaj.svg",
    americanexpress: "amex.svg",
    diners: "diners.svg",
  };

  const network =
    cardNetworks[cardNetwork.toLowerCase() as keyof typeof cardNetworks];

  return (
    <div className="flex items-center space-x-2">
      {network ? (
        <img
          src={`${process.env.REACT_APP_S3_URL}/website-assets/payment/card/${network}`}
          alt={cardNetwork}
          className="h-6 w-6"
        />
      ) : (
        <CardIcon />
      )}
      <span className="text-[12px] font-medium">•••• •••• •••• {last4}</span>
    </div>
  );
};

export default CardPaymentMethod;
