import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getAllSubscriptions = async () => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_API_URL}/subscription/get-all-subscription`,
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching subscriptions:", error);
    throw error;
  }
};
