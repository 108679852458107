import { useEffect, useState } from "react";
import { LeftChevronIcon, RightChevronIcon } from "./SvgIcons";
import { TestimonialCard } from "./TestimonialCard";

const Testimonials = () => {
  const testimonials = [
    {
      quote:
        "We were thrilled to partner with Workplay Studio for our recent Intuit event! Their customized quiz solution was a huge hit with our team fostering collaboration, creativity, and plenty of laughs. The Workplay Studio team was professional, responsive, and a pleasure to work with. We highly recommend their services for any corporate event looking to drive engagement and teamwork.",
      name: "Arjun Menon",
      company: "Intuit",
    },
    {
      quote:
        "The team is super quick to respond and has some fun, creative ideas for corporate engagements. They’re easy to connect with and really take the time to customize everything to fit exactly what you need. Such a great experience working with them!",
      name: "Rebecca Pereira",
      company: "Acme Experience",
    },
    {
      quote:
        "An amazing team to work with. A one stop solution for the best engagement with your guests as either a marketing campaign or even something just for fun! Try their Quiz! Best cost in the industry",
      name: "Adeeb",
      company: "Uniphore",
    },
    {
      quote: "It has been a great experience working with them in projects.",
      name: "Nishant Mallick",
      company: "Indi Rides",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLeftChevronClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleRightChevronClick = () => {
    if (currentIndex < testimonials.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    // Set up auto scroll with interval
    const autoScrollInterval = setInterval(() => {
      if (currentIndex < testimonials.length - 3) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        setCurrentIndex(0); // Reset to first set if at the end
      }
    }, 7000); // Scroll every 7 seconds

    return () => clearInterval(autoScrollInterval);
  }, [currentIndex, testimonials.length]);

  return (
    <div className="bg-codGray px-6 py-10 sm:p-10 md:px-12 md:py-16 xl:px-20 xl:py-24">
      <h2 className="font-epilogue text-3xl font-semibold text-mercury md:text-4xl xl:text-5xl">
        What people say about us
      </h2>
      <p className="py-3 font-plusJakartaSans text-base text-silverChalice md:py-6 lg:text-lg xl:py-8 xl:text-[22px]">
        Don't just take our word for it — see what our satisfied clients have to
        say about their transformative experiences with us!
      </p>
      <div className="relative">
        <div className="relative mx-auto mt-6 w-full overflow-hidden xl:mt-10">
          {/* Container holding the horizontal flexbox of cards */}
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (100 / (window.innerWidth >= 1260 ? 3 : window.innerWidth >= 900 ? 2 : 1))}%)`,
            }}
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="w-full flex-shrink-0 md:w-1/2 xl:w-1/3"
              >
                <TestimonialCard
                  quote={testimonial.quote}
                  name={testimonial.name}
                  company={testimonial.company}
                />
              </div>
            ))}
          </div>
        </div>
        {/* Left Chevron */}
        <div
          className={`absolute bottom-[50%] left-[-12px] transform cursor-pointer sm:left-[-20px] md:left-[-30px] xl:left-[-40px] ${currentIndex === 0 ? "opacity-30" : "opacity-100"}`}
          onClick={handleLeftChevronClick}
        >
          <LeftChevronIcon />
        </div>
        {/* Right Chevron */}
        <div
          className={`absolute bottom-[50%] right-[-12px] transform cursor-pointer sm:right-[-20px] md:right-[-30px] xl:right-[-40px] ${currentIndex === testimonials.length - 3 ? "opacity-30" : "opacity-100"}`}
          onClick={handleRightChevronClick}
        >
          <RightChevronIcon />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
