import { useState } from "react";
import { RightArrowIcon } from "../../../components/SvgIcons";
import "../../../styles/Sections/Game.css";
import LazyLoadImages from "../../../components/LazyLoadImages";

interface Section {
  label: string;
  id: string;
}

interface UseCaseProps {
  title: string;
  sections: {
    id: string;
    title: string;
    imgSrc: string;
    content: string[];
  }[];
  labels: Section[];
}

const UseCase: React.FC<UseCaseProps> = ({ title, sections, labels }) => {
  const [activeSection, setActiveSection] = useState<string>(
    sections[0]?.id || "",
  );

  const handleOptionClick = (id: string) => {
    setActiveSection(id);
    const element = document.getElementById(id);
    if (element) {
      const offset = 0;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <h2 className="mb-12 mt-4 px-10 text-center font-epilogue text-2xl font-semibold text-mercury md:text-3xl lg:mb-14 xl:text-5xl">
        {title}
      </h2>

      <div className="useCase-bg relative items-center justify-between px-6 py-10 text-mercury sm:px-10 md:px-12 lg:flex xl:px-20">
        <LazyLoadImages
          src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/rollTheDice/pattern.webp`}
          className="absolute left-0 top-0"
          alt="pattern"
        />
        <div className="top-10 z-100 mt-4 flex h-fit flex-col space-y-6 lg:sticky lg:w-[40%]">
          {labels.map((option) => (
            <div
              key={option.id}
              className="group z-100 flex w-fit cursor-pointer items-center"
              onClick={() => handleOptionClick(option.id)}
            >
              <div
                className={`z-100 transition-transform duration-100 group-hover:inline-block ${activeSection === option.id ? "mr-2 group-hover:inline-block" : "hidden"}`}
              >
                <RightArrowIcon width={14} height={14} />
              </div>
              <p
                className={`whitespace-normal break-words transition-all duration-300 sm:text-lg lg:text-xl ${
                  activeSection === option.id
                    ? "underline underline-offset-4 group-hover:opacity-80"
                    : "text-mercury group-hover:translate-x-2"
                }`}
              >
                {option.label}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-8 h-[1px] w-full bg-white bg-opacity-20 lg:hidden"></div>
        <div className="z-100 flex flex-col items-center justify-center scroll-smooth lg:h-[62vh] lg:w-[55%] lg:overflow-y-auto">
          {sections.map((section) => (
            <div
              id={section.id}
              key={section.id}
              className={`block transition-all duration-300 lg:mr-10 ${
                activeSection === section.id
                  ? "lg:block lg:h-auto lg:opacity-100"
                  : "lg:hidden lg:h-0 lg:opacity-0"
              }`}
            >
              <h2 className="mt-8 font-epilogue text-lg font-medium md:text-xl lg:mt-0 xl:text-2xl">
                {section.title}
              </h2>
              <img
                src={section.imgSrc}
                alt={section.id}
                className="mx-auto my-8 w-32 md:w-40"
              />
              <div className="flex w-full flex-col space-y-2 text-silverChalice sm:text-lg xl:space-y-3">
                {section.content.map((paragraph, index) => (
                  <div key={index}>
                    {typeof paragraph === "string" ? paragraph : paragraph}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCase;
