import LandingCTA from "../../components/LandingCTA";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Brand Engagement", id: "brandEngagement" },
  { label: "Mindfulness & Relaxation", id: "mindfulness&Relaxation" },
  { label: "Event Activities", id: "eventActivities" },
  { label: "Educational Tool", id: "educationalTool" },
  { label: "Reward-Based Challenges", id: "rewardBasedChallenges" },
];

const useCaseDetails = [
  {
    id: "brandEngagement",
    title: "Brand Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/brandEngagement.webp`,
    content: [
      "Create puzzles using branded visuals, turning each piece into a subtle yet powerful promotion of your business. Every completed puzzle showcases your brand in a rewarding and memorable way, leaving a lasting impression on players.",
    ],
  },
  {
    id: "mindfulness&Relaxation",
    title: "Mindfulness & Relaxation",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/mindfulness.webp`,
    content: [
      "Offer a game that lets players unwind while staying engaged, creating a positive connection with your brand as a source of calm and fun. This positions your business as one that cares about the well-being and mental health of its audience.",
    ],
  },
  {
    id: "eventActivities",
    title: "Event Activities",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/eventActivity.webp`,
    content: [
      "Make your events unforgettable by featuring puzzles that attendees can solve for prizes, driving participation and interaction. By adding a competitive or cooperative element, you create a buzz that keeps people engaged throughout the event.",
    ],
  },
  {
    id: "educationalTool",
    title: "Educational Tool",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/educationTool.webp`,
    content: [
      "Use puzzles to support learning initiatives, improving problem-solving and cognitive skills while keeping users entertained. This makes it ideal for schools, training programs, or campaigns targeting educational outcomes in a fun and interactive way.",
    ],
  },
  {
    id: "rewardBasedChallenges",
    title: "Reward-Based Challenges",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/rewardBasedChallenge.webp`,
    content: [
      "Motivate players to continue by offering unlockable rewards or new puzzles, driving repeat engagement and long-term retention. Adding progression-based rewards ensures players feel a sense of achievement with every level, encouraging them to keep coming back.",
    ],
  },
];

const strategyItems = [
  {
    title: "Highly Customizable, For You",
    description:
      "Customizable images and themes ensure the puzzles align perfectly with your brand, helping you connect with your audience in a creative and meaningful way. Over time, this builds loyalty and fosters a positive brand association, driving sustained customer engagement and retention.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/highlyCustomizable.webp`,
    imgAlt: "highlyCustomizable",
  },
  {
    title: "The Ultimate Marketing Advantage",
    description:
      "From product launches to customer loyalty programs, its versatile nature ensures it delivers results in various contexts. By blending entertainment with brand storytelling, the game increases awareness, deepens customer engagement, and provides a smart, results-driven addition to your marketing toolkit.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/marketingAdvantage.webp`,
    imgAlt: "marketingAdvantage",
    reverse: true,
  },
];

const handlePlay = () => {
  window.open("https://jigsaw-puzzle.workplay.digital/", "_target");
};

const JigsawPuzzle = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Jigsaw <br /> Puzzle
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Jigsaw Puzzle
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Engage your audience meaningfully with our Jigsaw Puzzle game.
              Branded images and custom themes create unique, entertaining
              experiences that reinforce your brand message. Combining challenge
              and mindfulness, it fosters positive associations, ensuring
              players stay immersed and return for more.
            </p>
            <button
              className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              onClick={handlePlay}
            >
              Play Now
            </button>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/puzzleGroup.webp`}
            alt="Puzzle"
            className="absolute bottom-[-10%] right-[-7%] z-0 hidden w-[90%] sm:bottom-[0%] sm:block md:bottom-[-65%] lg:bottom-[-70%] lg:w-[70%] xl:right-[-6%] xl:top-[-65%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/jigsawPuzzle/puzzle.webp`}
            alt="Puzzle"
            className="absolute right-0 top-[-2%] h-[14vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <UseCase
        title="How You Can Use Jigsaw Puzzle"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <Strategy
        title="How Jigsaw Puzzle Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default JigsawPuzzle;
