import React from "react";
import LazyLoadImages from "./LazyLoadImages";
const { REACT_APP_S3_URL } = process.env;

const FlowDiagram: React.FC = () => {
  return (
    <div className="h-full bg-codGray 3xl:py-40">
      <div className="whatWeOfferBg z-50 px-6 py-4 sm:py-10 md:px-12 xl:px-20">
        <div className="mt-10 flex items-center justify-start xl:mt-28 xl:space-x-10">
          <div>
            <div className="relative flex items-center">
              <h2 className="w-[40%] font-epilogue text-3xl font-semibold text-mercury md:text-4xl xl:text-5xl">
                What We Offer
              </h2>
              <LazyLoadImages
                src={`${REACT_APP_S3_URL}/website-assets/servicePage/bolt.png`}
                alt="bolt"
                className="absolute left-[0%] top-[10%] h-12 md:top-[14%] md:h-16 xl:top-20 xl:h-auto"
              />
              <img
                src={`${REACT_APP_S3_URL}/website-assets/servicePage/mappingLines.png`}
                alt="mappingLines"
                className="hidden md:block md:h-[60vh] xl:h-auto"
              />{" "}
              <img
                src={`${REACT_APP_S3_URL}/website-assets/servicePage/smallMappingLines.png`}
                alt="mappingLines"
                className="block md:hidden"
              />{" "}
            </div>
          </div>
          <div className="font-poppins text-2xl xl:text-5xl">
            <div className="flex cursor-pointer items-center space-x-6 text-white hover:scale-110 hover:duration-500 hover:ease-in-out">
              <h2 className="text-carnation">01</h2>
              <p className="font-epilogue text-lg font-semibold sm:text-2xl xl:text-4xl">
                No Code Game Builder
              </p>
            </div>
            <div className="my-12 flex cursor-pointer items-center space-x-6 text-white hover:scale-110 hover:duration-500 hover:ease-in-out 520_px:my-14 xl:my-28">
              <h2 className="text-dodgerBlue">02</h2>
              <p className="font-epilogue text-lg font-semibold sm:text-2xl xl:text-4xl">
                In App Gamification
              </p>
            </div>
            <div className="my-12 flex cursor-pointer items-center space-x-6 text-white hover:scale-110 hover:duration-500 hover:ease-in-out 520_px:my-14 xl:my-28">
              <h2 className="text-mantis">03</h2>
              <p className="font-epilogue text-lg font-semibold sm:text-2xl xl:text-4xl">
                Customized Games
              </p>
            </div>
            <div className="relative">
              <div className=" flex cursor-pointer items-center space-x-6 text-white hover:scale-110 hover:duration-500 hover:ease-in-out">
                <h2 className="text-oldGold">04</h2>
                <p className="font-epilogue text-lg font-semibold sm:text-2xl xl:text-4xl">
                  Web Development
                </p>
              </div>
            </div>
            <LazyLoadImages
              src={`${REACT_APP_S3_URL}/website-assets/servicePage/pattern.png`}
              alt="pattern"
              className="absolute right-0 z-50 h-16 md:h-32 xl:h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowDiagram;
