import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../app/store";
import { toast } from "react-toastify";

const { REACT_APP_API_URL } = process.env;

interface CampaignMetaStatisticsData {
  impressions: number;
  submissions: number;
}

interface CampaignCountryStatisticsData {
  totalImpressions: number;
  country: string;
}

interface CampaignGraphData {
  totalImpressions: number;
  totalSubmissions: number;
  date: string;
}

interface SubscriptionPricing {
  IN: number;
  US: number;
  discount: number;
  isFree: boolean;
  validForInDays: number;
}

interface CampaignSubscription {
  _id: string;
  title: string;
  pricing: SubscriptionPricing;
  description: string;
  features: string[];
}

interface Campaign {
  id: string;
  userId: string;
  name: string;
  startDate: string;
  endDate: string;
  game: string;
  createdAt: string;
  updatedAt: string;
  gameId?: string | null;
  isPaid?: boolean;
  isTrial?: boolean;
  disabled?: boolean;
  subscription?: CampaignSubscription;
  subscriptionId?: string;
  latestPaymentOrderId?: string;
  expiresOn?: string;
}

interface CampaignsState {
  campaigns: Campaign[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  currentCampaign: {
    _id: string;
    userId: string;
    name: string;
    startDate: string;
    endDate: string;
    game: string;
    createdAt: string;
    updatedAt: string;
    gameId?: string | null;
    isPaid?: boolean;
    isTrial?: boolean;
    disabled?: boolean;
    subscription?: CampaignSubscription;
    subscriptionId?: string;
    latestPaymentOrderId?: string;
    expiresOn?: string;
  };
  campaignMetaStatisticsData: CampaignMetaStatisticsData | null;
  campaignCountryStatisticsData: CampaignCountryStatisticsData[] | null;
  campaignGraphData: CampaignGraphData[] | null;
}

const initialState: CampaignsState = {
  campaigns: [],
  status: "idle",
  error: null,
  currentCampaign: {
    _id: "",
    userId: "",
    name: "",
    startDate: "",
    endDate: "",
    game: "",
    createdAt: "",
    updatedAt: "",
  },
  campaignMetaStatisticsData: {
    impressions: 0,
    submissions: 0,
  },
  campaignCountryStatisticsData: [],
  campaignGraphData: [],
};

export const getAllCampaigns = createAsyncThunk(
  "campaigns/getAllCampaigns",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.get(`${REACT_APP_API_URL}/campaign`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success === true) {
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const createCampaign = createAsyncThunk(
  "campaigns/createCampaign",
  async (newCampaign: any, { rejectWithValue }) => {
    const token = localStorage.getItem("xSudTzMYmd");

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/campaign`,
        newCampaign,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success === true) {
        sessionStorage.setItem("ccid", response.data.data._id);
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const getCampaignMetaStatistics = createAsyncThunk(
  "campaigns/getCampaignMetaStatistics",
  async (
    { ccid, gameId }: { ccid: string; gameId: string },
    { rejectWithValue },
  ) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/campaign/meta/${ccid}/${gameId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success === true) {
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const getCampaignCountryStatistics = createAsyncThunk(
  "campaigns/getCampaignCountryStatistics",
  async (
    { ccid, gameId }: { ccid: string; gameId: string },
    { rejectWithValue },
  ) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/campaign/country/${ccid}/${gameId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success === true) {
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);
export const getCampaignGraphData = createAsyncThunk(
  "campaigns/getCampaignGraphData",
  async (
    {
      ccid,
      gameId,
      fromDate,
      toDate,
    }: { ccid: string; gameId: string; fromDate: string; toDate: string },
    { rejectWithValue },
  ) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/campaign/graph/${ccid}/${gameId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            fromDate: fromDate,
            toDate: toDate,
          },
        },
      );

      if (response.data.success === true) {
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const getCampaign = createAsyncThunk(
  "campaigns/getCampaign",
  async (ccid: string, { rejectWithValue }) => {
    // Add { rejectWithValue } here
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/campaign/${ccid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success === true) {
        return response.data.data;
      } else {
        // Return a rejected promise with the error message
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      // Return a rejected promise with the error message
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const getCampaignUsersData = createAsyncThunk(
  "campaigns/getCampaignUsersData",
  async (ccid: string, { rejectWithValue }) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/campaign/submissions/${ccid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success === true) {
        if (response.data.data.length === 0) {
          toast.info("No data found!", {
            autoClose: 1000,
            className: "toastify-toast",
          });
          return rejectWithValue("No data found");
        }
        toast.success("Downloading", {
          autoClose: 1000,
          className: "toastify-toast",
        });

        return response.data.data;
      } else {
        // Return a rejected promise with the error message
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.info("Collecting user information not enabled!", {
          autoClose: 1000,
          className: "toastify-toast",
        });
        return rejectWithValue("Collecting user information not enabled");
      }
      // Return a rejected promise with the error message
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const updateCampaign = createAsyncThunk(
  "campaigns/updateCampaign",
  async (
    { updateCampaign, ccid }: { updateCampaign: any; ccid: string },
    { rejectWithValue },
  ) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.patch(
        `${REACT_APP_API_URL}/campaign/${ccid}`,
        updateCampaign,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        },
      );
      if (response.data.success === true) {
        sessionStorage.setItem("ccid", response.data.data._id);
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

export const deleteCampaign = createAsyncThunk(
  "campaigns/deleteCampaign",
  async (ccid: string, { rejectWithValue }) => {
    const token = localStorage.getItem("xSudTzMYmd");
    try {
      const response = await axios.delete(
        `${REACT_APP_API_URL}/campaign/${ccid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success === true) {
        toast.success("Project deleted!", {
          autoClose: 1000,
          className: "toastify-toast",
        });
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      // Check for specific 400 error with "Subscription active unable to delete"
      if (
        error.response?.status === 400 ||
        error.response?.data?.message === "Subscription active unable to delete"
      ) {
        toast.error("Unable to delete project: Subscription is active.", {
          autoClose: 2000,
          className: "toastify-toast",
        });
      } else {
        toast.error("Oops! Something went wrong! Please try again later.", {
          autoClose: 1000,
          className: "toastify-toast",
        });
      }
      return rejectWithValue(error.response?.data?.message || "Unknown error");
    }
  },
);

export const LinkCampaigntoGame = createAsyncThunk(
  "campaigns/LinkCampaigntoGame",
  async (
    {
      gameData,
      ccid,
    }: {
      gameData: {
        game: string;
        gameId: string;
      };
      ccid: string;
    },
    { rejectWithValue },
  ) => {
    const token = localStorage.getItem("xSudTzMYmd");

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/campaign/link-game/${ccid}`,
        gameData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success === true) {
        toast.success("Game linked!", {
          autoClose: 1000,
          className: "toastify-toast",
        });
        return response.data.data;
      } else {
        return rejectWithValue("Unsuccessful response");
      }
    } catch (error: any) {
      toast.error("Oops! Something went wrong! Please try again later.", {
        autoClose: 1000,
        className: "toastify-toast",
      });
      return rejectWithValue(error.response.data.message || "Unknown error");
    }
  },
);

const CampaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCurrentCampaign: (state, action) => {
      state.currentCampaign = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCampaigns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllCampaigns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.campaigns = action.payload.map((campaign: any) => ({
          id: campaign._id, // Set the id field to _id
          ...campaign, // Spread the rest of the properties
        }));
      })
      .addCase(getAllCampaigns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(createCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentCampaign = action.payload;
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(getCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentCampaign = action.payload;
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentCampaign = action.payload;
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentCampaign = action.payload;
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(LinkCampaigntoGame.pending, (state) => {
        state.status = "loading";
      })
      .addCase(LinkCampaigntoGame.fulfilled, (state, action) => {
        state.campaignMetaStatisticsData = action.payload;
        state.status = "succeeded";
      })
      .addCase(LinkCampaigntoGame.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(getCampaignMetaStatistics.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCampaignMetaStatistics.fulfilled, (state, action) => {
        state.campaignMetaStatisticsData = action.payload;
        state.status = "succeeded";
      })
      .addCase(getCampaignMetaStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(getCampaignCountryStatistics.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCampaignCountryStatistics.fulfilled, (state, action) => {
        state.campaignCountryStatisticsData = action.payload;
        state.status = "succeeded";
      })
      .addCase(getCampaignCountryStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(getCampaignGraphData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCampaignGraphData.fulfilled, (state, action) => {
        state.campaignGraphData = action.payload;
        state.status = "succeeded";
      })
      .addCase(getCampaignGraphData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(getCampaignUsersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCampaignUsersData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getCampaignUsersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const CurrentCampaign = (state: RootState) =>
  state.campaign.currentCampaign;
export const CampaignMetaStatisticsData = (state: RootState) =>
  state.campaign.campaignMetaStatisticsData;
export const CampaignCountryStatisticsData = (state: RootState) =>
  state.campaign.campaignCountryStatisticsData;
export const CampaignGraphData = (state: RootState) =>
  state.campaign.campaignGraphData;
export const AllCampaigns = (state: RootState) => state.campaign.campaigns;
export const { setCurrentCampaign } = CampaignSlice.actions;

export default CampaignSlice.reducer;
