import React, { useState } from "react";
import PlanCard from "./PlanCard";
import PlanModal from "./PlanModal";
import { PlanDetails } from "../../types/planTypes";
import { businessPlan } from "../../data/businessPlan";
import { useDispatch, useSelector } from "react-redux";
import { setPlan } from "../../features/planSlice";
import { AppDispatch } from "../../app/store";

const Plan: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlanState] = useState<PlanDetails | null>(
    null,
  );
  const dispatch = useDispatch<AppDispatch>();
  const { allPlans = [] } = useSelector((state: any) => state.plan);

  const handlePlanClick = (plan: PlanDetails) => {
    setSelectedPlanState(plan);
    dispatch(setPlan(plan));
    setModalOpen(true);
  };

  return (
    <section className="py-14 lg:pb-20 lg:pt-32" id="plans">
      <div className="mx-auto max-w-[88%] font-plusJakartaSans lg:max-w-[85%]">
        {/* <PlanToggle active={pricingType} onToggle={setPricingType} /> */}
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 lg:gap-6">
          {allPlans.map((subscription: PlanDetails) => (
            <PlanCard
              key={subscription._id}
              planDetails={subscription}
              onClick={() => handlePlanClick(subscription)}
            />
          ))}
          <PlanCard
            planDetails={businessPlan}
            onClick={() => handlePlanClick(businessPlan)}
          />
        </div>
      </div>

      {selectedPlan && (
        <PlanModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          planDetails={selectedPlan}
          setSelectedPlan={setSelectedPlanState}
          subscriptions={allPlans}
          allPlanTitles={allPlans.map(
            (subscription: PlanDetails) => subscription.title,
          )}
        />
      )}
    </section>
  );
};

export default Plan;
