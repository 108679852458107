import React, { useEffect, useState } from "react";
import { getPaymentHistory } from "../services/paymentApi";
import { useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import {
  CurrentCampaign,
  getCampaign,
} from "../features/Campaigns/campaignSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import useFinalPrice from "../hooks/useFinalPrice";
import { formatContactNumber } from "../utils/contactNumber";

const Invoice: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(location.search);
  const ccid = searchParams.get("ccid");
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedRecord, setSelectedRecord] = useState<any | null>(null);

  const currentCampaign = useAppSelector(CurrentCampaign);

  useEffect(() => {
    const path = window.location.pathname;
    const id = path.split("/").pop();
    if (id) {
      setPaymentId(id);
    }
  }, []);

  useEffect(() => {
    if (!ccid) return;

    setLoading(true);
    setError(null);

    const fetchPaymentHistory = async () => {
      const token = localStorage.getItem("xSudTzMYmd");

      if (!token) {
        console.error("Authentication token is missing.");
        setLoading(false);
        return;
      }

      try {
        const paymentData = await getPaymentHistory(ccid, token);

        setSelectedPayments(paymentData.data);
        console.log("Payment data:", paymentData.data);

        dispatch(getCampaign(ccid));
        // Now find the selected record based on paymentId
        if (paymentId) {
          const matchedRecord = paymentData.data.find(
            (record: any) => record._id === paymentId,
          );
          if (matchedRecord) {
            setSelectedRecord(matchedRecord); // Set the selected record state
          } else {
            console.error("No matching record found for paymentId:", paymentId);
          }
        }
      } catch (error) {
        setError("Error fetching payment history.");
        console.error("Error fetching payment history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentHistory();
  }, [ccid, paymentId]);

  useEffect(() => {
    // Log selectedRecord whenever it changes
    if (selectedRecord) {
      console.log("Selected Record:", selectedRecord);
    }
  }, [selectedRecord]);

  const formatCurrency = (currency: string, amount: number) => {
    // Format the amount with two decimal places and add commas for thousands
    const formattedAmount = amount
      .toFixed(2) // Ensure two decimal places
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousands

    switch (currency) {
      case "INR":
        return `₹${formattedAmount}`;
      case "USD":
        return `$${formattedAmount}`;
      default:
        return `$${formattedAmount}`;
    }
  };

  const originalPrice =
    (selectedRecord?.originalAmountWithoutDiscount || 0) / 100;
  const gstRate = 18;
  const discountRate = currentCampaign.subscription?.pricing.discount || 0;
  const currency = selectedRecord?.currency;

  const { gstAmount, discountAmount, finalPrice, basePrice, discountedPrice } =
    useFinalPrice(originalPrice, gstRate, discountRate, currency);

  const contact = selectedRecord?.notes?.payment?.contact;
  const country = selectedRecord?.notes?.payment?.notes?.country;

  return (
    <>
      {error ? (
        <div className="relative flex  h-screen w-full flex-col items-center justify-center text-center">
          {" "}
          <a href="/" rel="noopener noreferrer">
            <img
              src={`${process.env.REACT_APP_S3_URL}/assets/workplay-logo.png`}
              className="absolute left-7 top-7 h-5 sm:h-6"
              alt="forgot"
            />{" "}
          </a>
          <img
            src={`${process.env.REACT_APP_S3_URL}/assets/forgot.jpg`}
            className="w-[80%] xl:h-[70vh] xl:w-auto"
            alt="forgot"
          />
          <p className="px-8 text-lg font-medium">
            Hmm... This link doesn't look right.
            <a href="/contact" rel="noopener noreferrer">
              {" "}
              <span className="mr-1 text-blue-700 hover:underline">
                Contact
              </span>
            </a>
            for help.
          </p>
          <a href="/" rel="noopener noreferrer">
            <button className="my-6 rounded-md bg-black px-4 py-2 font-medium text-white hover:scale-[101%] hover:opacity-75">
              Go to Homepage
            </button>
          </a>
        </div>
      ) : selectedRecord ? (
        <div className="h-screen w-full bg-white p-8 font-medium">
          <div className="mb-8 text-left">
            <h1 className="text-3xl font-bold text-gray-800">Invoice</h1>
            <p className="mt-2 text-base text-gray-500">
              <p className="text-[15px] uppercase">
                #{selectedRecord?.notes?.payment?.id?.replace(/^pay_/, "")}
              </p>
            </p>
            <div className="my-5 border-y"></div>
            <div className="flex flex-col space-y-5">
              {" "}
              <div>
                {" "}
                <h4 className="mb-1 text-[12px] font-bold uppercase">From</h4>
                <p className="text-[15px] font-medium">
                  Workplay Studio Pvt. Ltd. <br /> No. 13-14, SV Complex,
                  Navodaya Nagar, <br /> Kothanur Main Road, JP Nagar 7th Phase,{" "}
                  <br /> Bangalore - 560078, <br />
                  Karnataka, India <br />
                  GSTIN/UIN: 29AADCW0908P1ZT <br />
                  PAN: AADCW0908P
                </p>{" "}
              </div>
              <div className="mt-8">
                <h4 className="mb-1 text-[12px] font-bold uppercase">
                  Bill To
                </h4>
                <p>{selectedRecord.notes?.payment?.notes?.fullName}</p>
                {/* <p>{selectedRecord.notes?.payment?.notes?.address}</p> */}{" "}
                <p>{selectedRecord.notes?.payment?.notes?.email}</p>
                <p>
                  {contact && country
                    ? formatContactNumber(contact, country)
                    : contact}
                </p>
              </div>
              <div className="mt-8">
                <h4 className="mb-1 text-[12px] font-semibold uppercase">
                  Date
                </h4>

                <p>
                  {selectedRecord?.createdAt
                    ? new Date(selectedRecord.createdAt).toLocaleString("en-US")
                    : ""}
                </p>

                <div className="mt-8">
                  <h4 className="mb-1 text-[12px] font-bold uppercase">
                    Payment Method
                  </h4>
                  <p>
                    {selectedRecord.notes?.payment?.method
                      ?.charAt(0)
                      .toUpperCase() +
                      selectedRecord.notes?.payment?.method?.slice(1)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="pb-6">
            <table className="w-full table-auto border-collapse text-left">
              <thead>
                <tr>
                  <th className="border px-2 py-2 text-sm  xl:text-base">
                    Sl. No.
                  </th>
                  <th className="border px-2 py-2 text-sm  xl:text-base">
                    Product
                  </th>
                  <th className="border px-2 py-2 text-sm  xl:text-base">
                    Plan
                  </th>
                  <th className="border px-2 py-2 text-sm  xl:text-base">
                    Service Period
                  </th>
                  <th className="border px-2 py-2 text-sm  xl:text-base">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="max-w-[200px] break-words border px-2 py-2 text-[15px]  xl:text-base">
                    1
                  </td>
                  <td className="max-w-[200px] break-words border px-2 py-2 text-[15px]  xl:text-base">
                    {currentCampaign.name}
                  </td>
                  <td className="border px-2 py-2 text-[15px]  xl:text-base">
                    {selectedRecord.notes?.payment?.notes?.subscriptionName}
                  </td>
                  <td className="flex items-center space-x-1 border px-2 py-2 text-[15px] xl:text-base">
                    <p>
                      {currentCampaign.expiresOn
                        ? new Date(
                            currentCampaign.createdAt,
                          ).toLocaleDateString("en-US")
                        : ""}
                    </p>{" "}
                    <span>—</span>{" "}
                    <p>
                      {currentCampaign.expiresOn
                        ? new Date(
                            currentCampaign.expiresOn,
                          ).toLocaleDateString("en-US")
                        : ""}
                    </p>
                  </td>

                  <td className="border px-2 py-2 text-[15px]  xl:text-base">
                    {formatCurrency(
                      selectedRecord.notes?.payment?.currency,
                      parseFloat(basePrice.toFixed(2)),
                    )}
                  </td>
                </tr>

                {currentCampaign.subscription?.pricing.discount !== 0 ? (
                  <tr>
                    <td
                      className="border-x py-2 pr-5 text-right text-sm font-semibold uppercase  xl:pr-20"
                      colSpan={4}
                    >
                      Discount ({" "}
                      {currentCampaign.subscription?.pricing.discount} %)
                    </td>
                    <td className="border px-2 py-2 text-[15px]  xl:text-base">
                      -{" "}
                      {formatCurrency(
                        selectedRecord.notes?.payment?.currency,
                        parseFloat(discountAmount.toFixed(2)),
                      )}
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td
                    className="border-x py-2 pr-5 text-right text-sm font-semibold uppercase  xl:pr-20"
                    colSpan={4}
                  >
                    Subtotal
                  </td>
                  <td className="border px-2 py-2 text-[15px]  xl:text-base">
                    {formatCurrency(
                      selectedRecord.notes?.payment?.currency,
                      parseFloat(discountedPrice.toFixed(2)),
                    )}
                  </td>
                </tr>
                {selectedRecord.notes?.payment?.currency === "INR" && (
                  <tr>
                    <td
                      className="border-x py-2 pr-5 text-right text-sm font-semibold uppercase  xl:pr-20"
                      colSpan={4}
                    >
                      GST (18%)
                    </td>
                    <td className="border px-2 py-2 text-[15px] xl:text-base">
                      {formatCurrency(
                        selectedRecord.notes?.payment?.currency,
                        parseFloat(gstAmount.toFixed(2)),
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className="border-x border-b py-2 pr-5 text-right font-bold uppercase xl:pr-20 xl:text-[15px]"
                    colSpan={4}
                  >
                    Total
                  </td>
                  <td className="border py-2 pl-2 font-bold xl:text-lg">
                    {formatCurrency(
                      selectedRecord.notes?.payment?.currency,
                      parseFloat(finalPrice.toFixed(2)),
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>{" "}
          <ul className="flex items-center space-x-3 pb-8 text-sm">
            <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
              <li className="underline underline-offset-1 hover:opacity-70">
                Terms of use
              </li>{" "}
            </a>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              <li className="border-x border-black px-4 underline underline-offset-1 hover:opacity-70">
                Privacy Policy
              </li>{" "}
            </a>{" "}
            <a href="/contact" target="_blank" rel="noopener noreferrer">
              <li className="underline underline-offset-1 hover:opacity-70">
                Contact us
              </li>{" "}
            </a>
          </ul>
        </div>
      ) : (
        <div className="flex h-screen w-full items-center justify-center">
          <FadeLoader color={"#444444"} />
        </div>
      )}
    </>
  );
};

export default Invoice;
