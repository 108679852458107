import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar";
import "../styles/Sections/Game.css";
import LandingCTA from "../components/LandingCTA";
import {
  DomainIcon,
  EmbedIcon,
  LinkIcon,
  QRCodeIcon,
} from "../components/SvgIcons";
import PopularGames from "./games/sections/PopularGames";
import CardGrid from "./games/sections/CardGrid";
import { getIconSize } from "../utils/icon";

const integrationOptions = [
  { Icon: EmbedIcon, label: "Embed Code", color: "text-carnation" },
  { Icon: LinkIcon, label: "Copy Link", color: "text-dodgerBlue" },
  { Icon: QRCodeIcon, label: "QR Code", color: "text-oldGold" },
  { Icon: DomainIcon, label: "Custom Domains", color: "text-mantis" },
];

const Game: React.FC = () => {
  const [iconSize, setIconSize] = useState(getIconSize());

  useEffect(() => {
    const handleResize = () => setIconSize(getIconSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const location = useLocation();
  const isBaseRoute = location.pathname === "/game";

  return (
    <div className="overflow-x-hidden bg-codGray">
      <Navbar />
      {isBaseRoute ? (
        <>
          <section className="bg-codGray px-6 py-10 text-white sm:py-10 md:px-12 md:py-16 xl:px-20 xl:pb-24">
            <PopularGames />
            <CardGrid />
          </section>{" "}
          <div
            className="flex w-full items-center justify-center rounded-xl py-12 text-center text-white"
            style={{
              background:
                "linear-gradient(150deg, rgba(124, 124, 124, 0.3) 20%, rgba(68, 68, 68, 0.1) 70%)",
            }}
          >
            <div className="flex w-full max-w-7xl flex-col items-center px-6">
              <p className="w-full px-10 font-epilogue text-2xl font-semibold md:pb-4 md:text-3xl lg:text-4xl">
                See how you can integrate it
              </p>
              <div className="flex w-full items-center justify-center py-10">
                <div className="grid w-full grid-cols-2 gap-10 md:grid-cols-2 lg:grid-cols-4">
                  {integrationOptions.map(({ Icon, label, color }, index) => (
                    <div
                      key={label}
                      className="flex w-full flex-col items-center"
                    >
                      <Icon width={iconSize} height={iconSize} />
                      <p className={`text-base lg:text-xl ${color}`}>{label}</p>
                    </div>
                  ))}
                </div>
              </div>
              <button
                className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-lg"
                onClick={() => (window.location.href = "/integration")}
              >
                Learn More
              </button>
            </div>
          </div>
        </>
      ) : (
        <Outlet />
      )}
      <LandingCTA />
      <Footer />
    </div>
  );
};

export default Game;
