interface FooterLinkProps {
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const FooterLink: React.FC<FooterLinkProps> = ({
  to = "#",
  children,
  onClick,
}) => (
  <li className="cursor-pointer hover:opacity-80">
    {to ? (
      <a href={to} onClick={onClick} className="text-inherit">
        {children}
      </a>
    ) : (
      <span onClick={onClick} className="text-inherit">
        {children}
      </span>
    )}
  </li>
);

export default FooterLink;
