import { useMemo } from "react";
import { PlanDetails } from "../types/planTypes";

export const usePricing = (plan: PlanDetails | null, country: string) => {
  const pricing = plan?.pricing;

  const pricingDetails = useMemo(() => {
    if (!pricing) return null;

    const gst = 18;
    const discountPercentage = pricing.discount || 0;

    // Default values
    let originalPrice = 0;
    let amountAfterDiscount = 0;
    let discountAmount = 0;
    let gstAmount = 0;
    let finalPrice = 0;
    let saveAmount = 0;
    let validForInDays = pricing.validForInDays;

    // Determine pricing based on country
    const countryToUse = country === "IN" ? "IN" : "US"; // Use IN for India, else US for all other countries
    const price = pricing[countryToUse];

    if (countryToUse === "US") {
      const originalPriceUSD = price;
      const discountUSD = originalPriceUSD * (discountPercentage / 100);
      amountAfterDiscount = originalPriceUSD - discountUSD;
      saveAmount = originalPriceUSD - amountAfterDiscount;
      originalPrice = originalPriceUSD;
      discountAmount = saveAmount;
      finalPrice = amountAfterDiscount;
    }

    if (countryToUse === "IN") {
      const finalPriceINR = price - price * (discountPercentage / 100);
      originalPrice =
        finalPriceINR / ((1 - discountPercentage / 100) * (1 + gst / 100));
      amountAfterDiscount =
        originalPrice - originalPrice * (discountPercentage / 100);
      gstAmount = (amountAfterDiscount * gst) / 100;
      saveAmount = originalPrice - amountAfterDiscount;
      finalPrice = finalPriceINR;
      discountAmount = saveAmount;
    }

    const formatCurrency = (amount: number, currency: string) => {
      const formattedAmount = amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (currency === "IN") {
        return `₹${formattedAmount}`;
      } else if (currency === "US") {
        return `$${formattedAmount}`;
      }

      return formattedAmount;
    };

    return {
      originalPrice: formatCurrency(originalPrice, countryToUse),
      amountAfterDiscount: formatCurrency(amountAfterDiscount, countryToUse),
      gst:
        countryToUse === "IN" ? formatCurrency(gstAmount, countryToUse) : null,
      total: formatCurrency(finalPrice, countryToUse),
      validForInDays,
      discountAmount: formatCurrency(discountAmount, countryToUse),
      discountPercentage,
    };
  }, [pricing, country]);

  return pricingDetails;
};
