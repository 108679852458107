import { faClose, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginContext } from "../Context/LoginContext/LoginContext";
import { userContext } from "../Context/LoginContext/UserContext";
import LazyLoadImages from "../LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

const UserAvatar: React.FC = () => {
  const navigate = useNavigate();
  const contextValue = useContext(LoginContext);
  const { user } = useContext(userContext);
  const [profile, setProfile] = useState(false);
  const additionalMenuItems = [
    // {
    //   name: " update Profile",
    //   path: "/profile",
    //   icon: faUser,
    // },
    // {
    //   name: "Notifications",
    //   path: "/notifications",
    //   icon: faBell,
    // },
    // {
    //   name: "Settings",
    //   path: "/settings",
    //   icon: faGear,
    // },
    // {
    //   name: "Feedback",
    //   path: "/feedback",
    //   icon: faComment,
    // },
    // {
    //   name: "Support",
    //   path: "/support",
    //   icon: faHeadphones,
    // },
    {
      name: "Logout",
      path: "/logout",
      icon: faSignOutAlt,
    },
  ];

  const handleProfileNavigate = () => {
    navigate("/dashboard/profile");
  };

  return (
    <div className={`z-100 flex flex-row items-center justify-between pr-2`}>
      <div className="cursor-pointer">
        <LazyLoadImages
          onClick={() => setProfile(!profile)}
          style={{ height: "40px", width: "auto" }}
          src={
            user.img.link ||
            localStorage.getItem("ImgLink") ||
            `${REACT_APP_S3_URL}/uploads/profile/default-profile.webp`
          }
          className="rounded-full"
          alt="profile"
          title="Open profile"
        />
      </div>
      {profile && (
        <div className="bg-transperent absolute right-[80px] top-28 z-40 flex h-auto w-[280px] flex-col rounded-xl bg-mineShaft p-8">
          <div className="flex flex-col items-center gap-x-2 rounded-l-xl rounded-r-xl text-white">
            <LazyLoadImages
              style={{ height: "48px" }}
              src={
                user.img.link ||
                localStorage.getItem("ImgLink") ||
                `${REACT_APP_S3_URL}/uploads/profile/default-profile.webp`
              }
              className="my-2 rounded-full"
              alt="profile"
              title="Open profile"
              onClick={handleProfileNavigate}
            />
            <div className="mb-5 mt-3">
              <h2
                className="cursor-pointer text-center text-lg"
                title="Open profile"
                onClick={handleProfileNavigate}
              >
                {" "}
                {user.fname + " " + user.lname}
              </h2>
              <p className="my-1 text-sm text-stone-200">{user.email}</p>
            </div>
          </div>
          <div className="text-stone-200">
            <Link to="/dashboard/profile">
              <p className="mb-2 hover:opacity-55">Profile</p>
            </Link>
            <Link to="/dashboard/campaigns">
              <p className="hover:opacity-55">Dashboard</p>
            </Link>
          </div>
          <div className="my-4 w-auto border-b-2 border-gray-400 "></div>
          <button
            className="absolute right-4 top-2"
            onClick={() => setProfile(!profile)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          <ul className="flex flex-col items-start justify-start gap-y-4">
            {additionalMenuItems.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  if (item.name === "Logout") {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                    contextValue?.logout();
                    sessionStorage.removeItem("ImgLink");
                    sessionStorage.removeItem("fname");
                    sessionStorage.removeItem("lname");
                    sessionStorage.removeItem("collapse");
                    sessionStorage.removeItem("email");
                  } else {
                    window.location.href = `${item.path}`;
                  }
                }}
                className="hover:opacity-40"
              >
                <FontAwesomeIcon className="mr-2" icon={item.icon} />
                {item.name}
              </button>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
