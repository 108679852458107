import { useNavigate } from "react-router-dom";
import "../styles/Sections/Faq.css";

const { REACT_APP_S3_URL } = process.env;

const FaqCTA = () => {
  const navigate = useNavigate();
  const handleContact = () => {
    navigate("/contact");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="faqBg my-12 items-center justify-between rounded-[20px] px-5 py-6 sm:p-8 md:my-20 lg:flex xl:my-28 xl:p-10">
      <h3 className="block py-1 text-center font-epilogue text-xl font-semibold text-mercury md:hidden lg:text-3xl">
        Still Have Questions?
      </h3>
      <div className="flex items-center space-x-5 md:space-x-6 xl:space-x-8">
        <img
          src={`${REACT_APP_S3_URL}/website-assets/FaqPage/Puzzled.webp`}
          alt="Puzzled"
          className="h-14 md:h-24"
        />
        <div className="">
          <h3 className="hidden font-epilogue text-xl font-semibold text-mercury md:block md:text-2xl xl:text-3xl">
            Still Have Questions?
          </h3>
          <p className="mt-2 font-plusJakartaSans text-mercury md:text-lg xl:text-xl">
            Cant find the answer you're looking for? Please Chat to our friendly
            team.
          </p>
        </div>
      </div>
      <button
        className="headerBtn relative mt-6 w-full rounded-full bg-tanHide px-4 py-2.5 font-encodeSansExpanded font-semibold text-white shadow-navBtn hover:bg-brandyPunch md:text-lg lg:mt-0 lg:w-44 xl:w-48 xl:text-xl"
        onClick={handleContact}
      >
        Get in Touch
      </button>
    </div>
  );
};

export default FaqCTA;
