import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { userContext } from "../Context/LoginContext/UserContext";
import PhoneInput from "react-phone-input-2";
import LazyLoadImages from "../LazyLoadImages";
import { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import countriesData from "../../assets/countriesData.json";
import "../../styles/Profile.css";
import { useNavigate } from "react-router-dom";
import PaymentHistoryTable from "../PaymentHistory";

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

interface CountryInfo {
  country: string;
  nationality: string;
}

interface Field {
  name: string;
  type: string;
  value: string;
}

interface ProfileSection {
  name: string;
  fields?: Field[];
  desc: string;
  button?: string;
}

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = ({}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("xSudTzMYmd");
  const data = localStorage.getItem("Profile");
  const [payments, setPayments] = useState<any[]>([]);

  const { user } = useContext(userContext);

  const profileFields: ProfileSection[] = [
    {
      name: "Edit profile",
      fields: [
        { name: "First name", type: "text", value: user.fname },
        { name: "Last name", type: "text", value: user.lname },
        { name: "Phone number", type: "number", value: user.phone },
        { name: "Location", type: "text", value: user.location },
        { name: "Bio", type: "textarea", value: user.bio },
      ],
      desc: "Set up your Workplay presence",
      button: "Save profile",
    },
    {
      name: "Social profiles",
      fields: [
        { name: "LinkedIn", type: "url", value: user.socials?.linkedin },
        { name: "Github", type: "url", value: user.socials?.github },
        { name: "Facebook", type: "url", value: user.socials?.facebook },
        { name: "Twitter", type: "url", value: user.socials?.twitter },
        { name: "Instagram", type: "url", value: user.socials?.instagram },
      ],
      desc: "Add social links to your profile",
      button: "Update social profiles",
    },
    {
      name: "Billing",
      desc: "Manage billing information and view receipts",
    },
    // {
    //   name: "Password",
    //   fields: [
    //     { name: "Old Password", type: "password", value: "" },
    //     { name: "New Password", type: "password", value: "" },
    //   ],
    //   button: "Update password",
    // },
    {
      name: "Company",
      fields: [
        { name: "Company name", type: "text", value: user.company?.name },
        { name: "Company URL", type: "url", value: user.company?.url },
      ],
      desc: "Your current company",
      button: "Save",
    },
  ];

  const [selectedSection, setSelectedSection] = useState<string | null>(
    localStorage.getItem("section") || "Edit profile",
  );

  const [formData, setFormData] = useState<Record<string, string>>(() => {
    const savedData = localStorage.getItem("formData");
    return savedData
      ? JSON.parse(savedData)
      : {
          "First name": user.fname,
          "Last name": user.lname,
          isdCode: user.isdcode,
          "Phone number": user.phone,
          Bio: user.bio,
          Location: user.location,
          socials: user.socials,
          company: user.company,
        };
  });
  const [showtoast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");

  const handleSectionClick = (sectionName: string) => {
    localStorage.setItem("section", sectionName);
    setSelectedSection(sectionName);
  };

  const handleChange = (fieldName: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateChanges();
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;

    const formData = new FormData();

    formData.append("file", file);

    axios
      .put(`${REACT_APP_API_URL}/auth/profile-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setShowToast(true);
        setMessage(
          "Image uploaded successfully. It might take some time to see the changes.",
        );
        setTimeout(() => {
          setShowToast(false);
          window.location.reload();
          setMessage("");
        }, 3000);
      })
      .catch((error) => {
        console.error("Failed to upload image:", error);
      });
  };

  // PhoneNumber validation
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Find the country info based on the country name
    const countryInfo: CountryInfo | undefined = countriesData.find(
      (info: CountryInfo) => info.country === country.name,
    );

    if (!countryInfo) {
      console.error(`Country info not found for name: ${country.name}`);
      return;
    }

    const dialCode = country.dialCode;

    setFormData((prevState) => ({
      ...prevState,
      isdCode: country.dialCode,
      "Phone number": value.replace(dialCode, ""),
    }));
  };

  const updateChanges = async () => {
    const dataToSend: any = {
      firstName: formData["First name"] || user.fname,
      lastName: formData["Last name"] || user.lname,
      ...(formData["isdCode"] && {
        isdCode: "+" + formData["isdCode"] || user.isdcode,
      }),
      ...(formData["Phone number"] && {
        phoneNumber: formData["Phone number"] || user.phone,
      }),
      ...(formData["Bio"] && {
        bio: formData["Bio"] || user.bio,
      }),
      ...(formData["Location"] && {
        location: formData["Location"] || user.location,
      }),
    };

    // Check if socials exist and if any field is filled
    if (
      formData["Twitter"] ||
      formData["Facebook"] ||
      formData["Github"] ||
      formData["Instagram"] ||
      formData["LinkedIn"]
    ) {
      dataToSend.socials = {
        ...(formData["Twitter"] && {
          twitter: formData["Twitter"] || user.socials.twitter,
        }),
        ...(formData["Facebook"] && {
          facebook: formData["Facebook"] || user.socials.facebook,
        }),
        ...(formData["Github"] && {
          github: formData["Github"] || user.socials.github,
        }),
        ...(formData["Instagram"] && {
          instagram: formData["Instagram"] || user.socials.instagram,
        }),
        ...(formData["LinkedIn"] && {
          linkedin: formData["LinkedIn"] || user.socials.linkedin,
        }),
      };
    }

    // Check if company exist and if any field is filled
    if (formData["Company name"] || formData["Company URL"]) {
      dataToSend.company = {
        ...(formData["Company name"] && {
          name: formData["Company name"] || user.company.name,
        }),
        ...(formData["Company URL"] && {
          url: formData["Company URL"] || user.company.url,
        }),
      };
    }

    try {
      const response = await axios.patch(
        `${REACT_APP_API_URL}/auth/profile`,
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include bearer token in the Authorization header
          },
        },
      );
      localStorage.setItem("formData", JSON.stringify(formData));

      if (response.status === 200) {
        setShowToast(true);
        setMessage("Your profile has been updated");
        setTimeout(() => {
          setShowToast(false);
          setMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setShowToast(true);
      setMessage(error.data.message);
      setTimeout(() => {
        setShowToast(false);
        setMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    if (data && !localStorage.getItem("formData")) {
      const profile = JSON.parse(data);
      localStorage.setItem(
        "formData",
        JSON.stringify({
          "First name": profile.firstName,
          "Last name": profile.lastName,
          isdCode: profile.isdCode,
          "Phone number": profile.phoneNumber,
          Bio: profile.bio,
          Location: profile.location,
          socials: profile.socials || user.socials,
          company: profile.company || user.company,
        }),
      );
    }
  }, []);

  const handleAccountDelete = () => {
    navigate("/profile/delete");
  };

  return (
    <div className="m-0 @container">
      {showtoast && (
        <div className="m-0 h-10 border-2 border-black bg-black p-2 text-center text-sm text-white">
          {message}
        </div>
      )}
      <div className="flex flex-col items-center justify-between py-7">
        <div className="items-start justify-between lg:flex lg:flex-col lg:gap-y-10">
          <div className="items-center justify-start gap-x-4 rounded-lg lg:flex lg:flex-row lg:gap-x-8">
            <LazyLoadImages
              className="xxsm:h-20 xxsm:w-20 m-auto h-[80px] w-[80px] rounded-full border border-[#b3b3b3] sm:h-28 sm:w-28 lg:ml-0"
              src={
                user.img.link ||
                localStorage.getItem("ImgLink") ||
                `${REACT_APP_S3_URL}/uploads/profile/default-profile.webp`
              }
              alt="workplay-user-profile"
            />
            <div className="flex flex-col gap-y-2 text-center lg:text-left">
              <div className="items-center lg:flex lg:text-xl">
                <p className="my-3 font-poppins font-medium lg:my-0 lg:text-2xl">
                  {sessionStorage.getItem("fname") +
                    " " +
                    sessionStorage.getItem("lname")}
                </p>
                <span className="mx-2 hidden lg:flex">/</span>
                <p className="font-poppins text-stone-900">{selectedSection}</p>
              </div>

              {profileFields.find((section) => section.name === selectedSection)
                ?.desc && (
                <p className="text-wrap font-poppins text-sm text-stone-900 lg:text-base">
                  {
                    profileFields.find(
                      (section) => section.name === selectedSection,
                    )?.desc
                  }
                </p>
              )}

              <input
                type="file"
                id="profileImageInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <button
                onClick={() =>
                  document.getElementById("profileImageInput")?.click()
                }
                className="m-auto mb-5 mt-2 w-fit rounded-md border-2 border-black bg-black px-4 py-2 font-poppins text-sm font-medium text-white lg:mb-0 lg:ml-0 lg:mt-0 lg:w-[11rem] lg:p-2"
              >
                upload new picture
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-x-36 @[46rem]:flex-row xl:gap-x-72 3xl:gap-x-96">
            <div className="">
              <ul className="flex w-auto cursor-pointer flex-col">
                {profileFields.map((section) => (
                  <li
                    className={`${selectedSection === section.name ? "font-semibold" : "font-normal hover:opacity-45"} my-1 font-poppins text-[15px] md:text-[17px]`}
                    key={section.name}
                    onClick={() => handleSectionClick(section.name)}
                  >
                    {section.name}
                  </li>
                ))}
              </ul>
              <div className="my-2 w-auto border-b-[2px] border-gray-300 lg:my-3"></div>
              <p
                className="mb-4 cursor-pointer font-poppins text-[15px] text-red-500 md:text-[17px]"
                onClick={handleAccountDelete}
              >
                Delete account
              </p>
            </div>
            <div className="w-auto @sm:w-[20rem] @md:w-[26rem] @lg:w-[30rem] @xl:w-[28rem]">
              {selectedSection && (
                <form
                  onSubmit={handleSubmit}
                  className="flex-col items-center justify-center gap-2"
                >
                  {profileFields
                    .find((section) => section.name === selectedSection)
                    ?.fields?.map((field, idx) => (
                      <div key={idx} className="">
                        <label
                          htmlFor={field.name}
                          className="font-poppins text-[15px] font-medium lg:text-base"
                        >
                          {field.name}
                        </label>
                        {field.name !== "Phone number" ? (
                          field.name !== "Bio" ? (
                            <input
                              key={field.name}
                              className={`${field.name !== "Bio" ? "mb-2 h-[44px]" : " h-20"} mt-2 w-full rounded-md p-3 font-poppins text-[15px] font-normal`}
                              type={field.type}
                              defaultValue={formData[field.name] || field.value}
                              onChange={(e) =>
                                handleChange(field.name, e.target.value)
                              }
                              placeholder={field.name}
                            />
                          ) : (
                            <textarea
                              key={idx}
                              className="mt-2 h-16 w-full rounded-md p-3 text-[15px]"
                              defaultValue={formData[field.name] || field.value}
                              onChange={(e) =>
                                handleChange(field.name, e.target.value)
                              }
                              placeholder={field.name}
                            ></textarea>
                          )
                        ) : (
                          <PhoneInput
                            country={"in"}
                            onChange={handlePhoneChange}
                            value={
                              `${user.isdcode + user.phone}` ||
                              `${formData["isdCode"]} +${formData["Phone number"]}`
                            }
                          />
                        )}
                      </div>
                    ))}
                  {profileFields.find(
                    (section) => section.name === selectedSection,
                  )?.button && (
                    <button
                      type="submit"
                      className="mt-4 w-full rounded-md bg-black p-3 text-base font-medium text-white"
                    >
                      {
                        profileFields.find(
                          (section) => section.name === selectedSection,
                        )?.button
                      }
                    </button>
                  )}
                </form>
              )}
              <div className="w-auto md:-ml-10 xl:-ml-52">
                {selectedSection === "Billing" && (
                  <PaymentHistoryTable payments={payments} /> // Conditionally render PaymentHistoryTable
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
