import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { projectStatus } from "../../services/projectStatusApi";
import ActivationModal from "./ActivationModal";
import { toast } from "react-toastify";

interface SubscriptionPricing {
  IN: number;
  US: number;
  discount: number;
  isFree: boolean;
  validForInDays: number;
}

interface CampaignSubscription {
  _id: string;
  title: string;
  pricing: SubscriptionPricing;
  description: string;
  features: string[];
}

interface Campaign {
  _id: string;
  userId: string;
  name: string;
  startDate: string;
  endDate: string;
  game: string;
  createdAt: string;
  updatedAt: string;
  gameId?: string | null;
  isPaid?: boolean;
  isTrial?: boolean;
  disabled?: boolean; // This determines if the project is active or inactive
  subscription?: CampaignSubscription;
  subscriptionId?: string;
  latestPaymentOrderId?: string;
  expiresOn?: string;
}

interface ManageProjectModalProps {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  campaignDetails: Campaign;
}

const ManageProjectModal: React.FC<ManageProjectModalProps> = ({
  close,
  campaignDetails,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ccid = searchParams.get("ccid");

  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isDeactivateActiveModalOpen, setIsDeactivateActiveModalOpen] =
    useState(false);

  const token = localStorage.getItem("xSudTzMYmd");

  const calculateDaysLeft = (expiryDate: string): number => {
    const currentDate = new Date();
    const expiry = new Date(expiryDate);
    return Math.floor(
      (expiry.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
    );
  };

  const daysLeft = campaignDetails?.expiresOn
    ? calculateDaysLeft(campaignDetails.expiresOn)
    : null;

  // Check if the "Renew Now" button should be displayed
  const shouldDisplayRenewButton =
    daysLeft !== null && daysLeft <= 4 && daysLeft >= 0;

  const handleViewPlans = () => {
    navigate(`/pricing?ccid=${ccid}`);
    setTimeout(() => {
      const element = document.getElementById("plans");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    // Check if the campaign is disabled or not and update the status accordingly
    if (campaignDetails?.disabled === false) {
      setIsActive(true); // If disabled is false, the project is active
    } else {
      setIsActive(false); // If disabled is true, the project is inactive
    }
  }, [campaignDetails?.disabled]);

  const handleToggle = () => {
    if (isActive) {
      setIsDeactivateActiveModalOpen(true); // Open deactivation modal if the toggle is active
    } else {
      setIsActivateModalOpen(true); // Open activation modal if the toggle is inactive
    }
  };

  // Handle confirmation in the modal
  const handleConfirmAction = async (status: boolean) => {
    if (!token) {
      console.log("token not available");
      return;
    }

    try {
      // Send the opposite of the current status to activate or deactivate the campaign
      const response = await projectStatus(campaignDetails?._id, token, {
        status: !status, // Flip the status to activate/deactivate
      });
      console.log(response);

      setIsActive(!status); // Toggle the active status

      setIsActivateModalOpen(false);
      setIsDeactivateActiveModalOpen(false);
      close(false);
      window.location.reload();
      setTimeout(() => {
        toast.success("Project Status updated!");
      }, 500);
    } catch (error) {
      console.error("Error updating project status:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-40 flex min-h-full min-w-full flex-col items-center overflow-y-auto overflow-x-hidden transition">
      <div className="z-10 flex h-screen w-screen bg-transparent backdrop-blur"></div>
      <div className="absolute left-1/2 top-1/2 z-20 flex w-[90%] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center gap-y-4 rounded-lg border-[2px] border-[#d7dadf] bg-[#ffffff] bg-opacity-95 pb-4 sm:w-auto sm:min-w-[500px]">
        <div className="flex w-full flex-row justify-between rounded-t-md border-b-[1px] border-[#bbbdbe] bg-[#fafaf9] px-6 pb-3 pt-3">
          <p className="font-poppins text-xl font-semibold text-black">
            Manage Project - {campaignDetails.name}
          </p>
          <X
            className="cursor-pointer text-red-600 hover:scale-110"
            size={24}
            onClick={() => {
              close(false);
            }}
          />
        </div>
        <div className="w-full px-6 text-left">
          <div className="my-2 rounded-md bg-[#f7f5f5] p-4">
            <h3 className="font-poppins text-lg font-semibold">
              {campaignDetails.subscription?.title}
            </h3>
            <div className="flex items-center justify-between">
              <p className="my-1 w-full text-start text-[15px] font-medium text-red-600">
                Expires on{" "}
                {campaignDetails?.expiresOn
                  ? new Date(campaignDetails.expiresOn).toLocaleDateString(
                      "en-IN",
                      {
                        timeZone: "Asia/Kolkata",
                        weekday: undefined,
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      },
                    )
                  : "N/A"}
              </p>
              {shouldDisplayRenewButton && (
                <button
                  className="-mt-10 flex w-36 cursor-pointer items-center justify-center rounded-full bg-black px-3 py-1.5 font-poppins text-sm font-medium text-white hover:scale-[102%]"
                  onClick={handleViewPlans}
                >
                  Renew Now
                </button>
              )}
            </div>
          </div>

          <div className="my-2 flex items-center justify-between rounded-md bg-[#f7f5f5] p-4">
            <h3 className="font-poppins text-lg font-semibold">Status</h3>{" "}
            {campaignDetails.gameId ? (
              <div className="flex items-center space-x-2">
                <label className="relative inline-block h-7 w-12">
                  <input
                    type="checkbox"
                    checked={isActive}
                    onChange={handleToggle}
                    className="peer h-0 w-0 opacity-0"
                  />
                  <span
                    className={`absolute inset-0 cursor-pointer rounded-full transition-all duration-300 
           ${isActive ? "bg-green-500" : "bg-gray-300"} peer-checked:bg-green-500`}
                  ></span>
                  <span
                    className={`absolute left-1 top-1 h-5 w-5 rounded-full bg-white transition-all duration-300
           ${isActive ? "translate-x-5" : ""}`}
                  ></span>
                </label>
                <p className="font-medium">
                  {isActive ? "Active" : "Inactive"}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>{" "}
      {isActivateModalOpen && (
        <ActivationModal
          close={setIsActivateModalOpen}
          title="Confirm Activation"
          message="Are you sure you want to activate this project? You can deactivate it anytime."
          status={true}
          onConfirm={handleConfirmAction}
        />
      )}
      {isDeactivateActiveModalOpen && (
        <ActivationModal
          close={setIsDeactivateActiveModalOpen}
          title="Confirm Deactivation"
          message="Are you sure you want to deactivate this project? You can reactivate it at any time."
          status={false}
          onConfirm={handleConfirmAction}
        />
      )}
    </div>
  );
};

export default ManageProjectModal;
