import React from "react";
import "../styles/Sections/TrustedPartners.css";

interface Partner {
  name: string;
  logo: string;
}

const { REACT_APP_S3_URL } = process.env;

const partnerData: Partner[] = [
  {
    name: "Tata Tea",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/tataTea.webp`,
  },
  {
    name: "Intuit Benefits",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/intuitBenefits.webp`,
  },
  {
    name: "Microsoft",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/microsoft.webp`,
  },
  {
    name: "Lenovo",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/lenovo.webp`,
  },
  {
    name: "Flipkart",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/flipkart.webp`,
  },
  {
    name: "Cmf",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/cmf.webp`,
  },

  {
    name: "Acme Experience",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/acme.webp`,
  },
  {
    name: "Saffola",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/saffola.webp`,
  },
  {
    name: "Berrybox Benefits",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/berrybox-benefits.webp`,
  },
  {
    name: "Uniphore",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/uniphore.webp`,
  },
  {
    name: "IndiRides",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/indiRides.webp`,
  },
  {
    name: "Optimum",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/optimum.webp`,
  },
  {
    name: "Three Wheels United",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/threeWheelsUnited.webp`,
  },
  {
    name: "Phoenix Live",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/phoenixLive.webp`,
  },
  {
    name: "Dharma Endevours",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/dharma.webp`,
  },
  {
    name: "Wiitronics",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/WIITronics.webp`,
  },
  {
    name: "Thar Tech",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/tharTech.webp`,
  },
  {
    name: "PI India Org",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/piIndiaOrg.webp`,
  },
  {
    name: "Progressive Foundation",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/progressiveFoundation.webp`,
  },
  {
    name: "Vilz",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/vilz.webp`,
  },
  {
    name: "Jute & co.",
    logo: `${REACT_APP_S3_URL}/website-assets/landingPage/trusted-partners/jute&co.webp`,
  },
];

const TrustedPartners: React.FC = () => {
  return (
    <section className="overflow-hidden bg-codGray px-6 py-10 sm:px-10 md:px-12 xl:px-20 xl:py-2">
      <div className="animate-scroll relative flex w-[200%] space-x-10 xl:space-x-16">
        {partnerData.concat(partnerData).map((partner, index) => (
          <img
            key={index}
            src={partner.logo}
            alt={partner.name}
            className="h-5 md:h-7 xl:h-[30px]"
          />
        ))}
      </div>
    </section>
  );
};

export default TrustedPartners;
