import { useEffect, useState } from "react";
import { Plus, X } from "react-feather";

interface Section {
  id: string;
  title: string;
  description: string;
  imgSrc: string;
}

interface HowToCustomizeProps {
  sections: Section[];
}

const HowToCustomize = ({ sections }: HowToCustomizeProps) => {
  const [expandedSection, setExpandedSection] = useState<string>(
    sections[0]?.id || "",
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setExpandedSection(sections[0]?.id || "");
      } else {
        setExpandedSection("");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sections]);

  const handleToggle = (sectionId: string) => {
    if (window.innerWidth >= 1024) {
      setExpandedSection((prevId) => {
        if (prevId === sectionId) {
          const currentIndex = sections.findIndex(
            (section) => section.id === sectionId,
          );
          const nextIndex = (currentIndex + 1) % sections.length;
          return sections[nextIndex].id;
        }
        return sectionId;
      });
    } else {
      setExpandedSection((prevId) => (prevId === sectionId ? "" : sectionId));
    }
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 z-0">
        <img
          src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/memory/ellipseYellow.webp`}
          alt="Background"
          className="h-[120%]"
        />
      </div>
      <div className="z-100 px-6 pb-10 sm:p-10 md:px-12 md:pb-16 xl:px-20 xl:pt-24">
        <h2 className="mt-10 text-center font-epilogue text-2xl font-semibold text-mercury md:text-3xl xl:text-5xl">
          How You Can Customize It
        </h2>
        <div className="z-100 mt-10 flex w-full items-center justify-between lg:space-x-32 xl:mt-20 xl:space-x-44">
          <div className="hidden lg:block lg:w-[30%]">
            {sections.map(
              ({ id, imgSrc }) =>
                expandedSection === id && (
                  <img key={id} src={imgSrc} alt={id} />
                ),
            )}
          </div>
          <div className="z-100 w-full space-y-6 lg:w-[70%] xl:space-y-8">
            {sections.map(({ id, title, description, imgSrc }) => (
              <Accordion
                key={id}
                section={{ id, title, description, imgSrc }}
                isOpen={expandedSection === id}
                onToggle={handleToggle}
                expandedSection={expandedSection}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface AccordionProps {
  section: Section;
  isOpen: boolean;
  onToggle: (id: string) => void;
  expandedSection: string;
}

const Accordion = ({
  section,
  isOpen,
  onToggle,
  expandedSection,
}: AccordionProps) => {
  const { id, title, description, imgSrc } = section;

  return (
    <div
      className="flex cursor-pointer items-center justify-between"
      onClick={() => onToggle(id)}
    >
      <div className="w-1/2 flex-1">
        <div className="flex items-center justify-between">
          <h3 className="font-epilogue text-lg font-medium text-mercury md:text-xl xl:text-2xl">
            {title}
          </h3>
          <div className="flex items-center">
            {isOpen ? (
              <X className="h-5 text-mercury lg:h-10" />
            ) : (
              <Plus className="h-5 text-mercury lg:h-10" />
            )}
          </div>
        </div>
        {isOpen && (
          <div className="flex w-full items-center space-x-6 lg:block">
            <div className="block w-[30%] lg:hidden">
              {expandedSection === id && <img key={id} src={imgSrc} alt={id} />}
            </div>
            <div className="mr-2 w-[70%] lg:mr-10 lg:w-auto">
              <p className="mb-6 h-auto pb-2 pt-6 text-base text-silverChalice lg:h-36 lg:pb-0 xl:text-lg">
                {description}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HowToCustomize;
