import React, { useState, useEffect } from "react";

type Game = {
  title: string;
  description: string;
  image: string;
  playButtonLink: string;
  customizeButtonLink?: string;
};

const games: Game[] = [
  {
    title: "Quiz",
    description: "Be ready to think!",
    image: `${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/quiz.webp`,
    playButtonLink: "https://quiz.workplay.digital/",
    customizeButtonLink: `${process.env.REACT_APP_CUSTOM_QUIZ_URL}`,
  },
  {
    title: "Spin The Wheel",
    description: "Try your luck and see what you win!",
    image: `${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/spinTheWheel.webp`,
    playButtonLink: "https://find-the-object.workplay.digital/",
    customizeButtonLink: `${process.env.REACT_APP_CUSTOM_SPIN_URL}`,
  },
  {
    title: "Memory Card",
    description: "Match it, remember it, win it!",
    image: `${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/memoryCard.webp`,
    playButtonLink: "https://memory-game.workplay.digital/",
  },
  {
    title: "Jigsaw Puzzle",
    description: "Put it all together, solve the mystery!",
    image: `${process.env.REACT_APP_S3_URL}/website-assets/Games/gameLibrary/jigsawPuzzle.webp`,
    playButtonLink: "https://jigsaw-puzzle.workplay.digital/",
  },
];

const PopularGames = () => {
  const [currentGameIndex, setCurrentGameIndex] = useState(0);

  // Handle the click to move to that specific game
  const handleDotClick = (index: number) => {
    setCurrentGameIndex(index);
  };

  // Auto-scroll every 7 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGameIndex((prevIndex) =>
        prevIndex === games.length - 1 ? 0 : prevIndex + 1,
      );
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  const currentGame = games[currentGameIndex];

  return (
    <div className="relative flex w-full justify-center">
      <div
        className="flex h-auto w-full justify-between overflow-hidden rounded-2xl"
        style={{
          background:
            "linear-gradient(320deg, rgba(37, 37, 37, 0.10) 0%, #151515 100%)",
        }}
      >
        <div className="flex flex-col px-4 pb-10 pt-6 sm:px-10 sm:py-10 lg:w-1/2">
          <img
            src={currentGame.image}
            alt={currentGame.title}
            className="absolute right-0 top-0 z-20 block w-full rounded-2xl md:hidden"
          />{" "}
          <p className="z-50 w-[70%] py-4 font-plusJakartaSans text-base text-silverChalice sm:w-full md:text-lg lg:text-xl">
            {currentGame.description}
          </p>
          <h3 className="z-50 font-epilogue text-3xl font-bold sm:text-4xl md:text-5xl xl:text-6xl">
            {currentGame.title}
          </h3>
          <div className="z-50 flex w-fit items-center gap-x-3 pb-10 sm:gap-x-6 sm:py-10">
            <a
              href={currentGame.playButtonLink}
              target="_blank"
              rel="noopener noreferrer"
              className="headerBtn relative mt-8 w-full rounded-full bg-tanHide px-6 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch  sm:px-8 sm:text-lg md:mt-4 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-lg"
            >
              Play Now
            </a>
            {currentGame.customizeButtonLink && (
              <a
                href={currentGame.customizeButtonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="headerBtn relative mt-8 w-full rounded-full bg-merlin px-6 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtnLogin hover:bg-tuatara  sm:px-8 sm:text-lg md:mt-4 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-lg"
              >
                Customize
              </a>
            )}
          </div>
        </div>
        <div>
          <img
            src={currentGame.image}
            alt={currentGame.title}
            className="hidden h-full rounded-2xl md:block"
          />
        </div>
      </div>

      {/* Navigation */}
      <div className="absolute bottom-8 left-1/2 flex -translate-x-1/2 transform gap-x-2">
        {games.map((_, index) => (
          <div
            key={index}
            onClick={() => handleDotClick(index)}
            className={`h-1.5 w-8 cursor-pointer rounded-lg transition-all duration-200 sm:w-14 ${
              index === currentGameIndex ? "bg-[#F8A359]" : "bg-[#3C3631]"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default PopularGames;
