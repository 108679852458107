import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useUnloadPrompt = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Show custom message when trying to reload or navigate away
      event.preventDefault();
      event.returnValue = ""; // Chrome requires this to display the prompt
    };

    const handlePopState = (event: PopStateEvent) => {
      // Prevent backward navigation (e.g., back button)
      event.preventDefault();
      event.stopImmediatePropagation();
      window.history.pushState(null, "", window.location.href); // Stay on the current page
      navigate("/dashboard/campaigns");
    };

    // Add the event listener to block page reloads
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Block back and forward navigation
    window.addEventListener("popstate", handlePopState);

    // Ensure that page navigation is always blocked if the user tries to go back/forward
    window.history.pushState(null, "", window.location.href); // Push a new state to prevent "back" navigation

    return () => {
      // Cleanup listeners
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
};

export default useUnloadPrompt;
