import LandingCTA from "../../components/LandingCTA";
import Strategy from "./sections/Strategy";
import UseCase from "./sections/UseCase";

const useCaseLabels = [
  { label: "Event Engagement", id: "eventEngagement" },
  { label: "Cognitive Training", id: "cognitiveTraining" },
  { label: "Team Building", id: "teamBuilding" },
  { label: "Thematic Branding", id: "thematicBranding" },
  { label: "Reward-Based Challenges", id: "rewardBasedChallenges" },
];

const useCaseDetails = [
  {
    id: "eventEngagement",
    title: "Event Engagement",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/eventEngagement.webp`,
    content: [
      "Add an interactive twist to your events by including Spot the Difference. Participants will enjoy the challenge, boosting engagement and making your event more memorable.",
    ],
  },
  {
    id: "cognitiveTraining",
    title: "Cognitive Training",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/cognitiveTraining.webp`,
    content: [
      "Enhance players’ attention to detail, concentration, and visual perception with this mentally stimulating activity. Suitable for all ages, it offers an enjoyable way to encourage cognitive growth.",
    ],
  },
  {
    id: "teamBuilding",
    title: "Team Building",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/teamBuilding.webp`,
    content: [
      "Transform the game into a collaborative experience by challenging teams to find the differences together. It’s a fun way to foster communication, teamwork, and a sense of accomplishment.",
    ],
  },
  {
    id: "thematicBranding",
    title: "Thematic Branding",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/themeticBranding.webp`,
    content: [
      "Align the game with your brand by customizing the images and differences to reflect event themes, brand elements, or seasonal campaigns. This ensures the experience resonates with your audience while promoting your message.",
    ],
  },
  {
    id: "rewardBasedChallenges",
    title: "Reward-Based Challenges",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/rewardBasedChallenges.webp`,
    content: [
      "Motivate players with prizes, badges, or exclusive rewards for completing levels or achieving high scores. This keeps participants engaged and encourages repeat play.",
    ],
  },
];

const strategyItems = [
  {
    title: "Engaging and Versatile",
    description:
      "Spot the Difference blends entertainment with skill, making it ideal for events, promotions, or educational programs. Its wide appeal ensures that everyone can enjoy the challenge.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/engagingVersatile.webp`,
    imgAlt: "Engaging-and-Versatile",
    reverse: true,
  },
  {
    title: "Customizable and On-Brand",
    description:
      "Tailor the game to suit your campaign goals by adjusting the visuals and difficulty. Whether for corporate branding, seasonal themes, or targeted messaging, Spot the Difference adapts seamlessly to your needs.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/customizableBrand.webp`,
    imgAlt: "Customizable-and-On-Brand",
  },
  {
    title: "Building Mental Agility",
    description:
      "Beyond fun, the game promotes observation and focus, offering players a rewarding and memorable experience. It’s a unique way to entertain your audience while fostering engagement with your brand.",
    imgSrc: `${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/mentalAgility.webp`,
    imgAlt: "Building-Mental-Agility",
    reverse: true,
  },
];

const handlePlay = () => {
  window.open("https://spot-the-difference.workplay.digital/", "_target");
};

const SpotTheDifference = () => {
  return (
    <div className="w-full">
      <div className="relative px-6 pb-14 pt-10 sm:px-10 sm:py-10 md:px-12 md:py-16 xl:h-screen xl:px-20 xl:py-28">
        <div className="relative flex w-full">
          <div className="z-100">
            <h2 className="block font-epilogue text-4xl font-semibold text-mercury sm:hidden md:text-5xl xl:text-6xl">
              Spot The <br /> Difference
            </h2>
            <h2 className="hidden font-epilogue text-4xl font-semibold text-mercury sm:block md:text-5xl xl:text-6xl">
              Spot The Difference
            </h2>
            <p className="my-8 w-full font-plusJakartaSans text-base text-silverChalice sm:w-[50%] sm:text-lg lg:my-6 xl:text-xl">
              Put your observation skills to the test with Spot the Difference!
              This thrilling game invites players to find subtle variations
              between two images before the clock runs out, creating an engaging
              mix of fun and focus.
            </p>
            <button
              className="headerBtn relative w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:w-fit sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
              onClick={handlePlay}
            >
              Play Now
            </button>
          </div>
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/spotTheDifferenceGroup.webp`}
            alt="spotTheDifference"
            className="absolute right-[-6%] top-[-20%] z-0 hidden w-[80%] sm:bottom-[-30%] sm:block md:top-[-25%] lg:top-[-45%] lg:w-[75%] xl:top-[-75%]"
          />
          <img
            src={`${process.env.REACT_APP_S3_URL}/website-assets/Games/spotTheDifference/spotTheDifference.webp`}
            alt="spotTheDifference"
            className="absolute right-0 top-[-2%] h-[14vh] sm:hidden sm:h-[20vh] md:h-[60vh] xl:right-4 xl:h-[90vh] xl:w-auto"
          />
        </div>
      </div>
      <div
        className="pointer-events-none absolute z-10 hidden h-full w-full overflow-x-hidden blur-3xl sm:top-[-40%] sm:block xl:top-[-0%] "
        style={{
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 2), transparent)",
        }}
      ></div>
      <UseCase
        title="How You Can Use Spot The Difference"
        labels={useCaseLabels}
        sections={useCaseDetails}
      />
      <Strategy
        title="How Spot The Difference Fits Your Strategy"
        items={strategyItems}
      />
    </div>
  );
};

export default SpotTheDifference;
