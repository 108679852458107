import { useEffect, useState } from "react";
import FaqCTA from "../components/FaqCTA";
import FooterSection from "../components/Footer/Footer";
import Navbar from "../components/Navbar";
import { Plus, X } from "react-feather";
import { BulletPointsIcon, SearchIcon } from "../components/SvgIcons";
import { faqAssets } from "../utils/assets";
import ImageWrapper from "../components/ImageWrapper";
import { faqData } from "../data/faq";

const Faq = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const toggleQuestion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredFaqData = faqData.filter((item) =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleSearchFocus = () => setIsSearchFocused(true);
  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const [iconSize, setIconSize] = useState({ width: 16, height: 16 });
  const [searchIconSize, setIconSearchSize] = useState({
    width: 16,
    height: 16,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setIconSize({ width: 30, height: 30 });
        setIconSearchSize({ width: 24, height: 24 });
      } else {
        setIconSize({ width: 24, height: 24 });
        setIconSearchSize({ width: 20, height: 20 });
      }
    };

    // Initial check on load
    handleResize();

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="faq-wrapper relative bg-codGray">
      <Navbar />
      <div className="z-0 flex items-center justify-center">
        {faqAssets.map((asset, index) => (
          <ImageWrapper
            key={index}
            src={asset.src}
            alt={asset.alt}
            className={asset.className}
          />
        ))}
      </div>
      <div className="mx-6 mb-20 mt-14 sm:mx-10 md:mx-12 md:mt-16 xl:mx-20 xl:mb-32">
        <div className="text-center text-mercury">
          <h4 className="my-4 font-plusJakartaSans text-xl md:my-4 md:text-2xl xl:my-5 xl:text-3xl">
            FAQs
          </h4>

          <h3 className="my-4 font-epilogue text-3xl font-bold leading-snug md:my-4 md:text-4xl xl:my-5 xl:text-5xl">
            Frequently asked questions
          </h3>

          <p className="mx-auto my-4 w-full font-plusJakartaSans text-silverChalice md:w-[90%] md:text-lg lg:w-[50%] lg:text-xl">
            Have questions? We're here to help.
          </p>
          <div className="relative">
            <input
              type="text"
              className="relative z-100 mt-6 h-10 w-[84%] rounded-full border bg-[#3D3D3D99] pl-10 placeholder:text-[#707070] md:mt-10 md:h-12 md:w-[50%] md:pl-11 md:pr-4 md:text-lg md:placeholder:text-lg"
              placeholder="Search"
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="absolute left-[12%] top-[53%] md:left-[26%] md:top-[58%]">
              <SearchIcon
                width={searchIconSize.width}
                height={searchIconSize.height}
                stroke={isSearchFocused ? "#f8a359" : "#919191"}
              />
            </div>
          </div>
          <div className="mb-12 mt-16 md:my-14 xl:my-20">
            {filteredFaqData.map((item, index) => {
              const parts = item.question.split(
                new RegExp(`(${searchQuery})`, "gi"),
              );

              return (
                <div
                  key={index}
                  className="relative z-20 mx-auto my-6 md:my-6 md:w-[80%] xl:my-8 xl:w-[60%]"
                >
                  <div
                    onClick={() => toggleQuestion(index)}
                    className="flex min-h-[20px] cursor-pointer flex-row justify-between font-semibold sm:items-center"
                  >
                    <div className="flex space-x-2 sm:items-center md:space-x-3">
                      <BulletPointsIcon
                        fill={
                          ["#D2C03A", "#8ACF62", "#F54A69", "#3BB5FB"][
                            index % 4
                          ]
                        }
                        width={iconSize.width}
                        height={iconSize.height}
                      />
                      <span className="text-left font-plusJakartaSans font-normal text-mercury md:text-lg xl:text-xl">
                        {parts.map((part, idx) =>
                          part.toLowerCase() === searchQuery.toLowerCase() ? (
                            <span key={idx} className="font-bold">
                              {part}
                            </span>
                          ) : (
                            part
                          ),
                        )}
                      </span>
                    </div>
                    {expandedIndex === index ? (
                      <X size={20} />
                    ) : (
                      <Plus size={20} />
                    )}
                  </div>
                  {expandedIndex === index && (
                    <div
                      className="my-2 ml-10 min-h-[20px] text-left font-plusJakartaSans text-[15px] text-silverChalice text-opacity-80 opacity-100 transition-all duration-300 ease-in-out md:text-[17px] xl:text-lg"
                      style={{
                        maxHeight: expandedIndex === index ? "500px" : "0",
                        overflow: "hidden",
                      }}
                    >
                      {item.answer}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <FaqCTA />
      </div>
      <FooterSection />
    </div>
  );
};

export default Faq;
