import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import LazyLoadImages from "../LazyLoadImages";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

interface ForgotPasswordProps {}

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  const handleEmailFocus = () => setIsEmailFocused(true);
  const handleEmailBlur = () => {
    setIsEmailFocused(false);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please fill your email address", {
        autoClose: 2000,
        className: "toastify-toast",
      });
    } else if (!validateEmail(email)) {
      toast.error("Invalid email address", {
        autoClose: 2000,
        className: "toastify-toast",
      });
      return;
    } else {
      const URL = `${REACT_APP_API_URL}/auth/reset-password`;
      if (!URL) {
        return;
      }
      try {
        const response = await axios.post(
          `${URL}`,
          { email: email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        if (response.data.success === true) {
          toast.success("Password reset email sent, Please check your inbox.", {
            autoClose: 3000,
            className: "toastify-toast",
          });
          setEmail("");
          localStorage.removeItem("xSudTzMYmd");
        }
      } catch (error: any) {
        toast.error(error.response.data.message, {
          autoClose: 2000,
          className: "toastify-toast",
        });
        console.error("Error creating user:", error);
        setEmail("");
      }
    }
  };

  const handleLogoRedirect = () => {
    navigate("/");
  };

  return (
    <>
      <div className="input-wrapper relative min-h-screen w-full items-center overflow-x-hidden bg-codGray py-6 md:flex md:py-0">
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/top-Ellipse.webp`}
          alt="Ellipse"
          className="absolute top-0 z-0"
        />
        <div className="z-100 w-full items-center justify-center px-6 md:flex md:w-[55%] md:px-[5%] lg:px-20 xl:w-[40%]">
          <div className="relative">
            <img
              className="z-100 mb-12 h-6 cursor-pointer xl:h-7"
              src={`${REACT_APP_S3_URL}/assets/wp-logo.webp`}
              alt="workplay-logo"
              onClick={handleLogoRedirect}
            />

            <div className="flex items-center justify-center">
              <img
                className="w-full px-14 pb-10 sm:px-20 md:hidden"
                src={`${REACT_APP_S3_URL}/website-assets/authPages/forgot.webp`}
                alt="forgot-pass"
              />
            </div>

            <div className="relative">
              <h2 className="text-left font-epilogue text-3xl font-bold text-mercury lg:text-4xl">
                Forgot Password?
              </h2>
              <LazyLoadImages
                src={`${REACT_APP_S3_URL}/website-assets/authPages/sparkle.webp`}
                alt="sparkle"
                className="absolute right-[0%] top-[-140%] h-8 lg:right-[-6%] lg:h-auto"
              />
            </div>
            <p className="m-auto mb-6 mt-3 text-left font-epilogue text-[17px] font-medium text-silverChalice lg:mb-10">
              Don't Worry! Just enter the email address you registered with to
              receive a password reset link.
            </p>
            <form onSubmit={handleSubmit} className="z-100 flex flex-col">
              <div className="relative z-100 w-full">
                <label
                  htmlFor="email"
                  className={`absolute left-3 top-3.5 transform transition-all duration-300 ${
                    isEmailFocused
                      ? "-translate-y-7 bg-codGray px-1 text-tanHide"
                      : email
                        ? "-translate-y-7 bg-codGray px-1 text-mercury duration-0"
                        : "text-mercury"
                  }`}
                >
                  Email Address{" "}
                  <span
                    className={`${isEmailFocused ? "text-tanHide" : "text-red-400"}`}
                  >
                    *
                  </span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={handleEmailFocus}
                  onBlur={handleEmailBlur}
                  required
                  className="w-full rounded-md border-2 border-mercury border-opacity-50 bg-transparent px-4 py-3 text-base font-semibold text-mercury outline-none focus:border-transparent focus:outline-none focus:ring-2 focus:ring-tanHide"
                />
              </div>
              <button
                type="submit"
                className="headerBtn relative z-100 mt-6 w-full rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-xl"
              >
                Get reset link
              </button>
            </form>
            <p className="z-100 mb-16 mt-5 hidden font-plusJakartaSans text-mercury sm:mb-0 sm:block">
              Did you remember your password?{" "}
              <Link
                to="/login"
                className="underline-link relative ml-1 scale-105 hover:opacity-80"
              >
                <strong className="win relative font-plusJakartaSans font-semibold">
                  Try logging in
                </strong>
              </Link>
            </p>
            <p className="z-100 block pb-1 pt-4 text-left font-plusJakartaSans text-mercury sm:hidden">
              Did you remember your password?{" "}
            </p>
            <Link
              to="/login"
              className="underline-link relative ml-1 block w-fit scale-105 hover:opacity-80 sm:hidden"
            >
              <strong className="win relative font-plusJakartaSans font-semibold">
                Try logging in
              </strong>
            </Link>
          </div>
        </div>{" "}
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/pattern.webp`}
          alt="pattern"
          className="absolute bottom-0 right-0 -z-0"
        />
        <LazyLoadImages
          src={`${REACT_APP_S3_URL}/website-assets/authPages/redEllipse.webp`}
          alt="Ellipse"
          className="absolute bottom-0 right-0 z-0 h-full"
        />
        <div className="z-0 hidden md:block md:w-[45%] xl:w-[60%]">
          <div className="flex items-center justify-center">
            <img
              className="w-[90%] px-6 md:px-10 lg:px-20"
              src={`${REACT_APP_S3_URL}/website-assets/authPages/forgot.webp`}
              alt="forgot-pass"
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
