export const faqData = [
  {
    question: "What is Workplay Digital?",
    answer:
      "Workplay Digital is a company specializing in gamified marketing solutions, providing customizable games that businesses can use to enhance their marketing campaigns and engage their audience.",
  },
  {
    question: "How do customizable games work in marketing campaigns?",
    answer:
      "Businesses integrate our games into their marketing strategies to attract, engage, and retain customers. Games are tailored to match the brand’s objectives and can include rewards, contests, and interactive elements.",
  },
  {
    question: "What industries can benefit from gamified marketing?",
    answer:
      "Gamified marketing can benefit industries such as retail, hospitality, e-commerce, education, entertainment, and more. Any brand looking to increase customer engagement can leverage our solutions.",
  },
  {
    question: "What types of games do you offer?",
    answer:
      "We offer a variety of game formats, including quizzes, puzzles, spin-to-win games, virtual scavenger hunts, and more, all of which can be customized to meet specific campaign goals.",
  },
  {
    question: "How customizable are the games?",
    answer:
      "Our games are fully customizable, allowing brands to incorporate their logos, color schemes, themes, and even tailored messaging to align with their marketing objectives.",
  },
  {
    question: "Can the games collect customer data?",
    answer:
      "Yes, our games can be designed to collect customer data such as emails, preferences, and feedback, in compliance with privacy laws like GDPR and CCPA.",
  },
  {
    question: "Do participants need to download an app to play?",
    answer:
      "No, our games are typically web-based and can be accessed directly through a link, but can be embedded in your apps, making them easy to share across social media, email campaigns, or websites.",
  },
  {
    question: "How do gamified solutions improve marketing ROI?",
    answer:
      "By increasing customer interaction and engagement, our games boost brand awareness, drive conversions, and encourage repeat interactions, ultimately improving your campaign ROI.",
  },
  {
    question: "What kind of rewards can be included in the games?",
    answer:
      "Rewards can range from digital coupons, discounts, and freebies to contest entries, loyalty points, or other incentives that fit your marketing goals.",
  },
  {
    question: "How long does it take to develop a game for a campaign?",
    answer:
      "Development timelines vary based on the complexity of the game and customization needs but typically range from 1 to 4 weeks.",
  },
];
