import { useNavigate } from "react-router-dom";
import LazyLoadImages from "./LazyLoadImages";

const { REACT_APP_S3_URL } = process.env;

const LandingCTA = () => {
  const navigate = useNavigate();
  const handleContact = () => {
    navigate("/contact");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-codGray px-6 pb-[270px] pt-10 sm:px-10 sm:pb-56 sm:pt-10 md:px-12 md:pb-40 md:pt-16 xl:px-20 xl:pb-48 xl:pt-32">
      <div className="relative flex">
        <div>
          <p className="font-epilogue text-lg text-white sm:w-full md:w-[50%] md:text-xl md:leading-relaxed xl:text-3xl xl:leading-normal">
            Ready, player one? You’ve got ideas and we’ve got the tools to make
            them a reality.  Get a free consultation and estimate to start
            building your campaigns today.
          </p>
          <button
            className="headerBtn relative mt-8 rounded-full bg-tanHide px-8 py-2.5 font-encodeSansExpanded text-base font-semibold text-white shadow-navBtn hover:bg-brandyPunch sm:text-lg md:mt-10 md:px-6 md:py-3 md:text-xl xl:px-8 xl:text-2xl"
            onClick={handleContact}
          >
            Let's Talk
          </button>
        </div>
        <div>
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/landingCTA/puzzlevector.webp`}
            alt="puzzlevector"
            className="absolute right-[70%] top-[145%] h-[90px] sm:right-[60%] sm:top-[150%] sm:h-24 md:right-[50%] md:top-[80%] md:h-28 xl:right-[30%] xl:top-[60%] xl:h-auto"
          />
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/landingCTA/gift.webp`}
            alt="gift"
            className="absolute right-[12%] top-[155%] h-[90px] sm:right-[0%] sm:top-[150%] sm:h-24 md:right-[5%] md:top-[95%] md:h-28 xl:right-[0%] xl:top-[60%] xl:h-auto"
          />
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/landingCTA/memoryvector.webp`}
            alt="memoryvector"
            className="absolute -bottom-[50%] right-[38%] h-[90px] sm:-bottom-[60%] sm:right-[30%] sm:h-24 md:bottom-[10%] md:right-[25%] md:h-28 xl:bottom-[40%] xl:right-[18%] xl:h-auto"
          />
          <LazyLoadImages
            src={`${REACT_APP_S3_URL}/website-assets/landingPage/landingCTA/shape.webp`}
            alt="shape"
            className="absolute -bottom-[20%] right-[5%] h-[90px] sm:-bottom-[20%] sm:right-[0%] sm:h-24 md:bottom-[40%] md:left-[88%] md:h-28 xl:bottom-[80%] xl:left-[90%] xl:h-auto"
          />
        </div>{" "}
      </div>
    </div>
  );
};

export default LandingCTA;
